function isNumber(inputCheck:any) {
  if(!isNaN(inputCheck) && inputCheck !==undefined && inputCheck !== null && inputCheck !=="") {
    return true
  }
  else {
    return false
  }
}

function getNumeric(input:string) {
  return String(input).replace(/[^\d]/g, "");
}

function getPositiveNegativeNumeric(input:string) {
  return String(input).replace(/[^-?\d*\.{0,1}\d+$]/g, "");
}

function replaceComma(input:any) {
  var result = String(input).replace(",",".");
  return result
}

function addZeroesDecimals(num:number, decimals:number) {
  let roundDecimals:string = num.toFixed(decimals);
  roundDecimals = roundDecimals.replace('.',',');
  return roundDecimals;
}

// **** DATES **** //

function GetFormattedDateTime(inputData:any) {
  var todayTime = new Date(inputData);
  var month = todayTime.getMonth() + 1;
  var day = todayTime.getDate();
  var year = todayTime .getFullYear();
  return year + "-" + (month < 10?'0'+month:month) + "-" + (day < 10?'0'+day:day)+"T00:00:00.000Z";
}

function GetDate(inputData:any) {
  var todayTime = new Date(inputData);
  var month = todayTime.getMonth() + 1;
  var day = todayTime.getDate();
  var year = todayTime .getFullYear();
  return year + "-" + (month < 10?'0'+month:month) + "-" + (day < 10?'0'+day:day);
}

function GetFormattedDate(inputData: any) {
  var todayTime = new Date(inputData);
  var month = todayTime.getMonth() + 1;
  var day = todayTime.getDate();
  var year = todayTime .getFullYear();
  return (day < 10?'0'+day:day) + "/" + (month < 10?'0'+month:month) + "/" + year;
}

function isValidEmail(mail:string) {
  const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return regex.test(mail);
}

function GetDatetimeFormat(inputData: any) {
  var todayTime = new Date(inputData);
  var month = todayTime.getMonth() + 1;
  var day = todayTime.getDate();
  var year = todayTime.getFullYear();
  var hour = todayTime.getHours();
  var minut = todayTime.getMinutes();

  return (day < 10?'0'+day:day) + "/" + (month < 10?'0'+month:month) + "/" + year + " " + (hour < 10?'0'+hour:hour)+ ":" + (minut < 10?'0'+minut:minut)+"h";
}

function GetConcatedDate(date:any) {
  var todayTime = new Date(date);
  var month = todayTime.getMonth() + 1;
  var day = todayTime.getDate();
  var year = todayTime.getFullYear();
  var hour = todayTime.getHours();
  var minut = todayTime.getMinutes();
  var second = todayTime.getSeconds();

  return (`${year}${month}${day}${hour}${minut}${second}`);
}

function calcBusinessDays(dDate1:Date, dDate2:Date) { // input given as Date objects
  var iWeeks, iDateDiff, iAdjust = 0;
  if (dDate2 < dDate1) return -1; // error code if dates transposed
  var iWeekday1 = dDate1.getDay(); // day of week
  var iWeekday2 = dDate2.getDay();
  iWeekday1 = (iWeekday1 == 0) ? 7 : iWeekday1; // change Sunday from 0 to 7
  iWeekday2 = (iWeekday2 == 0) ? 7 : iWeekday2;
  if ((iWeekday1 > 5) && (iWeekday2 > 5)) iAdjust = 1; // adjustment if both days on weekend
  iWeekday1 = (iWeekday1 > 5) ? 5 : iWeekday1; // only count weekdays
  iWeekday2 = (iWeekday2 > 5) ? 5 : iWeekday2;

  // calculate differnece in weeks (1000mS * 60sec * 60min * 24hrs * 7 days = 604800000)
  iWeeks = Math.floor((dDate2.getTime() - dDate1.getTime()) / 604800000)

  if (iWeekday1 < iWeekday2) { //Equal to makes it reduce 5 days
    iDateDiff = (iWeeks * 5) + (iWeekday2 - iWeekday1)
  } else {
    iDateDiff = ((iWeeks + 1) * 5) - (iWeekday1 - iWeekday2)
  }

  iDateDiff -= iAdjust // take into account both days on weekend

  return (iDateDiff);
}

// **** LOCALSTORAGE **** //

function writeLocalStorage(title:string, value:any){
  try{
    localStorage.setItem(title, value);
    return true;
  } catch(err){
    return false;
  }
}

function readLocalStorage(title:string){
  try{
    return localStorage.getItem(title);
  } catch(err) {
    return undefined;
  }
}

function removeItemLocalStorage(title:string){
  try{
    return localStorage.removeItem(title);
  } catch(err) {
    return undefined;
  }
}

const requestNotificationPermission = async () => {
  const permission = await Notification.requestPermission();
  if (permission === 'granted') {
      console.log('Notification permission granted.');
      return true;
  } else {
      console.error('Notification permission denied.');
      return false;
  }
};

export {
  isNumber, replaceComma, writeLocalStorage, readLocalStorage,
  removeItemLocalStorage, addZeroesDecimals, GetFormattedDateTime,
  GetDate, calcBusinessDays, GetFormattedDate, GetDatetimeFormat,
  getNumeric, getPositiveNegativeNumeric, GetConcatedDate, isValidEmail,
  requestNotificationPermission
}