import { adalApiFetch } from './adalConfig';
import appData from './ApiClients/appData';
import { filter, split, reduce, find, cloneDeep, findIndex } from 'lodash';
import { IMe } from './types';
import LogoAgrocesa from './Assets/Empresas/logo_agrocesa.jpg';
import LogoVallcompanysPinso from './Assets/Empresas/logo_vallcompanys_pinso.jpg';
import LogoPondex from './Assets/Empresas/logo_pondex.jpg';
import LogoMevet from './Assets/Empresas/logo_mevet.jpg';
import LogoPatel from './Assets/Empresas/logo_patel.png';
import { sign01, sign02 } from './ApiClients/DummyData';

const fetchAPI = (
    url: string,
    method: string,
    parseJSON = false,
    body: any = undefined,
    headers = true
): Promise<any> => {
    return adalApiFetch(fetch, url, {
        method: method,
        mode: 'cors',
        redirect: 'follow',
        body: body ? JSON.stringify(body) : body,
        headers: headers
            ? new Headers({
                'Content-Type': 'application/json; charset=utf-8',
                'Accept': 'application/json; charset=utf-8',
            })
            : undefined
    }).then((response: any) => {
        if (response.ok) {
            if (parseJSON) {
                return response.json();
            } else {
                return response.text();
            }
        } else if (!parseJSON) {
            throw new Error(`Error ${response.status}`);
        } else {
            try {
                return response.json().then((data: any) => {
                    console.log('Error!');
                    return Promise.reject(new Error(data.message));
                });
            } catch (err) {
                throw new Error(`Error ${response.status}`);
            }
        }
        return response;
    });
}

const GetFormattedSqlDate = (inputData:string, type:number) => {
  var datime = new Date(inputData);
  var month = datime.getMonth() + 1;
  var day = datime.getDate();
  var year = datime.getFullYear();
  return year + "-" + (month < 10?'0'+month:month) + "-" + (day < 10?'0'+day:day)+` ${type==1?'00:00:00':'23:59:59'}`;
}

const splitData = (input:string, char:string) => {
  return split(input, char);
}

function addThousandsAndReplaceDecimal(number:string|number, separatorDecimal:string){
  //let newNumber = String(number).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  let newNumber = String(number);
  newNumber = newNumber.replace('.',separatorDecimal);
  newNumber = newNumber.replace(',',separatorDecimal);
  newNumber = newNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  return newNumber;
}

function addZeroes(num:number) {
  return num.toFixed(Math.max(((num+'').split(".")[1]||"").length, 2));
}

const arrayYears = (allowAllYears:boolean) =>{
  let currentDate:Date = new Date();
  let currentYear:number = currentDate.getFullYear();
  let arrayYears:any=[];

  for(let a = -6; a < 2; a++) {
      arrayYears.splice(0,0,currentYear+a);
  }

  if(allowAllYears)
      arrayYears.push("Todos");

  return arrayYears;
}

const GetLogoEmpresa = (centrotrabajoId: string) =>{
    switch(centrotrabajoId){
        case 'vc':
            return LogoVallcompanysPinso;
        case 'ac':
            return LogoAgrocesa;
        case 'pdx':
            return LogoPondex;
        case 'mvt':
            return LogoMevet;
        case 'ptl':
            return LogoPatel;
        default:
            return `Sin logotipo`;
    }
}

const GetRole = (me:IMe, centrotrabajoId: number) => {
    const index = findIndex(me.empresas, function(o){return o.id === Number(centrotrabajoId)});

    if(index == -1)
        return -1;
    return me.empresas[index].role;
}

const GetEmpresa = (centrotrabajoId: number, me?:IMe) => {
    if(!me)
        return undefined;
        
    const index = findIndex(me.empresas, function(o){return o.id === Number(centrotrabajoId)});

    if(index == -1)
        return undefined;
    return me.empresas[index];
}

const GetSignOptions = (centrotrabajoId: number, me?:IMe) => {
    if(!me)
        return [[], 0];
        
    const index = findIndex(me.empresas, function(o){return o.id === Number(centrotrabajoId)});

    if(index == -1)
        return [[], 0];

    const business = me.empresas[index];
    //** OTP decision */
    let signOptions = sign01;
    let signDefault = 1;

    if(!business.otp) {
        signOptions = sign02;
        signDefault = 2;
    }

    return [signOptions, signDefault];
}

const OpenExternalFile=(source:string)=> {
    //const basicUrlFileDownloader = `https://stggvcind01.blob.core.windows.net/filedownloader/index.html`;
    //window.open(`${basicUrlFileDownloader}?file=${encodeURIComponent(source)}`)
    window.open(source, "_blank");
}

const millisToMinutesAndSeconds = (millis:number) => {
    let minutes:number = Math.floor(millis / 60000);
    let seconds:number = Number(((millis % 60000) / 1000).toFixed(0));
    return minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
}

export { 
  fetchAPI, GetFormattedSqlDate,
  arrayYears,
  addThousandsAndReplaceDecimal, addZeroes,
  GetLogoEmpresa, GetRole, GetEmpresa, OpenExternalFile,
  millisToMinutesAndSeconds, GetSignOptions
}