import React, { Component } from 'react';
import { createStyles, withStyles, Theme } from '@material-ui/core/styles';
import { Text, View, StyleSheet, Image, PDFViewer, PDFDownloadLink, pdf } from '@react-pdf/renderer';
import { Document, Page } from '../Material/pdfRenderComponents';
import Button from '@material-ui/core/Button';
import Add from '@material-ui/icons/Add';
import DatePicker from '../DatePicker';
import { IMe, IIIRegister, IVRegister, IForm, IQuestion, ICentroTrabajo } from '../../types';
import { SelectorField, CheckBoxField, TextFieldInput } from '../FormsWidgets';
import {GetRole, GetEmpresa} from '../../helpers';
import TakeIcon from '@material-ui/icons/CheckCircleOutline';
import {cloneDeep, filter, find, findIndex} from 'lodash';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddActionIcon from '@material-ui/icons/AddBox';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import PageViewIcon from '@material-ui/icons/Pageview';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import { GetConcatedDate, GetDatetimeFormat } from '../../vc_general';
import OrderService from '../../ApiClients/OrderService';
import LoaderBeauty from '../Material/activityProgress';
import CreateModal from '../CreateDialog';
import ListSecciones from '../Material/MultiSelect';
import Loader from '../Loader';
import { ListChildComponentProps } from 'react-window';

const styles = (theme: Theme) => createStyles({
    container: {
        padding: '10px',
        textAlign: 'center',
        overflowY:'auto'
    },
    image: {
        width: '100%',
        height: 'auto'
    },
    promotion: {
        //backgroundColor: '#f00',
        padding: '3px 15px',
        //color: '#fff',
        verticalAlign: 'middle',
    },
    root: {
    width: '100%',
    },
    paper: {
        width: '100%',
        marginTop: '5px',
        //overflowX: 'auto',
        borderWidth: 2, 
        borderColor: '#00809b',
        borderStyle: 'solid',
    },
    table:{
        //tableLayout:'fixed'
        width: '100%',
    },
    row:{
        //wordWrap:'break-word'
        width:'100%',
        //backgroundColor:'#ddd'
    },
    heading: {
    fontSize: theme.typography.pxToRem(15),
    //fontWeight: theme.typography.fontWeightRegular,
    },
    item: {
        maxWidth:'200px',
        border: '2px solid #ccc',
        borderRadius: '15px',
        margin: '5px 10px',
        width: 'calc(100% - 20px)!important',
        boxShadow: '5px 5px 5px 0px rgba(0,0,0,0.50)',
        cursor: 'pointer',
        paddingTop: '10px',
        paddingBottom: '10px',
        position: 'relative',
    },
});

const useStyles = ({
    page: {
        //display: 'row',
        //backgroundColor: '#E4E4E4',
        margin: 10,
        padding: 10,
        flexGrow: 1,
    },
    body: {
        paddingTop: 15,
        paddingBottom: 40,
        paddingLeft: 15,
    },
    logo: {
        width: 60,
        height: 60,
    },
    logoFito: {
        width: 'auto',
        height: 'auto',
    },
    bussinessData: {
        width: 'auto',
        height: 'auto',
    },
    header:{
        marginTop:7,
        fontSize:10
    },
    secondHeader:{
        marginTop:7,
        fontSize:11,
        marginBottom:10
    },
    headerTable:{
        fontSize:10,
        marginVertical:'auto',
        padding:3,
        borderTop:'1pt solid rgba(0,0,0,0.31)',
        borderBottom:'1pt solid rgba(0,0,0,0.31)',
        borderLeft:'1pt solid rgba(0,0,0,0.31)',
    },
    lineTable:{
        fontSize:9,
        marginVertical:'auto',
        padding:3,
    },
    content: {
        marginRight:15,
        '@media min-width: 400': {
            flexDirection: 'row',
        },
    }
});

const secciones_inspected = (list:Array<any>)=>{

    let secciones = list.map((seccion:any, index:number)=>{

        return (
            <View key={index} style={[useStyles.content, {paddingBottom:5}]}>
                <View style={{width:'13%'}}>
                    <Text style={[useStyles.lineTable, {textAlign:'center', justifyContent:'center', borderRight:'1pt solid rgba(0,0,0,0.31)'}]}>
                        {index + 1}
                    </Text>
                </View>
                <View style={{width:'87%'}}>
                    <Text style={[useStyles.lineTable, {textAlign:'center', justifyContent:'center', borderRight:'1pt solid rgba(0,0,0,0.31)'}]}>
                        {seccion.name}
                    </Text>
                </View>
            </View>
        );
    })
    return secciones;
}

const questions = (list:Array<IQuestion>)=>{

    let questions = list.map((question:IQuestion, index:number)=>{

        return (
            <View key={index} style={[useStyles.content, {paddingBottom:5}]}>
                <View style={{width:'55%'}}>
                    <Text style={[useStyles.lineTable, {textAlign:'left', justifyContent:'center', borderRight:'1pt solid rgba(0,0,0,0.31)'}]}>
                        {question.question}
                    </Text>
                </View>
                <View style={{width:'10%'}}>
                    <Text style={[useStyles.lineTable, {textAlign:'center', justifyContent:'center', borderRight:'1pt solid rgba(0,0,0,0.31)'}]}>
                        {question.answer}
                    </Text>
                </View>
                <View style={{width:'35%'}}>
                    <Text style={[useStyles.lineTable, {textAlign:'center', justifyContent:'center', borderRight:'1pt solid rgba(0,0,0,0.31)'}]}>
                        {question.comment}
                    </Text>
                </View>
            </View>
        );
    })
    return questions;
}

const PdfForm  = (props: ListChildComponentProps) =>{
    const register:IForm = props.data[0];
    const me:IMe = props.data[1];
    const item:IVRegister = props.data[2];

    const empresa:ICentroTrabajo|undefined = GetEmpresa(item.centrotrabajoId, me);

    return(
        <Document>
            <Page size="A4" style={useStyles.body} >
                <View fixed>
                    <View style={[useStyles.content, {paddingBottom:20, justifyContent:'space-between'}]}>
                        <Text style={{fontSize:8, color:'#8c8c8c', fontWeight:'bold'}} fixed>
                            {`Fecha revisión: ${register.dt_reviewed}`}
                        </Text>
                        <Text style={{fontSize:8}} render={({ pageNumber, totalPages }) => (
                            `Pág. ${pageNumber} / ${totalPages}`
                        )} fixed />
                    </View>
                    <View style={[useStyles.content, {paddingBottom:20}]}>
                        <View style={{width:'25%', marginRight:30, paddingRight:15, borderRight:'1pt solid rgba(0,0,0,0.31)'}}>
                            {empresa?
                                /*<Image src={empresa.logo} style={useStyles.bussinessData}/>*/
                                <Image src={empresa.logo} style={useStyles.bussinessData}/>
                            :
                                null
                            }
                            <Text style={{fontWeight:25, fontSize:10, textAlign:'center', marginTop:10}}>{`${empresa?.description}`}</Text>
                        </View>
                        <View style={{width:'70%', height:120}}>
                            <Text style={{fontWeight:50, fontSize:20}}>{`${register.title}`}</Text>
                            <Text style={useStyles.header}>{`Fecha realización: ${GetDatetimeFormat(register.dt_created)}`}</Text>
                            <Text style={useStyles.header}>{`Realizado por: ${register.usuario}`}</Text>
                        </View>
                    </View>
                </View>


                <View style={[useStyles.secondHeader,{fontWeight:30}]}>
                    <Text>{`Secciones:`}</Text>
                </View>

                <View style={[useStyles.content, {paddingBottom:10}]}>
                    <View style={{width:'13%'}}>
                        <Text style={[useStyles.headerTable, {textAlign:'center', justifyContent:'center'}]}>{`Nº`}</Text>
                    </View>
                    <View style={{width:'87%'}}>
                        <Text style={[useStyles.headerTable, {textAlign:'center', justifyContent:'center', backgroundColor:'#ddd', borderRight:'1pt solid rgba(0,0,0,0.31)'}]}>{`Sección`}</Text>
                    </View>
                </View>

                <View>
                    {secciones_inspected(register.secciones)}
                </View>

                <View style={[useStyles.secondHeader,{fontWeight:30}]}>
                    <Text>{`Puntos inspeccionados:`}</Text>
                </View>

                <View style={[useStyles.content, {paddingBottom:10}]}>
                    <View style={{width:'55%'}}>
                        <Text style={[useStyles.headerTable, {textAlign:'center', justifyContent:'center'}]}>{`Punto inspección`}</Text>
                    </View>
                    <View style={{width:'10%'}}>
                        <Text style={[useStyles.headerTable, {textAlign:'center', justifyContent:'center', backgroundColor:'#ddd'}]}>{`Respuesta`}</Text>
                    </View>
                    <View style={{width:'35%'}}>
                        <Text style={[useStyles.headerTable, {textAlign:'center', justifyContent:'center', borderRight:'1pt solid rgba(0,0,0,0.31)'}]}>{`Comentario`}</Text>
                    </View>
                </View>

                <View>
                    {questions(register.questions)}
                </View>

            </Page>    
        </Document>
    ); 
}

interface IFormProps {
    me:IMe;
    classes: any;
    secciones:any;
    handleCloseItemForm: Function;
    item:IVRegister;
}

interface IFormState {
    creationModalOpen:boolean;
    modalAction:number;
    modalText:any;
    modalType:number;
    titleModal: string;
    htmlBody:boolean;
    loading:boolean;
    loadingModal:boolean;
    register:IForm;
}

class Form extends Component<IFormProps, IFormState> {
    _going2upload:any = {attachments:[]};
    constructor(props: IFormProps) {
        super(props);
        this.state = {
            creationModalOpen:false,
            modalType: -1,
            modalAction: -1,
            modalText:'',
            htmlBody:false,
            loading: false,
            loadingModal: false,
            titleModal: `MENSAJE`,
            register: {
                filename: `Informe_EPIs_02_secciones_${GetConcatedDate(new Date())}.pdf`,
                dt_reviewed: `01/01/21`,
                dt_created: new Date(),
                dt_edited: new Date(),
                title: `Revisión EPIs - Sección`,
                usuario: props.me.userIdName,
                questions: [
                    {id:"q_1", question: "Se dispone de los EPIs necesarios según lo indicado en la evaluación de riesgo del puesto de trabajo", answer: "", comment: "", a_template:[{id:"SI",name:"SI"},{id:"NO",name:"NO"},{id:"NO APLICA",name:"NO APLICA"}]},
                    {id:"q_2", question: "Su utilización se lleva a cabo de acuerdo a las instrucciones del fabricante y frente a los riesgos a proteger", answer: "", comment: "", a_template:[{id:"SI",name:"SI"},{id:"NO",name:"NO"},{id:"NO APLICA",name:"NO APLICA"}]},
                    {id:"q_3", question: "Se almacenan en lugares adecuados", answer: "", comment: "", a_template:[{id:"SI",name:"SI"},{id:"NO",name:"NO"},{id:"NO APLICA",name:"NO APLICA"}]},
                    {id:"q_4", question: "Los EPIs necesarios se encuentran señalizados", answer: "", comment: "", a_template:[{id:"SI",name:"SI"},{id:"NO",name:"NO"},{id:"NO APLICA",name:"NO APLICA"}]},
                    {id:"q_5", question: "Se encuentran en perfecto estado de conservación y mantenimiento", answer: "", comment: "", a_template:[{id:"SI",name:"SI"},{id:"NO",name:"NO"},{id:"NO APLICA",name:"NO APLICA"}]},
                    {id:"q_6", question: "Cuando son desechables, se depositan en los contenedores adecuados", answer: "", comment: "", a_template:[{id:"SI",name:"SI"},{id:"NO",name:"NO"},{id:"NO APLICA",name:"NO APLICA"}]}
                ],
                secciones: [],
                seccionDsc: ""
            }
        };
    }

    componentDidMount(){

    }

    onCloseCreationModal = () => {
        this.setState({ creationModalOpen: false, modalText: undefined, htmlBody:false });
    }

    /* CALL BACKEND */

    sendAttachFiles = async () => {
        let response = await OrderService.createImgBlob(this._going2upload, 6);
        this._going2upload = {attachments:[]};
        let p = `Fallo en el proceso, vuelva a intentarlo.`;
        let modaltype = 1;
        let modalaction = -1;

        if(response == 1) {
            p = `Formulario adjuntado correctamente`;
            modaltype = 4;
            modalaction= 1;
        }
        
        this.setState({
            creationModalOpen:true,
            modalText:p,
            modalType: modaltype,
            modalAction: modalaction,
            loadingModal: false
        });
    }

    /* */

    handleCreateModal = (typeAction: number) => {
        switch(typeAction){
            case 1: // Implica que se ha adjuntado corractamente el formulario
                this.props.handleCloseItemForm(true);
                this.onCloseCreationModal();
                break;
            case 2: // Implica iniciar el proceso de adjuntar el formulario
                this.setState({loadingModal: true},()=>{
                    this.handleCreateForm();
                    //this.onCloseCreationModal();
                });
                break;
            default:
                this.onCloseCreationModal();
        }
    }

    handleChangeDate=(id:any, event:any)=>{
        let nRegister = Object.assign({}, this.state.register);
        nRegister[id] = event;
        this.setState({ register: nRegister });
    }

    handleCloseForm=(item:IIIRegister, lines:Array<IVRegister>)=>{
        this.setState({
            modalAction: 2,
            modalText: `¡ATENCIÓN! ¿Quiere finalizar el formulario y adjuntarlo a la acción ${item.id} de la orden de trabajo ${item.code}?`,
            modalType: 2,
            creationModalOpen: true
        });
    }

    handleChangeUser=(id:any, event:any)=>{
        let nRegister = Object.assign({}, this.state.register);
        nRegister[id] = event.target.value;
        this.setState({ register: nRegister });
    }

    handleChangeUserQuestions=(id:any, event:any, index:number, type?:string)=>{
        let nRegister = Object.assign({}, this.state.register);
        nRegister.questions[index][id] = event.target.value;
        this.setState({ register: nRegister });
    }

    handleChangeSelectDevices=(e:any, list:Array<string>)=>{
        let nRegister = Object.assign({}, this.state.register);
        nRegister.secciones = list;
        this.setState({ register: nRegister });
    }

    handleCreateForm=()=>{

        const {item, me} = this.props;
        const {register} = this.state;

        let dale = 
                <PdfForm index={1} data={[register, me, item]} style={{}} />

        /*this.setState({
            modalAction: -1,
            modalText: <PDFViewer style={{width:'40vw', height:'90vh'}}>{dale}</PDFViewer>,
            modalType: 1,
            creationModalOpen: true,
        });*/

        const blob = pdf(dale).toBlob();

        blob.then(ret=>{

            let reader = new FileReader();

            reader.onloadend = async ()=>{
                this._going2upload.attachments.push({name: register.filename, stream: reader.result, centrotrabajoId: item.centrotrabajoId, tipo: item.tipo, listadoId: item.listadoTableId, id: item.id});
                //this.setState({loading:true }, ()=>this.sendAttachFiles());
                this.sendAttachFiles();
            }
            reader.readAsDataURL(ret);

        }).catch(err=>{
            console.log(err)
        })
    }

    handleAskCreateForm=()=>{
        const {item} = this.props;
        this.setState({
            modalAction: 2,
            modalText: `¡ATENCIÓN! ¿Quiere finalizar el formulario y adjuntarlo a la acción ${item.id} de la orden de trabajo ${item.listadoTableId}?`,
            modalType: 2,
            creationModalOpen: true,
        });
    }

    verifyForm=(register:IForm)=>{
        let ret = false;

        const index_questions = findIndex(register.questions, function(o){return o.answer.length == 0});
        const index_secciones = register.secciones.length;

        if(index_questions  == -1 && index_secciones > 0)
            ret = true;

        return ret;
    }

    render() {
        const {register, loading} = this.state;
        const {me, secciones} = this.props;

        return (
            <Dialog style={{padding:'25px'}} fullScreen={false} open={true} aria-labelledby="form-dialog-title" className='dialog-data form-dialog-data'>
                <DialogTitle id="form-dialog-title">
                    <div className="App-header">
                        {`${register.title}`}
                    </div>
                </DialogTitle>
                <DialogContent>
                    <div style={{width:'100%'}}>
                        <ListSecciones id={"secciones"} data={register.secciones} multiple={true} name={'Secciones'} placeholder={'Sección'} options={secciones} handleChangeSelect={(e:any, list:any)=>this.handleChangeSelectDevices(e,list)}/>
                    </div>
                    <Paper className={this.props.classes.paper}>
                        <Table className={this.props.classes.table} aria-label="simple table">
                            {register.questions.map((item:IQuestion, i:number)=>{
                                return(
                                    <TableBody key={i} style={{width:'100%'}}>
                                        <TableRow className={this.props.classes.row} style={{backgroundColor:'#00809b'}} key={i}>
                                            <TableCell style={{width:'100%', color:'#fff'}} align="center"><strong>{i + 1}</strong></TableCell>
                                        </TableRow> 
                                        <TableRow className={this.props.classes.row}> 
                                            <TableCell  align="left">{`${item.question}`}</TableCell>
                                        </TableRow> 
                                        <TableRow className={this.props.classes.row}> 
                                            <TableCell align="center">
                                                <div style={{maxWidth:150}}>
                                                    <SelectorField
                                                        name={`Q_${i + 1}`}
                                                        fieldsData={item.a_template}
                                                        id={'answer'}
                                                        deviceData={item}
                                                        handleFieldChange={(id:string, evt:any)=>this.handleChangeUserQuestions(id,evt,i)}
                                                        required={true}
                                                        style={3}
                                                    />
                                                </div>

                                            </TableCell>
                                        </TableRow> 
                                        <TableRow className={this.props.classes.row}> 
                                            <TableCell align="center">
                                                <TextFieldInput
                                                    name={`C_${i + 1}`}
                                                    id={'comment'}
                                                    disabled={false}
                                                    type={"text"}
                                                    required={false}
                                                    handleFieldChange={(id:string, evt:any, type:string)=>this.handleChangeUserQuestions(id,evt,i, type)}
                                                    style={3}
                                                    helper={""}
                                                    fieldsData={item}
                                                    maxText={2000}
                                                    noError={true}
                                                    size={"medium"}
                                                    shrink={true}
                                                />
                                            </TableCell>
                                        </TableRow> 
                                    </TableBody>
                                );
                            })}
                        </Table>
                    </Paper>

                    <div>
                    {this.state.creationModalOpen?
                        <CreateModal
                            onCloseCreationModal={this.onCloseCreationModal}
                            isOpen={this.state.creationModalOpen}
                            inputText={this.state.modalText}
                            typeModal={this.state.modalType}
                            action={this.handleCreateModal}
                            typeAction={this.state.modalAction}
                            loadingModal={this.state.loadingModal}
                            titleModal={this.state.titleModal}
                        />
                    :
                        null
                    }
                    </div>
                </DialogContent>
                <DialogActions style={{ borderTop: '#ff972b solid 3px'}}>
                    {!loading?
                        <div style={{marginRight:'auto', display:'flex', marginLeft: 'auto', width: '90%', justifyContent:'space-between'}}>
                            <Button onClick={()=>this.props.handleCloseItemForm(false)} className="cancel-dialog">
                                {'Cancelar'}
                            </Button>
                            <Button disabled={!this.verifyForm(register)} onClick={()=>this.handleAskCreateForm()} className={`confirm-dialog ${!this.verifyForm(register)?`confirm-dialog-disable`:``}`}>
                                {`Generar informe`}
                            </Button>
                        </div>
                    :
                        <Loader loading={true}/>
                    }
                </DialogActions>
            </Dialog>
        );
    }
}

export default withStyles(styles)(Form);