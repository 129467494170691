import React, { Component } from 'react';
import Layout from './Layout';
import Empresa from './Empresa';
import { createStyles, withStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Add from '@material-ui/icons/Add';
import OrderService from '../ApiClients/OrderService';
import Loader from './Loader';
import DatePicker from './DatePicker';
import { IMe, IIIRegister, IVRegister, IAttach, ICentroTrabajo, IIPersonal, IIIPersonal, IPersonal } from '../types';
import { SelectorField, CheckBoxField, TextFieldInput } from './FormsWidgets';
import {GetRole, GetEmpresa, OpenExternalFile} from '../helpers';
import TakeIcon from '@material-ui/icons/CheckCircleOutline';
import {cloneDeep, filter, find, findIndex} from 'lodash';
import CreateModal from './CreateDialog';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddActionIcon from '@material-ui/icons/AddBox';
import IconButton from '@material-ui/core/IconButton';
import LoaderBeauty from './Material/activityProgress';
import PaginatedTableLines from './Material/PaginatedTableListadoLines';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import PaginatedTable from './Material/PaginatedControlPanelPersonalSearcher';


const listadoStyles = ({
    button: {
        margin: 1,
        marginRight:15
    },
      leftIcon: {
        marginRight: 10,
    },
      rightIcon: {
        marginLeft: 1,
    },
      iconSmall: {
        fontSize: 20,
    },
    formControl: {
        margin: '1px',
        minWidth: 120,
        marginRight: 'auto',
    },
});

const base_searcher = {id: "", name: "", surname: ""};

const fieldsFormListadoItemLine = [
    [
        {
            id: 'id',
            name: 'ID',
            required: true,
            style:1,
            typeField:2,
            group: []
        },
    ],
    [
        {
            id: 'name',
            name: 'Nombre',
            required: false,
            style:3,
            helper:'',
            maxText:1000,
            typeField:2,
            multiline:false,
            group: []
        }
    ],
    [
        {
            id: 'surname',
            name: 'Apellidos',
            required: false,
            style:3,
            helper:'',
            maxText:1000,
            typeField:2,
            multiline:false,
            group: []
        }
    ]
];

interface IPersonalSearcherProps {
    handleCloseItemForm: Function;
    history:any;
    match:any;
}

interface IPersonalSearcherState {
    personalSearcher:IIIPersonal;
    searching: boolean;
    personal?: Array<IPersonal>;
}

class FormPersonalSearcher extends Component<IPersonalSearcherProps, IPersonalSearcherState> {
    constructor(props: IPersonalSearcherProps) {
        super(props);
        this.state = {
            personalSearcher: base_searcher,
            searching: false,
        };
    }

    handleGo = (item:IPersonal) => {
        if(!this.props.history)
            return;
        this.props.history.push(`/${item.centrotrabajoId}/personal`);
    }
    searchPersonal = async () => {
        let response = await OrderService.SearchPersonal(this.state.personalSearcher);

        this.setState({searching: false, personal: response});
    }

    handleSearchPersonal = async () => {
        this.setState({searching: true}, ()=>this.searchPersonal());
    }

    handleChangeUser=(id:any, event:any)=>{
        let newItemListado = Object.assign({}, this.state.personalSearcher);
        newItemListado[id] = event.target.value;
        this.setState({ personalSearcher: newItemListado });
    }    

    handleResetItemForm = () => {
        this.setState({personalSearcher:base_searcher});
    }

    render() {
        const {personalSearcher, searching, personal} = this.state;

        let fieldsTMP = fieldsFormListadoItemLine.map((n0:any, index:number)=>{
            let fieldsN0 = n0.map((n:any, index:number)=>{
                switch(n.typeField){
                    case 2:
                        return(
                            <TextFieldInput
                                key={index}
                                name={n.name}
                                id={n.id}
                                fieldsData={personalSearcher}
                                handleFieldChange={this.handleChangeUser}
                                disabled={false}
                                size="medium"
                                shrink={true}
                                //disabled={n.id=="id"?!this.props.newProduct:this.state.editProduct}
                                //disabled={n.id=="id"?!this.props.newProduct:this.state.editProduct}
                                type={n.type}
                                required={n.required}
                                style={n.style}
                                helper={n.helper}
                                maxText={n.maxText}
                                //noError={n.required?this.verifyForm(0,n.id):true}
                                noError={true}
                                multiline={n.multiline}
                            />
                        )
                    default:
                        return('')
                }
            })
            return (
                <form key={index} noValidate autoComplete="off">
                    {fieldsN0}
                </form>
            )
        })

        return (
            <React.Fragment>
                {fieldsTMP}
                <DialogActions>
                    {!searching?
                        <div style={{ display:'flex', gap:20 }}>
                            <Button onClick={()=>this.props.handleCloseItemForm()} className='cancel-dialog'>
                                {'Cancelar'}
                            </Button>
                            <Button disabled={false} className={`confirm-dialog`} onClick={()=>this.handleSearchPersonal()}>
                                {`Buscar`}
                            </Button>
                        </div>
                    :
                        <Loader loading={true}/>
                    }
                </DialogActions>
                <div>
                    {personal?
                        personal.length > 0?
                            <PaginatedTable
                                data={personal}
                                onBusinessClick={(item:IPersonal)=>this.handleGo(item)}
                            />
                        :
                            <div style={{padding:20, textAlign:'center'}}>
                                <strong>{`No se han encontrado registros con los criterios de búsqueda.`}</strong>
                            </div>
                    :
                        null
                    }

                </div>
            </React.Fragment>
        );
    }
}

export default withStyles(listadoStyles)(FormPersonalSearcher);