import React, { Component } from 'react';
import { createStyles, withStyles, Theme } from '@material-ui/core/styles';
import Layout from './Layout';
import Button from '@material-ui/core/Button';
import Add from '@material-ui/icons/Add';
import PaginatedTable from './Material/PaginatedTableListado';
import {filter, includes, cloneDeep} from 'lodash';
import OrderService from '../ApiClients/OrderService';
import { SelectorField, CheckBoxField, TextFieldInput } from './FormsWidgets';
import Toolbar from '@material-ui/core/Toolbar';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import {GetRole, GetEmpresa} from '../helpers';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FilterListIcon from '@material-ui/icons/FilterList';
import IconButton from '@material-ui/core/IconButton';
import ClearAllIcon from '@material-ui/icons/LayersClearOutlined';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Loader from './Loader';
import DatePicker from './DatePicker';
import { IMe, IRegister, IIRegister, ICentroTrabajo } from '../types';
import LoaderBeauty from './Material/activityProgress';
import './FormCorrectivo.css';
import  Checkbox  from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
const listadoStyles = ({
    button: {
        margin: 1,
        marginRight:15
    },
      leftIcon: {
        marginRight: 10,
    },
      rightIcon: {
        marginLeft: 1,
    },
      iconSmall: {
        fontSize: 20,
    },
    formControl: {
        margin: '1px',
        minWidth: 120,
        marginRight: 'auto',
    },
});

const fieldsFormListado = [
    [{
        id: 'origen',
        name: 'Orígen',
        required: true,
        style:1,
        typeField:1,
    },
    {
        id: 'tipoMedida',
        name: 'Tipo medida',
        required: false,
        style:1,
        typeField:1,
    },
    
    {
        id: 'seccion',
        name: 'Sección',
        required: true,
        style:1,
        typeField:1,
    },
    {
        id: 'puestoTrabajo',
        name: 'Puesto de trabajo',
        required: true,
        style:1,
        typeField:1,
    },

    ],
    [
        {
            id: 'responsable',
            name: 'Responsable',
            required: false,
            style:1,
            helper:'',
            typeField:1,
        },
        {
            id: 'prioridad',
            name: 'Prioridad',
            required: true,
            style:1,
            typeField:1,
        },
        {
            id: 'referencia',
            name: 'Riesgo',
            required: true,
            style:1,
            typeField:1
        },
        {
            id: 'fechaCreacion',
            name: 'Fecha Creación',
            required: true,
            style:3,
            typeField:4,
        },
        {
            id: 'fechaPrevista',
            name: 'Fecha Prevista Inicio',
            required: true,
            style:3,
            typeField:3,
        },
        {
            id: 'fechaPrevistaEnd',
            name: 'Fecha Prevista Fin',
            required: true,
            style:3,
            typeField:3,
        },
        {
            id: 'nonCompliant',
            name: 'No Conformidad',
            required: false,
            style: 1, 
            typeField:5
        },
    ],
    [
        {
            id: 'detalleRiesgo',
            name: 'Detalle riesgo',
            required: false,
            style:3,
            helper:'',
            maxText:4000,
            typeField:2,
            multiline:true,
            rows:3
        }
    ],
    [
        {
            id: 'medidas',
            name: 'Medidas',
            required: true,
            style:3,
            helper:'',
            maxText:30000,
            typeField:2,
            multiline:true,
            rows:4
        }
    ],        
    [
        /*{
            id: 'riesgo',
            name: 'Riesgo',
            required: true,
            style:3,
            helper:'',
            maxText:1000,
            typeField:2,
            multiline:true
        }*/
        {
            id: 'comentario',
            name: 'Comentarios',
            required: false,
            style:3,
            helper:'',
            maxText:1000,
            typeField:2,
            multiline:true,
            rows:3
        }
    ]
];

interface IFormListadoItemProps {
    classes: any;
    options:any;
    handleCreateItem:Function;
    handleCloseItemForm: Function;
    loading:boolean;
    centrotrabajoId:number;
}

interface IFormListadoItemState {
    itemListado:any;
    warningOpen:boolean;
    warningTitle:string;
}


class FormCorrectivo extends Component<IFormListadoItemProps, IFormListadoItemState> {
    constructor(props: IFormListadoItemProps) {
        super(props);
        this.state = {
            itemListado:{
                centrotrabajoId:props.centrotrabajoId,
                tipo:2, 
                referencia:-1, 
                puestoTrabajo:-1,
                origen:-1,
                seccion:-1, 
                responsable:-1, 
                prioridad:-1, 
                riesgo:"",
                detalleRiesgo:"",
                medidas:"",
                comentario:"",
                fechaPrevista:new Date(),
                tipoMedida:-1,
                fechaPrevistaEnd: new Date(),
                fechaCreacion:new Date(),
                nonCompliant:false,
            },
            warningOpen:false,
            warningTitle:"Reunión CAE"
        };
    }

    handleChangeUser=(id:any, event:any)=>{
        if(id === 'origen' && event.nativeEvent.srcElement.textContent.includes("REUNIÓN CAE")){            
            this.setState({warningOpen:true, warningTitle:event.nativeEvent.srcElement.textContent});
        }

        let newItemListado = Object.assign({}, this.state.itemListado);   
        newItemListado[id] = event.target.value;
        this.setState({ itemListado: newItemListado });
    }

    shouldDisableToday = (date:any) => {
        return false;
    }

    handleChangeCheckbox= (id:string, event:any) =>{        
        let newItemListado = Object.assign({}, this.state.itemListado);       
        newItemListado[id] = event.target.checked;

        if(newItemListado[id])
            newItemListado["prioridad"] = 20;
        this.setState({ itemListado: newItemListado });        
    }

    handleChangeDate=(id:any, event:any)=>{
        let newItemListado = Object.assign({}, this.state.itemListado);
        newItemListado[id] = event;
        this.setState({ itemListado: newItemListado });
    }

    verifyForm=(type:number, id?:string)=>{

        if(type == 1){ // Verifica todo el formulario
            const item = this.state.itemListado;
            if(item.referencia == -1){
                return false;
            } else if(item.puestoTrabajo == -1){
                return false;
            } else if(item.origen == -1){
                return false;
            } else if(item.seccion == -1){
                return false;
            } else if(item.responsable == -1){
                return false;
            } else if(item.prioridad == -1){
                return false;
            } 
            else if(item.tipoMedida == -1){
                return false;
            } /*else if(item.riesgo.length == 0){
                return false;
            } else if(item.detalleRiesgo.length == 0){
                return false;
            } */else if(item.medidas.length == 0){
                return false;
            }

            return true;
        } else if(type == 2){ // Verifica el ítem

        }

        return false;
    }

    handleResetItemForm = () => {
        this.setState({itemListado:undefined});
    }

    handleCloseWarningMessage=()=>{
        this.setState({warningOpen:false});
    }

    render() {

        let fieldsTMP = fieldsFormListado.map((n0:any, index:number)=>{
            let fieldsN0 = n0.map((n:any, index:number)=>{
                switch(n.typeField){
                    case 1:
                        return(
                            <SelectorField
                                key={index}
                                name={n.name}
                                id={n.id}
                                fieldsData={this.props.options[n.id]?filter(this.props.options[n.id], function(o){return o.active}):[]}
                                handleFieldChange={this.handleChangeUser}
                                //disabled={n.id=="id"?!this.props.newProduct:this.state.editProduct}
                                required={n.required}
                                style={n.style}
                                deviceData={this.state.itemListado}
                                className='dialog-item-25'
                                disabled={n.id == "prioridad" && this.state.itemListado["nonCompliant"] ?true:false}
                            />
                        )
                    case 2:
                        return(
                            <TextFieldInput
                                key={index}
                                name={n.name}
                                id={n.id}
                                fieldsData={this.state.itemListado}
                                handleFieldChange={this.handleChangeUser}
                                disabled={false}
                                size="medium"
                                shrink={true}
                                //disabled={n.id=="id"?!this.props.newProduct:this.state.editProduct}
                                //disabled={n.id=="id"?!this.props.newProduct:this.state.editProduct}
                                type={n.type}
                                required={n.required}
                                style={n.style}
                                helper={n.helper}
                                maxText={n.maxText}
                                //noError={n.required?this.verifyForm(0,n.id):true}
                                noError={true}
                                multiline={n.multiline}
                                rows={n.rows}
                                className='dialog-item-25'
                            />
                        )
                    case 3:
                        return(
                            <div className='dialog-item-25'>{DatePicker(n.id, n.name, "dd/MM/yyyy", this.handleChangeDate, this.state.itemListado[n.id], false, this.shouldDisableToday, new Date())}</div>
                        );
                    case 4:
                        return(
                            <div className='dialog-item-25'>{DatePicker(n.id, n.name, "dd/MM/yyyy", this.handleChangeDate, this.state.itemListado[n.id], true, this.shouldDisableToday, new Date())}</div>
                        );
                    case 5:
                        return(  
                            <FormControlLabel
                                value="start"
                                control={<Checkbox 
                                    key={index} 
                                    required={n.required} 
                                    id={n.id} 
                                    name={n.name}
                                    size="medium"
                                    checked={this.state.itemListado[n.id]}
                                    onChange={(evt:any)=>this.handleChangeCheckbox(n.id, evt)}
                                    />}
                                label={n.name}

                                labelPlacement="start"
                            />                          
                        );
                    default:
                        return('')
                }
            })
            return (
                <form key={index} noValidate autoComplete="off">
                    {fieldsN0}
                </form>
            )
        })

        return (
            <div>
            <Dialog fullScreen={false} open={true} aria-labelledby="form-dialog-title" className='dialog-data'>
                <DialogTitle id="form-dialog-title">{'FORMULARIO CREACIÓN CORRECTIVO'}</DialogTitle>
                <DialogContent>
                    {fieldsTMP}
                </DialogContent>
                <DialogActions>
                    {!this.props.loading?
                        <div style={{ display:'flex', gap:25 }}>
                            <Button onClick={()=>this.props.handleCloseItemForm()} className='cancel-dialog'>
                                {'Cancelar'}
                            </Button>
                            <Button disabled={!this.verifyForm(1)} onClick={()=>this.props.handleCreateItem(this.state.itemListado)} className={`confirm-dialog ${(!this.verifyForm(1)?'confirm-dialog-disable':'')}`}>
                                {'Crear'}
                            </Button>
                        </div>
                    :
                        <Loader loading={true}/>
                    }
                </DialogActions>
            </Dialog>
            <Dialog open={this.state.warningOpen} onClose={this.handleCloseWarningMessage} className='dialog-data dialog-warning' aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                    {this.state.warningTitle}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        ¡Atención! Será obligatorio añadir en el apartado comentarios el nombre de la subcontrata.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>                
                    <Button onClick={this.handleCloseWarningMessage} autoFocus>
                        Cerrar
                    </Button>
                </DialogActions>
            </Dialog>
            </div>
        );
    }
}
export default withStyles(listadoStyles)(FormCorrectivo);