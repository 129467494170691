import React, { useState } from 'react';
import { Formik } from 'formik';
import { Props } from './types';
import InformeUpdateFormInner from './InformeUpdateFormInner';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import esLocale from 'date-fns/locale/es';
import CreateDialog from '../../../../../../Components/CreateDialog';
import { SubmitAction } from '../../containers';
import { getValidationSchema } from '../../containers/validationSchema';
import { InformeRouteType } from "../../../../hooks/useInformesRouteParams";
import { forEach } from 'lodash';

const InformeUpdateForm = ({
  title,
  Header,
  Content,
  Actions,
  SubmitProps,
  disabled,
  disabledFields,
  onSubmit,
  onClickSubmit,
  onFormChange,
  onOpenMeasureDialog,
  ...props
}: Props) => {
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  const handleClickSubmit = async (action: SubmitAction, formikProps: any) => {
    if (action === SubmitAction.PUBLISH) {
      const formikErrors = await formikProps.validateForm();
      if (Object.keys(formikErrors).length > 0) {
        let errorMessages: string[] = [];
        forEach(formikErrors, (value, key) => {
          switch (key) {
            case 'sections':
              errorMessages.push('Secciones: Hay secciones sin completar, esta información es necesaria para publicar.');
              break;
            case 'participant':
              errorMessages.push(`Participante: ${value}`);
              break;
            case 'dtStartVisit':
              errorMessages.push(`Fecha inicio: ${value}`);
              break;
            case 'dtEndVisit':
              errorMessages.push(`Fecha fin: ${value}`);
              break;
            case 'name':
              errorMessages.push(`Nombre: ${value}`);
              break;
            case 'globalComment01':
              errorMessages.push(`Buenas practicas: ${value}`);
              break;
            case 'globalComment02':
              errorMessages.push(`Valoración global: ${value}`);
              break;
          }
        });
        setErrorMessages(errorMessages);
        setShowErrorModal(true);
        return;
      }
    }
    onClickSubmit(action);
  };

  const handleCloseErrorModal = () => {
    setShowErrorModal(false);
    setErrorMessages([]);
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
      <Formik
        {...props}
        onSubmit={onSubmit}
      >
        {(formikProps) => (
          <>
            <InformeUpdateFormInner
              title={title}
              Header={Header}
              Content={Content}
              Actions={Actions}
              SubmitProps={SubmitProps}
              disabled={disabled}
              disabledFields={disabledFields}
              onClickSubmit={(action: SubmitAction) => handleClickSubmit(action, formikProps)}
              onFormChange={onFormChange}
              onOpenMeasureDialog={onOpenMeasureDialog}
              {...formikProps}
            />
            {showErrorModal ? (
              <CreateDialog
                isOpen={showErrorModal}
                titleModal='Falta información en el documento'
                onCloseCreationModal={handleCloseErrorModal}
                inputText={
                  <ul>
                    {errorMessages.map((msg, index) => {
                      let cmt = msg.split(':');
                      return (<li key={index}><b>{cmt[0]}:</b> {cmt[1]}</li>)
                    })}
                  </ul>
                }
                typeModal={1}
              />
            ) : null}
          </>
        )}
      </Formik>
    </MuiPickersUtilsProvider>
  );
};

export default InformeUpdateForm;