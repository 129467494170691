import React, { Component } from 'react';
import { createStyles, withStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import ViewIcon from '@material-ui/icons/VisibilityOutlined';
import SignIcon from '@material-ui/icons/EditOutlined';
import { IMe, IPersonal, VForm, ICentroTrabajo, VIIIQuestion, IFirmante, IIJobplace, IJobplace } from '../../types';
import { SelectorField, CheckBoxField, TextFieldInput } from '../FormsWidgets';
import {cloneDeep, filter, find, findIndex} from 'lodash';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Signature from './Signature';
import { isValidEmail } from '../../vc_general';

const fields = [
    {
        id: 'email',
        name: 'Email',
        required: true,
        style:3,
        helper:'Debe ser un email válido',
        maxText:100,
        typeField:1,
        format: 'string',
        multiline:false,
        validation: (mail:string)=>isValidEmail(mail)
    },
    // {
    //     id: 'phone',
    //     name: 'Teléfono',
    //     required: true,
    //     style:3,
    //     helper:'Debe ser un teléfono válido',
    //     maxText:9,
    //     typeField:1,
    //     format: 'number',
    //     multiline:false
    // },
];


const styles = (theme: Theme) => createStyles({
    container: {
        padding: '10px',
        textAlign: 'center',
        overflowY:'auto'
    },
    image: {
        width: '100%',
        height: 'auto'
    },
    promotion: {
        //backgroundColor: '#f00',
        padding: '3px 15px',
        //color: '#fff',
        verticalAlign: 'middle',
    },
    root: {
    width: '100%',
    },
    paper: {
        width: '100%',
        marginTop: '5px',
        overflowX: 'auto',
        borderWidth: 2, 
        borderColor: '#00DEFF',
        borderStyle: 'solid',
    },
    table:{
        //tableLayout:'fixed'
        width: '100%',
    },
    row:{
        //wordWrap:'break-word'
        width:'100%',
        //backgroundColor:'#ddd'
    },
    heading: {
    fontSize: theme.typography.pxToRem(15),
    // fontWeight: theme.typography.fontWeightRegular,
    },
    item: {
        maxWidth:'200px',
        border: '2px solid #ccc',
        borderRadius: '15px',
        margin: '5px 10px',
        width: 'calc(100% - 20px)!important',
        boxShadow: '5px 5px 5px 0px rgba(0,0,0,0.50)',
        cursor: 'pointer',
        paddingTop: '10px',
        paddingBottom: '10px',
        position: 'relative',
    },
});


interface ISignaturePanelProps {
    classes:any;
    handleCloseItemForm: Function;
    handleAddSign: Function;
    handleFirmanteDataChange: Function;
    handleDeleteSign: Function;
    handleAskCreateForm: Function;
    sign_mode: number;
    firmantes: Array<IFirmante>;
}

interface ISignaturePanelState {
    itemSelected?:IFirmante;
    screen: number;
    creationSignatureOpen: boolean;
    firmantes: Array<IFirmante>;
    s_firmante?: IFirmante;
}

class SignaturePanel extends Component<ISignaturePanelProps, ISignaturePanelState> {
    constructor(props: ISignaturePanelProps) {
        super(props);
        this.state = {
            screen: (props.sign_mode == 2?1:3),
            creationSignatureOpen: false,
            firmantes: props.firmantes
        };
    }

    /** Signature component */

    onCloseSignatureModal = () => {
        this.setState({ creationSignatureOpen: false });
    }
    
    handleSignatureTaken = (signature:string) => {
        const {s_firmante, firmantes} = this.state;
        let nfirmantes = cloneDeep(firmantes);

        if(!s_firmante)
            return;

        const f_idnex = findIndex(nfirmantes, function(o) { return o.id == s_firmante.id});

        if(f_idnex == -1)
            return;
        
        nfirmantes[f_idnex].signature = signature;

        this.setState({ creationSignatureOpen: false, firmantes: nfirmantes}, ()=>this.props.handleAddSign(f_idnex, nfirmantes[f_idnex]));
        //console.log(signature);
    }

    handleSignatureDelete = (index:number, firmante:IFirmante) => {
        const {s_firmante, firmantes} = this.state;

        let nfirmantes = cloneDeep(firmantes);
        
        nfirmantes[index].signature = undefined;

        this.setState({ firmantes: nfirmantes}, ()=>this.props.handleDeleteSign(index, firmante));
    }

    handleFirmanteDataChange = (id:string, evt:any, type:string, idx:number) => {
        const {s_firmante, firmantes} = this.state;

        let nfirmantes = cloneDeep(firmantes); 

        let value = evt.target.value;

        if(id == "phone")
            value = value.replace(/\D/g, '');
        
        nfirmantes[idx][id] = value;

        this.setState({ firmantes: nfirmantes}, ()=>this.props.handleFirmanteDataChange(idx, id, nfirmantes[idx]));
        //console.log(signature);
    }

    handleSign = (firmante:IFirmante) => {
        this.setState({s_firmante: firmante, creationSignatureOpen: true});
    }

    handleItemClick=(item:IIJobplace)=>{
        /*if(item.id == 8) {
            this.props.handleAddM01Item(item);
        } else {
            this.setState({screen: 2, itemSelected: item});
        }*/
    }


    renderScreen = (screen: number, firmantes:Array<IFirmante>, s_firmante?: IFirmante) => {
        switch(screen){
            case 1: // Resumen firmas manuscritas
                return(
                    <div>
                        <Paper className={this.props.classes.paper}>
                            <Table className={this.props.classes.table} aria-label="simple table">  
                                {firmantes.map((item:IFirmante, i:number)=>{
                                    return(
                                        <TableBody key={i} style={{width:'100%'}}>
                                            <TableRow className={this.props.classes.row}> 
                                                {item.signature?
                                                    <TableCell  align="center">
                                                        <IconButton onClick={()=>this.setState({itemSelected: item, screen: 2})} aria-label="delete user">
                                                            <ViewIcon />
                                                        </IconButton>
                                                    </TableCell>
                                                :
                                                    <TableCell  align="center">
                                                        <IconButton onClick={()=>this.handleSign(item)} aria-label="delete user">
                                                            <SignIcon />
                                                        </IconButton>
                                                    </TableCell>
                                                }
                                                <TableCell  align="left">{`${item.name}`}</TableCell>
                                                {item.signature?
                                                    <TableCell  align="center">
                                                        <IconButton onClick={()=>this.handleSignatureDelete(i, item)} aria-label="delete user">
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </TableCell>
                                                :
                                                    null
                                                }
                                            </TableRow> 
                                        </TableBody>
                                    );
                                })}
                            </Table>
                        </Paper>
                    </div>
                );
            case 2: // Preview firma manuscrita
                if(!s_firmante)
                    return;
                return(
                    <div>
                        <div style={{border:'rgb(0, 43, 97) solid 3px', borderRadius:'10px', padding:'10px'}}>
                            {s_firmante.name}
                        </div>
                        <div>
                            <div style={{marginTop: 20}}>
                                <img key={0} src={s_firmante.signature} width={'auto'} height={'100px'} />
                            </div>
                        </div>
                    </div>
                );
            case 3: // Resumen firmas OTP
                return(
                    <div>
                        <Paper className={this.props.classes.paper}>
                            <Table className={this.props.classes.table} aria-label="simple table">  
                                {firmantes.map((item:IFirmante, i:number)=>{

                                    const isvalid_email = isValidEmail(item['email']);

                                    return(
                                        <TableBody key={i} style={{width:'100%'}}>
                                            <TableRow className={this.props.classes.row}> 
                                                <TableCell align="left">
                                                    <div style={{ display: 'flex', flexDirection: 'column', gap:'10px',alignItems:'center' }}>
                                                        <strong>{`${item.id} - ${item.name}`}</strong>
                                                        {
                                                            fields.map((field:any, idx:number)=>{
                                                                const validation = field.validation?field.validation(item[field.id]) : undefined

                                                                switch(field.typeField){
                                                                    case 1:
                                                                        return(
                                                                            <TextFieldInput
                                                                                key={idx}
                                                                                name={field.name}
                                                                                id={field.id}
                                                                                fieldsData={item}
                                                                                handleFieldChange={(id:string, evt:any, type:string)=>this.handleFirmanteDataChange(id, evt, type, i)}
                                                                                disabled={false}
                                                                                size="medium"
                                                                                shrink={true}
                                                                                type={field.format}
                                                                                required={field.required}
                                                                                style={field.style}
                                                                                helper={`${validation?"":field.helper}`}
                                                                                maxText={field.maxText}
                                                                                rows={1}
                                                                                noError={validation}
                                                                                multiline={field.multiline}
                                                                            />
                                                                        )
                                                                    default:
                                                                        return(
                                                                            null
                                                                        )
                                                                }
                                                            })
                                                        }
                                                        {/* <TextFieldInput
                                                            key={i}
                                                            name={'Teléfono'}
                                                            id={'phone'}
                                                            fieldsData={item}
                                                            handleFieldChange={(id:string, evt:any, type:string)=>this.props.handleFirmanteDataChange(id, evt, type, i)}
                                                            disabled={false}
                                                            size="medium"
                                                            shrink={true}
                                                            //disabled={n.id=="id"?!this.props.newProduct:this.state.editProduct}
                                                            //disabled={n.id=="id"?!this.props.newProduct:this.state.editProduct}
                                                            type={'string'}
                                                            required={true}
                                                            style={3}
                                                            helper={''}
                                                            maxText={9}
                                                            rows={1}
                                                            //noError={n.required?this.verifyForm(0,n.id):true}
                                                            noError={true}
                                                            multiline={false}
                                                        /> */}
                                                    </div>
                                                </TableCell>
                                            </TableRow> 
                                        </TableBody>
                                    );
                                })}
                            </Table>
                        </Paper>
                    </div>
                );

        }
    }

    renderButtons = (screen: number, item?:IFirmante) => {
        switch(screen){
            case 1:
            case 3:
                return(
                    <DialogActions style={{marginRight:'auto', display:'flex', marginLeft: 'auto', width: '90%', justifyContent:'space-between', flexWrap:'wrap', gap:'10px'}}>
                        <Button className={`cancel-dialog`} onClick={()=>this.props.handleCloseItemForm()} color="primary">
                            {'Cerrar'}
                        </Button>
                        <Button style={{width:'180px', minWidth:'180px'}} className={`confirm-dialog ${!this.verifyForm()?`confirm-dialog-disable`:``}`} disabled={!this.verifyForm()} onClick={()=>this.props.handleAskCreateForm()} color="primary">
                            {'Generar informe'}
                        </Button>
                    </DialogActions>
                );
            case 2:
                if(!item)
                    return null;

                return(
                    <DialogActions style={{marginRight:'auto', marginLeft: 'auto', width: '90%', justifyContent:'space-between'}}>
                        <Button className={`cancel-dialog`} onClick={()=>this.setState({screen: 1})} color="primary">
                            {'Atrás'}
                        </Button>
                    </DialogActions>
                );
        }
    }

    verifyForm=()=>{
        const {sign_mode} = this.props;
        const {firmantes} = this.state;

        if(sign_mode == 1) { // Validaciones por OTP
            // Check if any invalid email
            const s_index = findIndex(firmantes, function(o){return !isValidEmail(o.email)});
            if(s_index > -1)
                return false;

        } else { // Validaciones por firma manuscrita
            // Verifica que todos han firmado
            const s_index = findIndex(firmantes, function(o){return !o.signature});
            if(s_index > -1)
                return false;
            
            // if(!register.supervisor.signature)
            //     return false;
        }

        return true;
    }

    render() {
        const {itemSelected, screen, s_firmante, firmantes} = this.state;

        return (
            <React.Fragment>
                <Dialog fullScreen={false} open={true} aria-labelledby="form-dialog-title" className='dialog-little'>
                    <DialogTitle id="form-dialog-title">{'Firmas'}</DialogTitle>
                    <DialogContent>
                        {this.renderScreen(screen, firmantes, itemSelected)}
                    </DialogContent>
                        {this.renderButtons(screen, itemSelected)}
                </Dialog>

                {this.state.creationSignatureOpen && s_firmante?
                    <Signature
                        onClose={this.onCloseSignatureModal}
                        isOpen={this.state.creationSignatureOpen}
                        handleSignatureTaken={this.handleSignatureTaken}
                        firmante={s_firmante.name}
                    />
                :
                    null
                }
            </React.Fragment>
        );
    }
}
export default withStyles(styles)(SignaturePanel);