import React, { Component } from 'react';
import { createStyles, withStyles, Theme } from '@material-ui/core/styles';
import { Text, View, StyleSheet, Image, PDFViewer, PDFDownloadLink, pdf } from '@react-pdf/renderer';
import { Document, Page } from '../Material/pdfRenderComponents';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons//AddCircleOutlineOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import DatePicker from '../DatePicker';
import { IMe, VIIIForm, XQuestion, VIQuestion, IQuestion, IVRegister, XIQuestion, IIIRegister, ICentroTrabajo } from '../../types';
import { SelectorField, CheckBoxField, TextFieldInput } from '../FormsWidgets';
import {GetRole, GetEmpresa, millisToMinutesAndSeconds} from '../../helpers';
import TakeIcon from '@material-ui/icons/CheckCircleOutline';
import {cloneDeep, filter, find, findIndex} from 'lodash';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddActionIcon from '@material-ui/icons/AddBox';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import PageViewIcon from '@material-ui/icons/Pageview';
import { GetConcatedDate, GetDatetimeFormat } from '../../vc_general';
import OrderService from '../../ApiClients/OrderService';
import LoaderBeauty from '../Material/activityProgress';
import CreateModal from '../CreateDialog';
import ListSecciones from '../Material/MultiSelect';
import Loader from '../Loader';
import { ListChildComponentProps } from 'react-window';
import Add from '@material-ui/icons/Add';
import FormCorrectivo from '../FormCorrectivo';

const listadoStyles = ({
    button: {
        margin: 1,
        marginRight:15
    },
      leftIcon: {
        marginRight: 10,
    },
      rightIcon: {
        marginLeft: 1,
    },
      iconSmall: {
        fontSize: 20,
    },
    formControl: {
        margin: '1px',
        minWidth: 120,
        marginRight: 'auto',
    },
});

const styles = (theme: Theme) => createStyles({
    container: {
        padding: '10px',
        textAlign: 'center',
        overflowY:'auto'
    },
    image: {
        width: '100%',
        height: 'auto'
    },
    promotion: {
        //backgroundColor: '#f00',
        padding: '3px 15px',
        //color: '#fff',
        verticalAlign: 'middle',
    },
    root: {
    width: '100%',
    },
    paper: {
        width: '100%',
        marginTop: '5px',
        //overflowX: 'auto',
        borderWidth: 2, 
        borderColor: '#00809b',
        borderStyle: 'solid',
    },
    paper_02: {
        width: '100%',
        marginTop: '5px',
        //overflowX: 'auto',
        borderWidth: 2, 
        borderColor: '#ddd',
        borderStyle: 'solid',
    },
    table:{
        //tableLayout:'fixed'
        width: '100%',
    },
    row:{
        //wordWrap:'break-word'
        width:'100%',
        //backgroundColor:'#ddd'
    },
    heading: {
    fontSize: theme.typography.pxToRem(15),
    //fontWeight: theme.typography.fontWeightRegular,
    },
    item: {
        maxWidth:'200px',
        border: '2px solid #ccc',
        borderRadius: '15px',
        margin: '5px 10px',
        width: 'calc(100% - 20px)!important',
        boxShadow: '5px 5px 5px 0px rgba(0,0,0,0.50)',
        cursor: 'pointer',
        paddingTop: '10px',
        paddingBottom: '10px',
        position: 'relative',
    },
});

const useStyles = ({
    page: {
        //display: 'row',
        //backgroundColor: '#E4E4E4',
        margin: 10,
        padding: 10,
        flexGrow: 1,
    },
    body: {
        paddingTop: 15,
        paddingBottom: 80,
        paddingLeft: 15,
    },
    logo: {
        width: 60,
        height: 60,
    },
    logoFito: {
        width: 'auto',
        height: 'auto',
    },
    bussinessData: {
        width: 'auto',
        height: 'auto',
    },
    header:{
        marginTop:7,
        fontSize:10
    },
    secondHeader:{
        marginTop:7,
        fontSize:11,
        marginBottom:10
    },
    headerTable:{
        fontSize:10,
        marginVertical:'auto',
        padding:3,
        borderTop:'1pt solid rgba(0,0,0,0.31)',
        borderBottom:'1pt solid rgba(0,0,0,0.31)',
        borderLeft:'1pt solid rgba(0,0,0,0.31)',
    },
    lineTable:{
        fontSize:9,
        marginVertical:'auto',
        padding:3,
    },
    content: {
        marginRight:15,
        '@media min-width: 400': {
            flexDirection: 'row',
        },
    },
    content02: {
        marginRight:0,
        '@media min-width: 400': {
            flexDirection: 'row',
        },
    }
});
/*
const secciones_inspected = (list:Array<any>)=>{

    let secciones = list.map((seccion:any, index:number)=>{

        return (
            <View key={index} style={[useStyles.content, {paddingBottom:5}]}>
                <View style={{width:'13%'}}>
                    <Text style={[useStyles.lineTable, {textAlign:'center', justifyContent:'center', borderRight:'1pt solid rgba(0,0,0,0.31)'}]}>
                        {index + 1}
                    </Text>
                </View>
                <View style={{width:'87%'}}>
                    <Text style={[useStyles.lineTable, {textAlign:'center', justifyContent:'center', borderRight:'1pt solid rgba(0,0,0,0.31)'}]}>
                        {seccion.name}
                    </Text>
                </View>
            </View>
        );
    })
    return secciones;
}

const questions = (list:Array<IQuestion>)=>{

    let questions = list.map((question:IQuestion, index:number)=>{

        return (
            <View key={index} style={[useStyles.content, {paddingBottom:5}]}>
                <View style={{width:'55%'}}>
                    <Text style={[useStyles.lineTable, {textAlign:'left', justifyContent:'center', borderRight:'1pt solid rgba(0,0,0,0.31)'}]}>
                        {question.question}
                    </Text>
                </View>
                <View style={{width:'10%'}}>
                    <Text style={[useStyles.lineTable, {textAlign:'center', justifyContent:'center', borderRight:'1pt solid rgba(0,0,0,0.31)'}]}>
                        {question.answer}
                    </Text>
                </View>
                <View style={{width:'35%'}}>
                    <Text style={[useStyles.lineTable, {textAlign:'center', justifyContent:'center', borderRight:'1pt solid rgba(0,0,0,0.31)'}]}>
                        {question.comment}
                    </Text>
                </View>
            </View>
        );
    })
    return questions;
}
*/

const pdf_questions = (list:Array<IQuestion>)=>{

    let questions = list.map((question:IQuestion, index:number)=>{

        return (
            <View key={index} style={[useStyles.content02, {paddingTop:10, marginLeft:'auto', paddingRight:'5%'}]}>
                <View style={{width:'38%'}}>
                    <Text style={[useStyles.lineTable, {textAlign:'justify', justifyContent:'center', borderRight:'1pt solid rgba(0,0,0,0.31)'}]}>
                        {question.question}
                    </Text>
                </View>
                <View style={{width:'15%'}}>
                    <Text style={[useStyles.lineTable, {textAlign:'center', justifyContent:'center', borderRight:'1pt solid rgba(0,0,0,0.31)'}]}>
                        {question.answer}
                    </Text>
                </View>
                <View style={{width:'45%'}}>
                    <Text style={[useStyles.lineTable, {textAlign:'center', justifyContent:'center', borderRight:'1pt solid rgba(0,0,0,0.31)'}]}>
                        {question.comment}
                    </Text>
                </View>
            </View>
        );
    })
    return questions;
}

const pdf_observations_02 = (list:Array<XIQuestion>) => {
    let observations = list.map((observation:XIQuestion, index:number)=>{

        return (
            <View key={index} style={[useStyles.content02, {paddingTop:10, marginLeft:'auto', paddingRight:'5%'}]}>
                <View style={{width:'83%'}}>
                    <Text style={[useStyles.lineTable, {textAlign:'justify', justifyContent:'center', borderRight:'1pt solid rgba(0,0,0,0.31)'}]}>
                        {observation.description}
                    </Text>
                </View>
                <View style={{width:'15%'}}>
                    <Text style={[useStyles.lineTable, {textAlign:'center', justifyContent:'center', borderRight:'1pt solid rgba(0,0,0,0.31)'}]}>
                        {observation.number}
                    </Text>
                </View>
            </View>
        );
    })
    return observations;
}

const pdf_inspections_01 = (inspections:XQuestion) => {
    const data = inspections.section.map((section:VIQuestion, i01:number)=>{
        return(
            <View key={i01}>             
                <View style={{paddingBottom:10}}>
                    <View style={{width:'95%', marginTop:10, marginLeft:'auto', paddingRight:'5%'}}>
                        <Text style={[useStyles.headerTable, {textAlign:'left', justifyContent:'center', backgroundColor:'#ddd', borderRight:'1pt solid rgba(0,0,0,0.31)'}]}>{`${section.title}`}</Text>
                    </View>

                    <View style={[useStyles.content02, {paddingBottom:20, paddingTop:10, marginLeft:'auto', paddingRight:'5%'}]}>
                        <View style={{width:'40%'}}>
                            <Text style={[useStyles.headerTable, {textAlign:'center', justifyContent:'center'}]}>{`Punto inspección`}</Text>
                        </View>
                        <View style={{width:'15%'}}>
                            <Text style={[useStyles.headerTable, {textAlign:'center', justifyContent:'center', backgroundColor:'#ddd'}]}>{`Respuesta`}</Text>
                        </View>
                        <View style={{width:'45%'}}>
                            <Text style={[useStyles.headerTable, {textAlign:'center', justifyContent:'center', borderRight:'1pt solid rgba(0,0,0,0.31)'}]}>{`Comentario`}</Text>
                        </View>
                    </View>

                    {pdf_questions(section.questions)}
                </View>
            </View>
        );
    });

    return data;

}

const pdf_inspections_02 = (inspections:XQuestion) => {
    const data = inspections.section.map((section:VIQuestion, i01:number)=>{
        return(
            <View key={i01}>             
                <View style={{paddingBottom:10}}>
                    <View style={{width:'95%', marginTop:10, marginLeft:'auto', paddingRight:'5%'}}>
                        <Text style={[useStyles.headerTable, {textAlign:'left', justifyContent:'center', backgroundColor:'#ddd', borderRight:'1pt solid rgba(0,0,0,0.31)'}]}>{`${section.title}`}</Text>
                    </View>

                    <View style={[useStyles.content02, {paddingBottom:20, paddingTop:10, marginLeft:'auto', paddingRight:'5%'}]}>
                        <View style={{width:'40%'}}>
                            <Text style={[useStyles.headerTable, {textAlign:'center', justifyContent:'center'}]}>{`Punto inspección`}</Text>
                        </View>
                        <View style={{width:'15%'}}>
                            <Text style={[useStyles.headerTable, {textAlign:'center', justifyContent:'center', backgroundColor:'#ddd'}]}>{`Respuesta`}</Text>
                        </View>
                        <View style={{width:'45%'}}>
                            <Text style={[useStyles.headerTable, {textAlign:'center', justifyContent:'center', borderRight:'1pt solid rgba(0,0,0,0.31)'}]}>{`Comentario`}</Text>
                        </View>
                    </View>

                    {pdf_questions(section.questions)}

                    {section.observations.length > 0?
                        <View>
                            <View style={[useStyles.content02, {paddingBottom:20, paddingTop:10, marginLeft:'auto', paddingRight:'5%'}]}>
                                <View style={{width:'85%'}}>
                                    <Text style={[useStyles.headerTable, {textAlign:'center', justifyContent:'center'}]}>{`Problema`}</Text>
                                </View>
                                <View style={{width:'15%'}}>
                                    <Text style={[useStyles.headerTable, {textAlign:'center', justifyContent:'center', borderRight:'1pt solid rgba(0,0,0,0.31)'}]}>{`Nº Obs.`}</Text>
                                </View>
                            </View>

                            {pdf_observations_02(section.observations)}
                        </View>
                    :
                        null
                    }
                </View>

            </View>
        );
    });

    return data;

}

const pdf_inspections_03 = (inspections:XQuestion) => {
    const data = inspections.section.map((section:VIQuestion, i01:number)=>{
        return(
            <View key={i01}>             
                <View style={{paddingBottom:10}}>
                    <View style={{width:'95%', marginTop:10, marginLeft:'auto', paddingRight:'5%'}}>
                        <Text style={[useStyles.headerTable, {textAlign:'left', justifyContent:'center', backgroundColor:'#ddd', borderRight:'1pt solid rgba(0,0,0,0.31)'}]}>{`${section.title}`}</Text>
                    </View>

                    <View style={[useStyles.content02, {paddingBottom:20, paddingTop:10, marginLeft:'auto', paddingRight:'5%'}]}>
                        <View style={{width:'40%'}}>
                            <Text style={[useStyles.headerTable, {textAlign:'center', justifyContent:'center'}]}>{`Punto inspección`}</Text>
                        </View>
                        <View style={{width:'15%'}}>
                            <Text style={[useStyles.headerTable, {textAlign:'center', justifyContent:'center', backgroundColor:'#ddd'}]}>{`Respuesta`}</Text>
                        </View>
                        <View style={{width:'45%'}}>
                            <Text style={[useStyles.headerTable, {textAlign:'center', justifyContent:'center', borderRight:'1pt solid rgba(0,0,0,0.31)'}]}>{`Comentario`}</Text>
                        </View>
                    </View>

                    {pdf_questions(section.questions)}
                </View>
            </View>
        );
    });

    return data;

}

const PdfForm  = (props: ListChildComponentProps) =>{
    const register:VIIIForm = props.data[0];
    const me:IMe = props.data[1];
    const item:IVRegister = props.data[2];

    const empresa:ICentroTrabajo|undefined = GetEmpresa(item.centrotrabajoId, me);
    const duration = new Date().getTime()-new Date(register.dt_created).getTime();
    return(
        <Document>
            <Page size="A4" style={useStyles.body} >
                <View fixed>
                    <View style={[useStyles.content, {paddingBottom:20, justifyContent:'space-between'}]}>
                        <Text style={{fontSize:8, color:'#8c8c8c', fontWeight:'bold'}} fixed>
                            {`Fecha revisión: ${register.dt_reviewed}`}
                        </Text>
                        <Text style={{fontSize:8}} render={({ pageNumber, totalPages }) => (
                            `Pág. ${pageNumber} / ${totalPages}`
                        )} fixed />
                    </View>
                    <View style={[useStyles.content, {paddingBottom:20}]}>
                        <View style={{width:'25%', marginRight:30, paddingRight:15, borderRight:'1pt solid rgba(0,0,0,0.31)'}}>
                            {empresa?
                                <Image src={empresa.logo} style={useStyles.bussinessData}/>
                            :
                                null
                            }
                            <Text style={{fontWeight:25, fontSize:10, textAlign:'center', marginTop:10}}>{`${empresa?.description}`}</Text>
                        </View>
                        <View style={{width:'70%', height:120}}>
                            <Text style={{fontWeight:50, fontSize:20}}>{`${register.title}`}</Text>
                            <Text style={useStyles.header}>{`Fecha realización: ${GetDatetimeFormat(register.dt_created)}`}</Text>
                            <Text style={useStyles.header}>{`Realizado por: ${register.usuario}`}</Text>
                            <Text style={useStyles.header}>{`Duración: ${millisToMinutesAndSeconds(duration)} minutos`}</Text>
                        </View>
                    </View>
                </View>
                <View style={{width:'100%', marginTop:10, marginLeft:'auto', paddingRight:'5%'}}>
                    <Text style={[useStyles.headerTable, {textAlign:'left', justifyContent:'center', backgroundColor:'#ddd', borderRight:'1pt solid rgba(0,0,0,0.31)'}]}>{`Secciones`}</Text>
                </View>
                <View style={{width:'95%', marginTop:10, marginLeft:'auto', paddingRight:'5%'}}>
                    <Text style={[useStyles.headerTable, {textAlign:'left', justifyContent:'center', borderRight:'1pt solid rgba(0,0,0,0.31)'}]}>{`${register.secciones.map(function(o){return o.name}).join("\r\n")}`}</Text>
                </View>
                <View style={{width:'100%', marginTop:10, marginLeft:'auto', paddingRight:'5%'}}>
                    <Text style={[useStyles.headerTable, {textAlign:'left', justifyContent:'center', backgroundColor:'#ddd', borderRight:'1pt solid rgba(0,0,0,0.31)'}]}>{`Área inspeccionada`}</Text>
                </View>
                <View style={{width:'95%', marginTop:10, marginLeft:'auto', paddingRight:'5%'}}>
                    <Text style={[useStyles.headerTable, {textAlign:'left', justifyContent:'center', borderRight:'1pt solid rgba(0,0,0,0.31)'}]}>{`${register.inspected_area}`}</Text>
                </View>
                <View style={{width:'100%', marginTop:10, marginLeft:'auto', paddingRight:'5%'}}>
                    <Text style={[useStyles.headerTable, {textAlign:'left', justifyContent:'center', backgroundColor:'#ddd', borderRight:'1pt solid rgba(0,0,0,0.31)'}]}>{`Asistentes`}</Text>
                </View>
                <View style={{width:'95%', marginTop:10, marginLeft:'auto', paddingRight:'5%'}}>
                    <Text style={[useStyles.headerTable, {textAlign:'left', justifyContent:'center', borderRight:'1pt solid rgba(0,0,0,0.31)'}]}>{`${register.attendees}`}</Text>
                </View>
                <View style={{width:'100%', marginTop:10, marginLeft:'auto', paddingRight:'5%'}}>
                    <Text style={[useStyles.headerTable, {textAlign:'left', justifyContent:'center', backgroundColor:'#ddd', borderRight:'1pt solid rgba(0,0,0,0.31)'}]}>{`Destinatarios`}</Text>
                </View>
                <View style={{width:'95%', marginTop:10, marginLeft:'auto', paddingRight:'5%'}}>
                    <Text style={[useStyles.headerTable, {textAlign:'left', justifyContent:'center', borderRight:'1pt solid rgba(0,0,0,0.31)'}]}>{`${register.recipients}`}</Text>
                </View>
                <View style={{width:'100%', marginTop:10, marginLeft:'auto', paddingRight:'5%'}}>
                    <Text style={[useStyles.headerTable, {textAlign:'left', justifyContent:'center', backgroundColor:'#ddd', borderRight:'1pt solid rgba(0,0,0,0.31)'}]}>{`${register.inspections_01.title}`}</Text>
                </View>
                {pdf_inspections_01(register.inspections_01)}

                <View style={{width:'100%', marginTop:10, marginLeft:'auto', paddingRight:'5%'}}>
                    <Text style={[useStyles.headerTable, {textAlign:'left', justifyContent:'center', backgroundColor:'#ddd', borderRight:'1pt solid rgba(0,0,0,0.31)'}]}>{`${register.inspections_02.title}`}</Text>
                </View>
                {pdf_inspections_02(register.inspections_02)}

                <View style={{width:'100%', marginTop:10, marginLeft:'auto', paddingRight:'5%'}}>
                    <Text style={[useStyles.headerTable, {textAlign:'left', justifyContent:'center', backgroundColor:'#ddd', borderRight:'1pt solid rgba(0,0,0,0.31)'}]}>{`${register.inspections_03.title}`}</Text>
                </View>
                <View style={{width:'95%', marginTop:10, marginLeft:'auto', paddingRight:'5%'}}>
                    <Text style={[useStyles.headerTable, {textAlign:'left', justifyContent:'center', borderRight:'1pt solid rgba(0,0,0,0.31)'}]}>{`${register.inspections_03.usuario}`}</Text>
                </View>
                {pdf_inspections_03(register.inspections_03)}
            </Page>    
        </Document>
    );
}

const questions_01 = {title:"1. CHECK-LIST: ORDEN Y LIMPIEZA", section: [
        {title: "1. Locales", observations:[], questions: [
            {id:"q_1", question: "Las escaleras y plataformas están limpias, en buen estado y libres de obstáculos.", answer: "", comment: "", a_template: [{id:"SI",name:"SI"},{id:"NO",name:"NO"},{id:"NO APLICA",name:"NO APLICA"}]},
            {id:"q_2", question: "Las paredes están limpias y en buen estado.", answer: "", comment: "", a_template: [{id:"SI",name:"SI"},{id:"NO",name:"NO"},{id:"NO APLICA",name:"NO APLICA"}]},
            {id:"q_3", question: "Las ventanas y tragaluces están limpios sin impedir la entrada de luz natura.", answer: "", comment: "", a_template: [{id:"SI",name:"SI"},{id:"NO",name:"NO"},{id:"NO APLICA",name:"NO APLICA"}]},    
            {id:"q_4", question: "Las señales de seguridad están visibles y correctamente distribuida.", answer: "", comment: "", a_template: [{id:"SI",name:"SI"},{id:"NO",name:"NO"},{id:"NO APLICA",name:"NO APLICA"}]},    
            {id:"q_5", question: "Los extintores están en su lugar de ubicación, visibles y libres de obstáculo.", answer: "", comment: "", a_template: [{id:"SI",name:"SI"},{id:"NO",name:"NO"},{id:"NO APLICA",name:"NO APLICA"}]},    
            {id:"q_6", question: "Los extintores tienen señalización.", answer: "", comment: "", a_template: [{id:"SI",name:"SI"},{id:"NO",name:"NO"},{id:"NO APLICA",name:"NO APLICA"}]},    
            {id:"q_7", question: "Extintores y BIEs con fecha revisión visible.", answer: "", comment: "", a_template: [{id:"SI",name:"SI"},{id:"NO",name:"NO"},{id:"NO APLICA",name:"NO APLICA"}]},    
            {id:"q_8", question: "BIEs accesibles y libres de obstáculos.", answer: "", comment: "", a_template: [{id:"SI",name:"SI"},{id:"NO",name:"NO"},{id:"NO APLICA",name:"NO APLICA"}]},    
            {id:"q_9", question: "Pulsadores de alarma accesibles y libres de obstáculos.", answer: "", comment: "", a_template: [{id:"SI",name:"SI"},{id:"NO",name:"NO"},{id:"NO APLICA",name:"NO APLICA"}]},    
            {id:"q_10", question: "El vado sanitario funciona correctamente: aplica gel, los tornos giran correctamente.", answer: "", comment: "", a_template: [{id:"SI",name:"SI"},{id:"NO",name:"NO"},{id:"NO APLICA",name:"NO APLICA"}]},    
            {id:"q_11", question: "Los lavamanos funcionan correctamente.", answer: "", comment: "", a_template: [{id:"SI",name:"SI"},{id:"NO",name:"NO"},{id:"NO APLICA",name:"NO APLICA"}]},    
            {id:"q_12", question: "Puertas funcionan con normalidad.", answer: "", comment: "", a_template: [{id:"SI",name:"SI"},{id:"NO",name:"NO"},{id:"NO APLICA",name:"NO APLICA"}]},    
            {id:"q_13", question: "Los teléfonos y contactos de emergencia están actualizados.", answer: "", comment: "", a_template: [{id:"SI",name:"SI"},{id:"NO",name:"NO"},{id:"NO APLICA",name:"NO APLICA"}]},    
            {id:"q_14", question: "Iluminación adecuada y sin fluorescentes fundidos.", answer: "", comment: "", a_template: [{id:"SI",name:"SI"},{id:"NO",name:"NO"},{id:"NO APLICA",name:"NO APLICA"}]},    
            {id:"q_15", question: "Lavaojos, lavaojos portátiles y duchas de emergencia en correcto estado.", answer: "", comment: "", a_template: [{id:"SI",name:"SI"},{id:"NO",name:"NO"},{id:"NO APLICA",name:"NO APLICA"}]},    
            {id:"q_16", question: "Enchufes y cuadros eléctricos en correcto estado.", answer: "", comment: "", a_template: [{id:"SI",name:"SI"},{id:"NO",name:"NO"},{id:"NO APLICA",name:"NO APLICA"}]},    
            {id:"q_17", question: "Señalización recorridos evacuación visibles y correctamente distribuidas.", answer: "", comment: "", a_template: [{id:"SI",name:"SI"},{id:"NO",name:"NO"},{id:"NO APLICA",name:"NO APLICA"}]},    
            {id:"q_18", question: "Plataformas de trabajo con barandillas.", answer: "", comment: "", a_template: [{id:"SI",name:"SI"},{id:"NO",name:"NO"},{id:"NO APLICA",name:"NO APLICA"}]}
            ]
        },
        {title: "2. Suelos y pasillos", observations:[], questions: [
            {id:"q_1", question: "Los suelos están limpios, secos, sin desperdicios ni material innecesario.", answer: "", comment: "", a_template: [{id:"SI",name:"SI"},{id:"NO",name:"NO"},{id:"NO APLICA",name:"NO APLICA"}]},
            {id:"q_2", question: "Las vías de circulación de personas y vehículos están diferenciadas y señalizadas.", answer: "", comment: "", a_template: [{id:"SI",name:"SI"},{id:"NO",name:"NO"},{id:"NO APLICA",name:"NO APLICA"}]},
            {id:"q_3", question: "Los pasillos y zonas de tránsito están libres de obstáculos.", answer: "", comment: "", a_template: [{id:"SI",name:"SI"},{id:"NO",name:"NO"},{id:"NO APLICA",name:"NO APLICA"}]},
            {id:"q_4", question: "Las carretillas están aparcadas en los lugares especiales para ello.", answer: "", comment: "", a_template: [{id:"SI",name:"SI"},{id:"NO",name:"NO"},{id:"NO APLICA",name:"NO APLICA"}]},
            {id:"q_5", question: "Los tramex o plataformas de trabajo están en correcto estado.", answer: "", comment: "", a_template: [{id:"SI",name:"SI"},{id:"NO",name:"NO"},{id:"NO APLICA",name:"NO APLICA"}]},
            {id:"q_6", question: "Desagües y rejillas en correcto estado.", answer: "", comment: "", a_template: [{id:"SI",name:"SI"},{id:"NO",name:"NO"},{id:"NO APLICA",name:"NO APLICA"}]}
            ]
        },
        {title: "3. Almacenaje", observations:[], questions: [
            {id:"q_1", question: "Las áreas de almacenamiento y deposición de materiales están señalizadas.", answer: "", comment: "", a_template: [{id:"SI",name:"SI"},{id:"NO",name:"NO"},{id:"NO APLICA",name:"NO APLICA"}]},
            {id:"q_2", question: "Los materiales y sustancias se encuentran correctamente identificadas.", answer: "", comment: "", a_template: [{id:"SI",name:"SI"},{id:"NO",name:"NO"},{id:"NO APLICA",name:"NO APLICA"}]},
            {id:"q_3", question: "Los materiales están almacenados en su sitio sin invadir zonas de tránsito.", answer: "", comment: "", a_template: [{id:"SI",name:"SI"},{id:"NO",name:"NO"},{id:"NO APLICA",name:"NO APLICA"}]},
            {id:"q_4", question: "Los materiales se apilan o cargan de manera segura, limpia y ordenada.", answer: "", comment: "", a_template: [{id:"SI",name:"SI"},{id:"NO",name:"NO"},{id:"NO APLICA",name:"NO APLICA"}]},
            {id:"q_5", question: "Altura de los apilamientos correcta.", answer: "", comment: "", a_template: [{id:"SI",name:"SI"},{id:"NO",name:"NO"},{id:"NO APLICA",name:"NO APLICA"}]},
            {id:"q_6", question: "El bisulfito esta correctamente almacenado: nº GRGs, señalización.", answer: "", comment: "", a_template: [{id:"SI",name:"SI"},{id:"NO",name:"NO"},{id:"NO APLICA",name:"NO APLICA"}]}
            ]
        },
        {title: "4. Maquinaria y equipos", observations:[], questions: [
            {id:"q_1", question: "Se encuentran limpios y libres en su entorno de todo material innecesario.", answer: "", comment: "", a_template: [{id:"SI",name:"SI"},{id:"NO",name:"NO"},{id:"NO APLICA",name:"NO APLICA"}]},
            {id:"q_2", question: "Se encuentran libres de filtraciones innecesarias de aceites y grasas.", answer: "", comment: "", a_template: [{id:"SI",name:"SI"},{id:"NO",name:"NO"},{id:"NO APLICA",name:"NO APLICA"}]},
            {id:"q_3", question: "Los dispositivos de seguridad funcionan correctamente: setas de emergencia, sirga de seguridad, enclavamientos.", answer: "", comment: "", a_template: [{id:"SI",name:"SI"},{id:"NO",name:"NO"},{id:"NO APLICA",name:"NO APLICA"}]},
            {id:"q_4", question: "Mesas de trabajo en buen estado.", answer: "", comment: "", a_template: [{id:"SI",name:"SI"},{id:"NO",name:"NO"},{id:"NO APLICA",name:"NO APLICA"}]},
            {id:"q_5", question: "Cableado en correcto estado.", answer: "", comment: "", a_template: [{id:"SI",name:"SI"},{id:"NO",name:"NO"},{id:"NO APLICA",name:"NO APLICA"}]},
            {id:"q_6", question: "Elementos móviles de maquinaria sin acceso.", answer: "", comment: "", a_template: [{id:"SI",name:"SI"},{id:"NO",name:"NO"},{id:"NO APLICA",name:"NO APLICA"}]},
            {id:"q_7", question: "Maquinaria de trabajo situada en plataformas en altura con barandilla.", answer: "", comment: "", a_template: [{id:"SI",name:"SI"},{id:"NO",name:"NO"},{id:"NO APLICA",name:"NO APLICA"}]}
            ]
        },
        {title: "5. Herramientas", observations:[], questions: [
            {id:"q_1", question: "Están almacenadas en cajas o paneles, en su lugar correspondiente.", answer: "", comment: "", a_template: [{id:"SI",name:"SI"},{id:"NO",name:"NO"},{id:"NO APLICA",name:"NO APLICA"}]},
            {id:"q_2", question: "Se guardan limpias de aceite y grasa.", answer: "", comment: "", a_template: [{id:"SI",name:"SI"},{id:"NO",name:"NO"},{id:"NO APLICA",name:"NO APLICA"}]},
            {id:"q_3", question: "Las eléctricas tienen el cableado y las conexiones en buen estado.", answer: "", comment: "", a_template: [{id:"SI",name:"SI"},{id:"NO",name:"NO"},{id:"NO APLICA",name:"NO APLICA"}]},
            {id:"q_4", question: "Están en condiciones seguras para el trabajo, no defectuosas u oxidadas.", answer: "", comment: "", a_template: [{id:"SI",name:"SI"},{id:"NO",name:"NO"},{id:"NO APLICA",name:"NO APLICA"}]},
            {id:"q_5", question: "Los cuchillos están correctamente guardados en las cuchilleras.", answer: "", comment: "", a_template: [{id:"SI",name:"SI"},{id:"NO",name:"NO"},{id:"NO APLICA",name:"NO APLICA"}]},
            ]
        },
        {title: "6. Equipos de Protección Individual y Ropa de Trabajo", observations:[], questions: [
            {id:"q_1", question: "Se encuentran marcados o codificados para poderlos identificar por su usuario.", answer: "", comment: "", a_template: [{id:"SI",name:"SI"},{id:"NO",name:"NO"},{id:"NO APLICA",name:"NO APLICA"}]},
            {id:"q_2", question: "Se guardan en los lugares específicos de uso personalizado (armarios y taquillas).", answer: "", comment: "", a_template: [{id:"SI",name:"SI"},{id:"NO",name:"NO"},{id:"NO APLICA",name:"NO APLICA"}]},
            {id:"q_3", question: "Se encuentran limpios y en buen estado.", answer: "", comment: "", a_template: [{id:"SI",name:"SI"},{id:"NO",name:"NO"},{id:"NO APLICA",name:"NO APLICA"}]},
            {id:"q_4", question: "Cuando son desechables, se depositan en los contenedores adecuados.", answer: "", comment: "", a_template: [{id:"SI",name:"SI"},{id:"NO",name:"NO"},{id:"NO APLICA",name:"NO APLICA"}]},
            {id:"q_5", question: "Todo el personal hace uso de los EPIs obligatorios de su puesto de trabajo.", answer: "", comment: "", a_template: [{id:"SI",name:"SI"},{id:"NO",name:"NO"},{id:"NO APLICA",name:"NO APLICA"}]},
            {id:"q_6", question: "Afilador dispone de gafas de protección.", answer: "", comment: "", a_template: [{id:"SI",name:"SI"},{id:"NO",name:"NO"},{id:"NO APLICA",name:"NO APLICA"}]},
            {id:"q_7", question: "Bisulfito dispone de mascara de gas y guantes de productos químicos.", answer: "", comment: "", a_template: [{id:"SI",name:"SI"},{id:"NO",name:"NO"},{id:"NO APLICA",name:"NO APLICA"}]}
            ]
        }
    ]
};

const questions_02 = {title:"2. CHECK-LIST: SEGURIDAD Y SALUD", section: [
    {title: "1. Equipos y Maquinaria de trabajo - (Estado/Protecciones/Dispositivos Seguridad/Energías Peligrosas)", information:`EJEMPLOS\r\n-	Protecciones Seguridad equipadas y en buen estado.\r\n- Paradas emergencia / dispositivos seguridad equipados y en funcionamiento.\r\n- Herramientas en buen estado.\r\n- Funcionamiento correcto.\r\n-	Elementos móviles maquinaria sin acceso.\r\n- Barandillas.`, observations:[], questions: [
        {id:"q_1", question: "Aceptable.", answer: "", comment: "", a_template: [{id:"SI",name:"SI"},{id:"NO",name:"NO"}]},
    ]
    },
    {title: "2. Equipos de transporte y elevación", information:`EJEMPLOS\r\n-	Carros dañados.\r\n- Estanterías NO estables / material almacenamiento incorrecto.\r\n- Equipos de elevación en mal estado.\r\n- Carretillas y transpaletas.\r\n-	Equipos elevación mecánica.`, observations:[], questions: [
        {id:"q_1", question: "Aceptable.", answer: "", comment: "", a_template: [{id:"SI",name:"SI"},{id:"NO",name:"NO"}]},
    ]
    },
    {title: "3. Sistemas seguridad contra incendios / Primeros auxilios - (Extintores/Vías de evacuación/Señalización/Sistemas Lavaojos/ducha emergencia/ Botiquines)", information:`EJEMPLOS\r\n-	Extintores accesibles / señalizados y colocados.\r\n- Salidas y vías evacuación claras.\r\n- Lavaojos disponibles y dentro de la fecha de caducidad.\r\n- Señalización salida visible.\r\n-	Revisión de botiquines.`, observations:[], questions: [
        {id:"q_1", question: "Aceptable.", answer: "", comment: "", a_template: [{id:"SI",name:"SI"},{id:"NO",name:"NO"}]},
    ]
    },
    {title: "4. Productos químicos / Agentes biológicos (Etiquetado, Almacenamiento, Ficha Seguridad Disponible)", information:`EJEMPLOS\r\n-	Todos los contenedores de residuos son etiquetados y los residuos son clasificados correctamente.\r\n- Todos los recipientes de productos químicos están etiquetados.\r\n- Las Fichas Datos Seguridad están disponibles para los productos químicos utilizados.`, observations:[], questions: [
        {id:"q_1", question: "Aceptable.", answer: "", comment: "", a_template: [{id:"SI",name:"SI"},{id:"NO",name:"NO"}]},
    ]
    },
    {title: "5. Otros", information:`EJEMPLOS\r\n-	Cualquier otra situación de peligro que no encaje en las otras categorías.`, observations:[], questions: [
        {id:"q_1", question: "Aceptable.", answer: "", comment: "", a_template: [{id:"SI",name:"SI"},{id:"NO",name:"NO"}]},
    ]
    },
]};

const questions_03 = {title:"3. CHECK-LIST: ERGONOMÍA - OFICINA", usuario:``, section: [
    {title: "1. Ordenador portátil", information:``, observations:[], questions: [
        {id:"q_1", question: "Uso.", answer: "", comment: "", a_template: [{id:"SI",name:"SI"},{id:"NO",name:"NO"},{id:"NO APLICA",name:"NO APLICA"}]},
        {id:"q_1", question: "Uso de monitor más grande para uso prolongado.", answer: "", comment: "", a_template: [{id:"SI",name:"SI"},{id:"NO",name:"NO"},{id:"NO APLICA",name:"NO APLICA"}]},
        {id:"q_1", question: "Uso de teclado externo para uso prolongado.", answer: "", comment: "", a_template: [{id:"SI",name:"SI"},{id:"NO",name:"NO"},{id:"NO APLICA",name:"NO APLICA"}]},
        {id:"q_1", question: "Uso ratón externo para uso prolongado.", answer: "", comment: "", a_template: [{id:"SI",name:"SI"},{id:"NO",name:"NO"},{id:"NO APLICA",name:"NO APLICA"}]},
    ]
    },
    {title: "2. Posición teclado", information:"", observations:[], questions: [
        {id:"q_1", question: "Codos a 90 grados y sin tensión en los hombros.", answer: "", comment: "", a_template: [{id:"SI",name:"SI"},{id:"NO",name:"NO"},{id:"NO APLICA",name:"NO APLICA"}]},
    ]
    },
    {title: "3. Bandeja para teclado necesaria", information:"", observations:[], questions: [
        {id:"q_1", question: "Es necesario para ayudar a tener una correcta posición.", answer: "", comment: "", a_template: [{id:"SI",name:"SI"},{id:"NO",name:"NO"},{id:"NO APLICA",name:"NO APLICA"}]},
    ]
    },
    {title: "4. Pantalla", information:"", observations:[], questions: [
        {id:"q_1", question: "La distancia es de 40 - 74 cm desde los ojos.", answer: "", comment: "", a_template: [{id:"SI",name:"SI"},{id:"NO",name:"NO"},{id:"NO APLICA",name:"NO APLICA"}]},
        {id:"q_2", question: "Tamaño brillo es OK.", answer: "", comment: "", a_template: [{id:"SI",name:"SI"},{id:"NO",name:"NO"},{id:"NO APLICA",name:"NO APLICA"}]},
        {id:"q_3", question: "Parte superior del monitor a la altura +/- de los ojos.", answer: "", comment: "", a_template: [{id:"SI",name:"SI"},{id:"NO",name:"NO"},{id:"NO APLICA",name:"NO APLICA"}]},

    ]
    },
    {title: "4. Asiento", information:"", observations:[], questions: [
        {id:"q_1", question: "Pies apoyados en el suelo.", answer: "", comment: "", a_template: [{id:"SI",name:"SI"},{id:"NO",name:"NO"},{id:"NO APLICA",name:"NO APLICA"}]},
        {id:"q_2", question: "Brazos apoyados sobre el reposabrazos.", answer: "", comment: "", a_template: [{id:"SI",name:"SI"},{id:"NO",name:"NO"},{id:"NO APLICA",name:"NO APLICA"}]},
        {id:"q_3", question: "Buen apoyo lumbar.", answer: "", comment: "", a_template: [{id:"SI",name:"SI"},{id:"NO",name:"NO"},{id:"NO APLICA",name:"NO APLICA"}]},
        {id:"q_4", question: "Altura ajustable para facilitar otra posición.", answer: "", comment: "", a_template: [{id:"SI",name:"SI"},{id:"NO",name:"NO"},{id:"NO APLICA",name:"NO APLICA"}]},

    ]
    },
    {title: "5. Escritorio", information:"", observations:[], questions: [
        {id:"q_1", question: "Facilita la posición del monitor, teclado y ratón.", answer: "", comment: "", a_template: [{id:"SI",name:"SI"},{id:"NO",name:"NO"},{id:"NO APLICA",name:"NO APLICA"}]},
    ]
    },
    {title: "6. Porta documentos", information:"", observations:[], questions: [
        {id:"q_1", question: "Si es usado, en correcta posición.", answer: "", comment: "", a_template: [{id:"SI",name:"SI"},{id:"NO",name:"NO"},{id:"NO APLICA",name:"NO APLICA"}]},
    ]
    }
]};

interface IFormProps {
    me:IMe;
    classes: any;
    options:any;
    handleCloseItemForm: Function;
    item:IVRegister;
}

interface IFormState {
    creationModalOpen:boolean;
    modalAction:number;
    modalText:any;
    modalType:number;
    titleModal: string;
    htmlBody:boolean;
    loading:boolean;
    loadingModal:boolean;
    register:VIIIForm;
    creationCorrectivoOpen: boolean;
    creatingItem:boolean;
}

class Form extends Component<IFormProps, IFormState> {
    _going2upload:any = {attachments:[]};
    constructor(props: IFormProps) {
        super(props);
        this.state = {
            creationModalOpen:false,
            modalType: -1,
            modalAction: -1,
            modalText:'',
            htmlBody:false,
            loading: false,
            loadingModal: false,
            titleModal: `MENSAJE`,
            register: {
                filename: `Informe_Inspecciones_Internas_ICP_01_checklist_${GetConcatedDate(new Date())}.pdf`,
                dt_reviewed: `01/01/21`,
                dt_created: new Date(),
                dt_edited: new Date(),
                title: `Inspecciones Internas ICP_CHECK LIST`,
                usuario: props.me.userIdName,
                secciones: [],
                inspected_area: ``,
                attendees: ``,
                recipients: ``,
                inspections_01: questions_01,
                inspections_02: questions_02,
                inspections_03: questions_03
            },
            creationCorrectivoOpen: false,
            creatingItem:false,
        };
    }

    componentDidMount(){

    }

    onCloseCreationModal = () => {
        this.setState({ creationModalOpen: false, modalText: undefined, htmlBody:false });
    }

    onCloseFormItemModal = () => {
        this.setState({ creationCorrectivoOpen: false });
    }

    handleCreateItem=(item:any)=>{
        this.setState({creatingItem:true})
        this.saveData(item, 2);
    }

    saveData=async(item:IIIRegister, action:number)=>{
        let response:any = await OrderService.saveData(item, action);

        switch(response){
            case 1:
                this.setState({
                    creatingItem:false,
                    creationCorrectivoOpen:false,
                    modalText: `Correctivo generado correctamente.`,
                    modalType: 1,
                    creationModalOpen: true
                });
                break;
            default:
                this.setState({
                    creatingItem:false,
                    modalText: `¡ATENCIÓN! Fallo en la creación del correctivo, vuelva a intentarlo.`,
                    modalType: 1,
                    creationModalOpen: true
                });
        }
    }

    /* CALL BACKEND */

    sendAttachFiles = async () => {
        let response = await OrderService.createImgBlob(this._going2upload, 6);
        this._going2upload = {attachments:[]};
        let p = `Fallo en el proceso, vuelva a intentarlo.`;
        let modaltype = 1;
        let modalaction = -1;

        if(response == 1) {
            p = `Formulario adjuntado correctamente`;
            modaltype = 4;
            modalaction= 1;
        }
        
        this.setState({
            creationModalOpen:true,
            modalText:p,
            modalType: modaltype,
            modalAction: modalaction,
            loadingModal: false
        });
    }

    /* */

    handleCreateModal = (typeAction: number) => {
        switch(typeAction){
            case 1: // Implica que se ha adjuntado corractamente el formulario
                this.props.handleCloseItemForm(true);
                this.onCloseCreationModal();
                break;
            case 2: // Implica iniciar el proceso de adjuntar el formulario
                this.setState({loadingModal: true},()=>{
                    this.handleCreateForm();
                });
                //this.handleCreateForm();
                break;
            default:
                this.onCloseCreationModal();
        }
    }

    handleChangeUserQuestions_01=(id:any, event:any, i02:number, i03:number, type?:string)=>{
        let nRegister = cloneDeep(this.state.register);
        nRegister.inspections_01.section[i02].questions[i03][id] = event.target.value;
        this.setState({ register: nRegister });
    }

    handleChangeUserQuestions_02=(id:any, event:any, i02:number, i03:number, type?:string)=>{
        let nRegister = cloneDeep(this.state.register);
        nRegister.inspections_02.section[i02].questions[i03][id] = event.target.value;
        this.setState({ register: nRegister });
    }

    handleChangeUserQuestions_03=(id:any, event:any, i02:number, i03:number, type?:string)=>{
        let nRegister = cloneDeep(this.state.register);
        nRegister.inspections_03.section[i02].questions[i03][id] = event.target.value;
        this.setState({ register: nRegister });
    }

    handleChangeUserObservations=(id:any, event:any, i02:number, i03:number, type?:string)=>{
        let nRegister = cloneDeep(this.state.register);
        nRegister.inspections_02.section[i02].observations[i03][id] = event.target.value;
        this.setState({ register: nRegister });
    }

    handleDeleteObservation=(i02:number, i03:number, type?:string)=>{
        let nRegister = cloneDeep(this.state.register);
        nRegister.inspections_02.section[i02].observations.splice(i03, 1);
        this.setState({ register: nRegister });
    }

    handleChangeSelectDevices=(e:any, list:any)=>{
        let nRegister = Object.assign({}, this.state.register);
        nRegister.secciones = list;
        this.setState({ register: nRegister });
    }

    handleFieldHeaderChange=(id:any, event:any)=>{
        let nRegister = cloneDeep(this.state.register);
        nRegister[id] = event.target.value;
        this.setState({ register: nRegister });
    }
    
    handleAddObservation = (i02:number) => {
        let nRegister = cloneDeep(this.state.register);
        nRegister.inspections_02.section[i02].observations?.push({description:"", number: ""})
        this.setState({ register: nRegister });
    }

    handleCreateForm=()=>{
        /*this.setState({
            modalAction: -1,
            modalText: <PDFViewer style={{width:'40vw', height:'90vh'}}>{dale}</PDFViewer>,
            modalType: 1,
            creationModalOpen: true,
        });*/

        const {item, me} = this.props;
        const {register} = this.state;

        let dale = <PdfForm index={1} data={[register, me, item]} style={{}} />;

        const blob = pdf(dale).toBlob();

        blob.then(ret=>{

            let reader = new FileReader();

            reader.onloadend = async ()=>{
                this._going2upload.attachments.push({name: register.filename, stream: reader.result, centrotrabajoId: item.centrotrabajoId, tipo: item.tipo, listadoId: item.listadoTableId, id: item.id});
                this.sendAttachFiles();
            }
            reader.readAsDataURL(ret);

        }).catch(err=>{
            console.log(err)
        })
    }

    handleAskCreateForm=()=>{
        const {item} = this.props;

        this.setState({
            modalAction: 2,
            modalText: `¡ATENCIÓN! ¿Quiere finalizar el formulario y adjuntarlo a la acción ${item.id} de la orden de trabajo ${item.listadoTableId}?`,
            modalType: 2,
            creationModalOpen: true,
        });
    }

    verifyForm=(register:VIIIForm)=>{

        // Verifica hay almenos una sección
        if(register.secciones.length == 0)
            return false;

        // Verifica hay almenos una área inspeccionada
        if(register.inspected_area.length == 0)
            return false;

        // Verifica hay almenos un asistente
            if(register.attendees.length == 0)
                return false; 
                
        // Verifica hay almenos un destinatario
        if(register.recipients.length == 0)
            return false;

        // Verifica todas las preguntas del FORM01 han sido respondidas menos los comentarios
        let c_01 = true;
        register.inspections_01.section.map(i=>{
            i.questions.map(j=>{
                if(j.answer.length == 0)
                    c_01 = false;
            })
        })

        if(!c_01)
            return false; 

        // Verifica todas las preguntas del FORM02 han sido respondidas menos los comentarios
        let c_02 = true;
        register.inspections_02.section.map(i=>{
            i.questions.map(j=>{
                if(j.answer.length == 0)
                c_02 = false;
            })
        })

        if(!c_02)
            return false; 

        // Verifica todas las preguntas del FORM03 han sido respondidas menos los comentarios
        let c_03 = true;
        register.inspections_03.section.map(i=>{
            i.questions.map(j=>{
                if(j.answer.length == 0)
                c_03 = false;
            })
        })

        if(!c_03)
            return false; 

        return true;
    }

    renderForm_01 = (inspection:XQuestion, secciones:any) => {

        return(
            <Accordion defaultExpanded={false} style={{border:`2px solid rgb(0, 43, 97, 0.5)`, marginBottom:20, borderRadius:'10px'}}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-content"
                >
                <Typography style={{fontWeight:'bold'}}><strong style={{fontSize:'15px'}}>{`${inspection.title}`}</strong></Typography>
                </AccordionSummary>
                    {inspection.section.map((item_01:VIQuestion, idx_01:number)=>{
                        return(
                            <div style={{margin:10}}>
                                <Accordion defaultExpanded={false} style={{border:`2px solid rgb(0, 43, 97, 0.5)`, marginBottom:20, borderRadius:'10px'}}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-content"
                                    >
                                    <Typography style={{fontWeight:'bold'}}><strong style={{fontSize:'15px'}}>{`${item_01.title}`}</strong></Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div style={{display:'inline', width:'100%'}}>
                                            <Paper className={this.props.classes.paper}>
                                                <Table className={this.props.classes.table} aria-label="simple table">
                                                {item_01.questions.map((item_02:IQuestion, idx_02:number)=>{
                                                    return(
                                                        <TableBody key={idx_02} style={{width:'100%'}}>
                                                            <TableRow className={this.props.classes.row} style={{backgroundColor:'#00809b'}} key={idx_02}>
                                                                <TableCell style={{width:'100%', color:'#fff'}} align="center"><strong>{idx_02 + 1}</strong></TableCell>
                                                            </TableRow>
                                                            <TableRow className={this.props.classes.row}> 
                                                                <TableCell  align="left">{`${item_02.question}`}</TableCell>
                                                            </TableRow> 
                                                            <TableRow className={this.props.classes.row}> 
                                                                <TableCell align="center">
                                                                    <div style={{maxWidth:150}}>
                                                                        <SelectorField
                                                                            name={`Q_${idx_02 + 1}`}
                                                                            fieldsData={item_02.a_template}
                                                                            id={'answer'}
                                                                            deviceData={item_02}
                                                                            handleFieldChange={(id:string, evt:any)=>this.handleChangeUserQuestions_01(id,evt, idx_01, idx_02)}
                                                                            required={true}
                                                                            style={3}
                                                                        />
                                                                    </div>

                                                                </TableCell>
                                                            </TableRow> 
                                                            <TableRow className={this.props.classes.row}> 
                                                                <TableCell align="center">
                                                                    <TextFieldInput
                                                                        name={`C_${idx_02 + 1}`}
                                                                        id={'comment'}
                                                                        disabled={false}
                                                                        type={"text"}
                                                                        required={false}
                                                                        handleFieldChange={(id:string, evt:any, type:string)=>this.handleChangeUserQuestions_01(id,evt,idx_01, idx_02)}
                                                                        style={3}
                                                                        helper={""}
                                                                        fieldsData={item_02}
                                                                        maxText={2000}
                                                                        noError={true}
                                                                        size={"medium"}
                                                                        shrink={true}
                                                                    />
                                                                </TableCell>
                                                            </TableRow> 
                                                        </TableBody>
                                                    );
                                                })}  
                                                </Table>
                                            </Paper> 
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        )
                    })}
            </Accordion>
        );
    }

    renderForm_02 = (inspection:XQuestion, secciones:any) => {

        return(
            <Accordion defaultExpanded={false} style={{border:`2px solid rgb(0, 43, 97, 0.5)`, marginBottom:20, borderRadius:'10px'}}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-content"
                >
                <Typography style={{fontWeight:'bold'}}><strong style={{fontSize:'15px'}}>{`${inspection.title}`}</strong></Typography>
                </AccordionSummary>
                    {inspection.section.map((item_01:VIQuestion, idx_01:number)=>{
                        return(
                            <div style={{margin:10}}>
                                <Accordion defaultExpanded={false} style={{border:`2px solid rgb(0, 43, 97, 0.5)`, marginBottom:20, borderRadius:'10px'}}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-content"
                                    >
                                    <Typography style={{fontWeight:'bold'}}><strong style={{fontSize:'15px'}}>{`${item_01.title}`}</strong></Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div style={{display:'inline', width:'100%'}}>
                                            <Paper className={this.props.classes.paper}>
                                                <Table className={this.props.classes.table} aria-label="simple table">
                                                {item_01.questions.map((item_02:IQuestion, idx_02:number)=>{
                                                    return(
                                                        <TableBody key={idx_02} style={{width:'100%'}}>
                                                            <TableRow className={this.props.classes.row} style={{backgroundColor:'#00809b'}} key={idx_02}>
                                                                <TableCell style={{width:'100%', color:'#fff'}} align="center"><strong>{idx_02 + 1}</strong></TableCell>
                                                            </TableRow>
                                                            <TableRow className={this.props.classes.row}> 
                                                                <TableCell  align="left">{`${item_02.question}`}</TableCell>
                                                            </TableRow> 
                                                            <TableRow className={this.props.classes.row}> 
                                                                <TableCell align="center">
                                                                    <div style={{maxWidth:150}}>
                                                                        <SelectorField
                                                                            name={`Q_${idx_02 + 1}`}
                                                                            fieldsData={item_02.a_template}
                                                                            id={'answer'}
                                                                            deviceData={item_02}
                                                                            handleFieldChange={(id:string, evt:any)=>this.handleChangeUserQuestions_02(id,evt, idx_01, idx_02)}
                                                                            required={true}
                                                                            style={3}
                                                                        />
                                                                    </div>

                                                                </TableCell>
                                                            </TableRow> 
                                                        </TableBody>
                                                    );
                                                })}  
                                                </Table>
                                            </Paper> 

                                            <div style={{display:'flex', marginLeft:'auto', width:'100%'}}>
                                                <IconButton onClick={()=>this.handleAddObservation(idx_01)} aria-label="delete user">
                                                    <AddIcon />
                                                </IconButton>
                                            </div>
                                            <Paper className={this.props.classes.paper_02}>
                                                <Table className={this.props.classes.table} aria-label="simple table">
                                                    <TableRow className={this.props.classes.row} style={{backgroundColor:'#ddd'}} key={idx_01}>
                                                        <TableCell style={{width:'60%'}} align="center"><strong>{`Problema`}</strong></TableCell>
                                                        <TableCell style={{width:'35%'}} align="center"><strong>{`Nº Observaciones`}</strong></TableCell>
                                                        <TableCell style={{width:'5%'}}></TableCell>
                                                    </TableRow>
                                                    {item_01.observations.map((item_03:XIQuestion, idx_03:number)=>{
                                                        return(
                                                            <TableBody key={idx_03} style={{width:'100%'}}>
                                                                <TableRow className={this.props.classes.row}> 
                                                                    <TableCell align="center">
                                                                        <TextFieldInput
                                                                            name={``}
                                                                            id={'description'}
                                                                            disabled={false}
                                                                            type={"text"}
                                                                            required={false}
                                                                            handleFieldChange={(id:string, evt:any, type:string)=>this.handleChangeUserObservations(id,evt,idx_01,idx_03)}
                                                                            style={3}
                                                                            helper={""}
                                                                            fieldsData={item_03}
                                                                            maxText={2000}
                                                                            noError={true}
                                                                            size={"medium"}
                                                                            shrink={true}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell align="center">
                                                                        <TextFieldInput
                                                                            name={``}
                                                                            id={'number'}
                                                                            disabled={false}
                                                                            type={"text"}
                                                                            required={false}
                                                                            handleFieldChange={(id:string, evt:any, type:string)=>this.handleChangeUserObservations(id,evt,idx_01,idx_03)}
                                                                            style={3}
                                                                            helper={""}
                                                                            fieldsData={item_03}
                                                                            maxText={2000}
                                                                            noError={true}
                                                                            size={"medium"}
                                                                            shrink={true}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <IconButton onClick={()=>this.handleDeleteObservation(idx_01,idx_03)} aria-label="delete user">
                                                                            <DeleteIcon />
                                                                        </IconButton>
                                                                    </TableCell>
                                                                </TableRow> 
                                                            </TableBody>
                                                        );
                                                    })}  
                                                </Table>
                                            </Paper>

                                            <div style={{whiteSpace:'pre-line', marginTop:20}}>
                                                {`${item_01.information}`}
                                            </div>
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        )
                    })}
            </Accordion>
        );
    }

    renderForm_03 = (inspection:XQuestion, secciones:any) => {

        return(
            <Accordion defaultExpanded={false} style={{border:`2px solid rgb(0, 43, 97, 0.5)`, marginBottom:20, borderRadius:'10px'}}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-content"
                >
                <Typography style={{fontWeight:'bold'}}><strong style={{fontSize:'15px'}}>{`${inspection.title}`}</strong></Typography>
                </AccordionSummary>
                    {inspection.section.map((item_01:VIQuestion, idx_01:number)=>{
                        return(
                            <div style={{margin:10}}>
                                <Accordion defaultExpanded={false} style={{border:`2px solid rgb(0, 43, 97, 0.5)`, marginBottom:20, borderRadius:'10px'}}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-content"
                                    >
                                    <Typography style={{fontWeight:'bold'}}><strong style={{fontSize:'15px'}}>{`${item_01.title}`}</strong></Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div style={{display:'inline', width:'100%'}}>
                                            <Paper className={this.props.classes.paper}>
                                                <Table className={this.props.classes.table} aria-label="simple table">
                                                {item_01.questions.map((item_02:IQuestion, idx_02:number)=>{
                                                    return(
                                                        <TableBody key={idx_02} style={{width:'100%'}}>
                                                            <TableRow className={this.props.classes.row} style={{backgroundColor:'#00809b'}} key={idx_02}>
                                                                <TableCell style={{width:'100%', color:'#fff'}} align="center"><strong>{idx_02 + 1}</strong></TableCell>
                                                            </TableRow>
                                                            <TableRow className={this.props.classes.row}> 
                                                                <TableCell  align="left">{`${item_02.question}`}</TableCell>
                                                            </TableRow> 
                                                            <TableRow className={this.props.classes.row}> 
                                                                <TableCell align="center">
                                                                    <div style={{maxWidth:150}}>
                                                                        <SelectorField
                                                                            name={`Q_${idx_02 + 1}`}
                                                                            fieldsData={item_02.a_template}
                                                                            id={'answer'}
                                                                            deviceData={item_02}
                                                                            handleFieldChange={(id:string, evt:any)=>this.handleChangeUserQuestions_03(id,evt, idx_01, idx_02)}
                                                                            required={true}
                                                                            style={3}
                                                                        />
                                                                    </div>

                                                                </TableCell>
                                                            </TableRow> 
                                                            <TableRow className={this.props.classes.row}> 
                                                                <TableCell align="center">
                                                                    <TextFieldInput
                                                                        name={`C_${idx_02 + 1}`}
                                                                        id={'comment'}
                                                                        disabled={false}
                                                                        type={"text"}
                                                                        required={false}
                                                                        handleFieldChange={(id:string, evt:any, type:string)=>this.handleChangeUserQuestions_03(id,evt,idx_01, idx_02)}
                                                                        style={3}
                                                                        helper={""}
                                                                        fieldsData={item_02}
                                                                        maxText={2000}
                                                                        noError={true}
                                                                        size={"medium"}
                                                                        shrink={true}
                                                                    />
                                                                </TableCell>
                                                            </TableRow> 
                                                        </TableBody>
                                                    );
                                                })}  
                                                </Table>
                                            </Paper> 
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        )
                    })}
            </Accordion>
        );
    }

    render() {
        const {register, loading, creatingItem} = this.state;
        const {me, options} = this.props;

        
        let inspected_01 = this.renderForm_01(register.inspections_01, options["seccion"]);

        let inspected_02 = this.renderForm_02(register.inspections_02, options["seccion"]);

        let inspected_03 = this.renderForm_03(register.inspections_03, options["seccion"]);

        return (
            <div>
                <Dialog style={{padding:'25px'}} fullScreen={false} open={true} aria-labelledby="form-dialog-title" className='dialog-data form-dialog-data'>
                    <DialogTitle id="form-dialog-title">
                        <div className="App-header">
                            {`${register.title}`}
                        </div>
                    </DialogTitle>
                    <DialogContent>
                        <div style={{marginBottom: 20}}>
                            <Button className='option-button option-button-orange' size="small" onClick={()=>this.setState({creationCorrectivoOpen:true})} variant="contained" style={listadoStyles.button}>
                                <Add style={listadoStyles.leftIcon}/>
                                {`GENERAR CORRECTIVO`}
                            </Button>
                        </div>
                        <div style={{width:'100%', marginBottom:20}}>
                            <ListSecciones id={"secciones"} data={register.secciones} multiple={true} name={'Sección'} placeholder={'Sección'} options={options["seccion"]} handleChangeSelect={(e:any, list:any)=>this.handleChangeSelectDevices(e,list)}/>
                        </div>
                        <div style={{width:'100%', marginBottom:20}}>
                            <TextFieldInput
                                name={`Áreas inspeccionadas`}
                                id={'inspected_area'}
                                disabled={false}
                                type={"text"}
                                required={true}
                                handleFieldChange={(id:string, evt:any, type:string)=>this.handleFieldHeaderChange(id,evt)}
                                style={3}
                                helper={""}
                                fieldsData={register}
                                maxText={2000}
                                noError={true}
                                size={"medium"}
                                shrink={true}
                                multiline
                            />
                        </div>
                        <div style={{width:'100%', marginBottom:20}}>
                            <TextFieldInput
                                name={`Asistentes`}
                                id={'attendees'}
                                disabled={false}
                                type={"text"}
                                required={true}
                                handleFieldChange={(id:string, evt:any, type:string)=>this.handleFieldHeaderChange(id,evt)}
                                style={2}
                                helper={""}
                                fieldsData={register}
                                maxText={2000}
                                noError={true}
                                size={"medium"}
                                shrink={true}
                                multiline
                            />
                            <TextFieldInput
                                name={`Destinatarios`}
                                id={'recipients'}
                                disabled={false}
                                type={"text"}
                                required={true}
                                handleFieldChange={(id:string, evt:any, type:string)=>this.handleFieldHeaderChange(id,evt)}
                                style={2}
                                helper={""}
                                fieldsData={register}
                                maxText={2000}
                                noError={true}
                                size={"medium"}
                                shrink={true}
                                multiline
                            />
                        </div>
                        {inspected_01}
                        {inspected_02}
                        {inspected_03}
                        <div>
                            {this.state.creationModalOpen?
                                <CreateModal
                                    onCloseCreationModal={this.onCloseCreationModal}
                                    isOpen={this.state.creationModalOpen}
                                    inputText={this.state.modalText}
                                    typeModal={this.state.modalType}
                                    action={this.handleCreateModal}
                                    typeAction={this.state.modalAction}
                                    loadingModal={this.state.loadingModal}
                                    titleModal={this.state.titleModal}
                                />
                            :
                                null
                            }
                            {this.state.creationCorrectivoOpen?
                                <FormCorrectivo
                                    centrotrabajoId={this.props.item.centrotrabajoId}
                                    options={this.props.options}
                                    handleCloseItemForm={this.onCloseFormItemModal}
                                    handleCreateItem={this.handleCreateItem}
                                    loading={creatingItem}
                                />
                            :
                                null
                            }
                        </div>
                    </DialogContent>
                    <DialogActions style={{ borderTop: '#ff972b solid 3px'}}>
                        {!loading?
                            <div style={{marginRight:'auto', display:'flex', marginLeft: 'auto', width: '90%', justifyContent:'space-between'}}>
                                <Button onClick={()=>this.props.handleCloseItemForm(false)} className='cancel-dialog'>
                                    {'Cancelar'}
                                </Button>
                                <Button disabled={!this.verifyForm(register)} onClick={()=>this.handleAskCreateForm()} className={`confirm-dialog ${!this.verifyForm(register)?`confirm-dialog-disable`:``}`}>
                                    {`Generar informe`}
                                </Button>
                            </div>
                        :
                            <Loader loading={true}/>
                        }
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default withStyles(styles)(Form);