import React, { Component } from 'react';
import { createStyles, withStyles, Theme } from '@material-ui/core/styles';
import Layout from './Layout';
import Empresa from './Empresa';
import Button from '@material-ui/core/Button';
import Add from '@material-ui/icons/Add';
import PaginatedTable from './Material/PaginatedSettingUsers';
import {filter, includes, cloneDeep} from 'lodash';
import OrderService from '../ApiClients/OrderService';
import { SelectorField, CheckBoxField, TextFieldInput } from './FormsWidgets';
import Toolbar from '@material-ui/core/Toolbar';
import {GetRole, GetEmpresa} from '../helpers';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FilterListIcon from '@material-ui/icons/FilterList';
import IconButton from '@material-ui/core/IconButton';
import ClearAllIcon from '@material-ui/icons/LayersClearOutlined';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Loader from './Loader';
import DatePicker from './DatePicker';
import { IMe, IRegister, IIRegister, ICentroTrabajo, IUser } from '../types';
import LoaderBeauty from './Material/activityProgress';
import CreateModal from './CreateDialog';
import List from './Material/MultiSelect';
import SearchField from './SearchField';
import LateralMenu from './LateralMenu';

function tmRegister(centrotrabajoId: any){
    return ({
        centrotrabajoId:centrotrabajoId,
        id: -1,
        userId: '',
        name: '',
        surname:'',
        role:-1, 
        roleDsc: '',
        activated: true,
        activatedcentro: true,
        department:[]
    });
};

const listadoStyles = ({
    button: {
        margin: 1,
        marginRight:15
    },
      leftIcon: {
        marginRight: 10,
    },
      rightIcon: {
        marginLeft: 1,
    },
      iconSmall: {
        fontSize: 20,
    },
    formControl: {
        margin: '1px',
        minWidth: 120,
        marginRight: 'auto',
    },
});

const fieldsUsers = [
    [
        {
            id: 'userId',
            name: 'Email',
            required: true,
            style:3,
            helper:'',
            maxText:100,
            typeField:2,
            multiline:false,
            disable:false
        },
        {
            id: 'name',
            name: 'Nombre',
            required: true,
            style:3,
            helper:'',
            maxText:50,
            typeField:2,
            multiline:false,
            disable:true
        },
        {
            id: 'surname',
            name: 'Apellidos',
            required: true,
            style:3,
            helper:'',
            maxText:100,
            typeField:2,
            multiline:false,
            disable:true
        },        
        {
            id: 'role',
            name: 'Rol',
            required: true,
            style:1,
            typeField:1,
        },
        {
            id: 'departments',
            name: 'Departamentos',
            required: true,
            style:1,
            typeField:4,
        },
        {
            id: 'activatedcentro',
            name: 'Activo en el centro',
            required: true,
            style:1,
            typeField:5,
        }
    ],
    [
        {
            id: 'activated',
            name: 'Activo en la aplicación',
            required: true,
            style:1,
            typeField:5,
        }
    ]
];

interface IFormListadoItemProps {
    classes: any;
    options:any;
    handleCreateItem:Function;
    handleCloseItemForm: Function;
    loading:boolean;
    //centrotrabajoId:string;
    register: IUser;
}

interface IFormListadoItemState {
    itemListado:any;
}

class FormListadoItem extends Component<IFormListadoItemProps, IFormListadoItemState> {
    constructor(props: IFormListadoItemProps) {
        super(props);
        this.state = {
            itemListado: props.register,
        };
    }

    handleChangeUser=(id:any, event:any)=>{
        let newItemListado = Object.assign({}, this.state.itemListado);
        newItemListado[id] = event.target.value;
        this.setState({ itemListado: newItemListado });
    }
    
    handleBlurUser = async (id:any, event:any)=>{                
        if(id === 'userId'){
            let response = await OrderService.checkUser(event.target.value);
            const nameInput:any = document.getElementById('name');
            const surnameInput:any = document.getElementById('surname');
            if(nameInput && surnameInput){
                nameInput.disabled = response.exists;
                surnameInput.disabled = response.exists;
                let newItemListado = Object.assign({}, this.state.itemListado);
                newItemListado['userId'] = event.target.value;
                newItemListado['name'] = response.name;
                newItemListado['surname'] = response.surname;
                this.setState({ itemListado: newItemListado });
            }            
            
        }

    }
    
    handleChangeCheckBox=(id:any, event:any)=>{
        let newItemListado = Object.assign({}, this.state.itemListado);
        newItemListado[id] = event.target.checked;
        this.setState({ itemListado: newItemListado });
    }    
    

    shouldDisableToday = (date:any) => {
        return false;
    }

    handleChangeDate=(id:any, event:any)=>{
        let newItemListado = Object.assign({}, this.state.itemListado);
        newItemListado[id] = event;
        this.setState({ itemListado: newItemListado });
    }

    handleListChange=(id:any, e:any, list:any)=> {
        let newItemListado = Object.assign({}, this.state.itemListado);
        newItemListado[id] = list;
        this.setState({ itemListado: newItemListado });
    }

    verifyForm=(type:number, id?:string)=>{

        if(type == 1){ // Verifica todo el formulario
            const item = this.state.itemListado;
            if(item.role == -1){
                return false;
            } else if(item.userId.length == 0){
                return false;
            }

            return true;
        } else if(type == 2){ // Verifica el ítem

        }

        return false;
        /*let idProduct : number = Number(this.state.productInfo.id)
        let ivaProduct : number = Number(this.state.productInfo.iva)

        if(verificationType == 0) {
            switch(id){
                case "id":
                    if(idProduct <= 0){
                        return false;
                    }
                    break;
                case "name":
                    if(this.state.productInfo.name.length == 0){
                        return false;
                    }
                    break;
                case "iva":
                    if(ivaProduct < 0){
                        return false;
                    }
                    break;
            }
            return true;
        } else {

            if(idProduct <= 0){
                return false;
            }

            if(this.state.productInfo.name.length == 0){
                return false;
            }

            if(ivaProduct < 0){
                return false;
            }
            return true;
        }*/

    }
    handleResetItemForm = () => {
        this.setState({itemListado:undefined});
    }

    render() {
        const {itemListado} = this.state;

        let fieldsTMP = fieldsUsers.map((n0:any, index:number)=>{
            let fieldsN0 = n0.map((n:any, index:number)=>{
                switch(n.typeField){
                    case 1:
                        if(n.id !== "role" || this.props.register.role !== 100)
                            return(
                                <SelectorField
                                    key={index}
                                    name={n.name}
                                    id={n.id}
                                    fieldsData={this.props.options[n.id]?this.props.options[n.id]:[]}
                                    keyDescription={n.id}
                                    handleFieldChange={this.handleChangeUser}
                                    //disabled={n.id=="id"?!this.props.newProduct:this.state.editProduct}
                                    required={n.required}
                                    style={n.style}
                                    deviceData={itemListado}
                                />
                            );
                        else
                            return(
                                <div style={{marginBottom:20}}>
                                    <strong>{`${this.props.register.roleDsc}`}</strong>
                                </div>
                            )
                    case 2:
                        return(
                            <TextFieldInput
                                key={index}
                                name={n.name}
                                id={n.id}
                                fieldsData={itemListado}
                                handleFieldChange={this.handleChangeUser}
                                handleFieldBlur={this.handleBlurUser}
                                disabled={(this.props.register.id !== -1 && (n.id == "userId" || n.id == "name" || n.id == "surname") )?true:false}
                                size="medium"
                                shrink={true}                                
                                //disabled={n.id=="id"?!this.props.newProduct:this.state.editProduct}
                                //disabled={n.id=="id"?!this.props.newProduct:this.state.editProduct}
                                type={n.type}
                                required={n.required}
                                style={n.style}
                                helper={n.helper}
                                maxText={n.maxText}
                                //noError={n.required?this.verifyForm(0,n.id):true}
                                noError={true}
                                multiline={n.multiline}
                            />
                        );
                    case 3:
                        return(
                            DatePicker(n.id, n.name, "dd/MM/yyyy", this.handleChangeDate, itemListado.fechaReferencia, false, this.shouldDisableToday, new Date('1970-01-01'))
                        );
                    case 4:
                        return(
                            <div style={{ backgroundColor:'#fff', borderRadius:11 }}>
                                <List id={n.id} data={itemListado[n.id]} multiple={true} name={n.name} placeholder={n.name} options={this.props.options[n.id]?filter(this.props.options[n.id], function(o){return o.activated}):[]} handleChangeSelect={(e:any, list:any)=>this.handleListChange(n.id,e,list)}/>
                            </div>
                        );
                    case 5:
                        return(
                            <CheckBoxField
                                key={index}
                                name={n.name}
                                id={n.id}
                                fieldsData={itemListado}
                                handleFieldChange={this.handleChangeCheckBox}
                                disabled={false}
                                size="medium"
                                shrink={true}
                                //disabled={n.id=="id"?!this.props.newProduct:this.state.editProduct}
                                //disabled={n.id=="id"?!this.props.newProduct:this.state.editProduct}
                                type={n.type}
                                //noError={n.required?this.verifyForm(0,n.id):true}
                            />
                        )
                    default:
                        return('');
                }
            })
            return (
                <form key={index} noValidate autoComplete="off">
                    {fieldsN0}
                </form>
            )
        })

        return (
            <Dialog fullScreen={false} open={true} aria-labelledby="form-dialog-title" className='dialog-little'>
                <DialogTitle id="form-dialog-title">{'FORMULARIO USUARIO'}</DialogTitle>
                <DialogContent>
                    {fieldsTMP}
                </DialogContent>
                <DialogActions>
                    {!this.props.loading?
                        <div style={{ display:'flex', gap:20 }}>
                            <Button onClick={()=>this.props.handleCloseItemForm()} className="cancel-dialog">
                                {'Cancelar'}
                            </Button>
                            <Button disabled={!this.verifyForm(1)} onClick={()=>this.props.handleCreateItem(this.state.itemListado)} className="confirm-dialog">
                                {`${itemListado.id == -1?`Crear`:`Actualizar`}`}
                            </Button>
                        </div>
                    :
                        <Loader loading={true}/>
                    }
                </DialogActions>
            </Dialog>
        );
    }
}
const FormListado = withStyles(listadoStyles)(FormListadoItem);

interface IListadoProps {
    history:any;
    match:any;
    me?: IMe;
    version: string;
}

interface IListadoState {
    rowSelected: number;
    creationModalOpen:boolean;
    creationItemOpen: boolean;
    creationModalOpenEmail:boolean;
    modalAction:number;
    modalText:any;
    modalType:number;
    htmlBody:boolean;
    sendingEmail:boolean;
    emailResult:number;
    currentYearSelected: number|string;
    loading:boolean;
    loadingDocuments:boolean;
    documents:any;
    filters:any;
    options?:any;
    searchValue:string;
    creatingItem:boolean;
    bRegister: IUser;
    loadingModal: boolean;
    titleModal: string;
}

class Income extends Component<IListadoProps, IListadoState> {
    constructor(props: IListadoProps) {
        super(props);
        this.state = {
            rowSelected: -1,
            //dataIncome: dataIncome,
            documents:[],
            creationModalOpen:false,
            creationItemOpen:false,
            creationModalOpenEmail:false,
            modalType: -1,
            modalAction: -1,
            modalText:'',
            sendingEmail:false,
            emailResult:-1,
            htmlBody:false,
            currentYearSelected: new Date().getFullYear(),
            loading: true,
            loadingDocuments: true,
            filters:{},
            searchValue: '',
            creatingItem: false,
            bRegister: tmRegister(this.props.match.params.centrotrabajoId),
            loadingModal: false,
            titleModal: `MENSAJE`
        };
    }

    componentDidMount(){
        this.loadData();
    }

    componentDidUpdate(prevProps:IListadoProps){
        if(prevProps.me !== this.props.me)
            this.loadData();
    }

    loadData = () => {
        const {me} = this.props;

        if(!me)
            return;
        if ([50, 100].indexOf(GetRole(me, this.props.match.params.centrotrabajoId)) > -1) {
            this.loadUsers();
        } else {
            this.setState({
                loading:false,
                loadingDocuments: false
            })
        }
    }

    loadUsers = async () => {
        const centrotrabajoId = Number(this.props.match.params.centrotrabajoId);
        let response = await OrderService.LoadUsers(centrotrabajoId);
        this.setState({loadingDocuments: false, documents: response.users, options: {role:response.roles, departments:response.departments}});
    }

    handleFilterDocuments=(documents:Array<IUser>)=>{
        let newDocuments = cloneDeep(documents);

        //** FILTRA POR EL VALOR DE BÚSQUEDA */
        const searchValue = this.state.searchValue.toLowerCase();

        if(newDocuments.length > 0 && searchValue && searchValue.length > 0){
            newDocuments = filter(newDocuments, function(o){return includes(o.userId.toLowerCase(), searchValue) || includes(o.roleDsc.toLowerCase(), searchValue) });
        }

        return newDocuments;
    }

    handleSearchChange=(evt:any)=>{
        this.setState({searchValue:evt.target.value})
    }

    handleClearSearchValue=()=>{
        this.setState({searchValue:''})
    }

    handleCleanFilters = (evt:any)=>{
        evt.stopPropagation();
        this.setState({filters:{}});
    }

    onCloseCreationModal = () => {
        this.setState({ creationModalOpen: false, modalText: undefined, htmlBody:false, });
    }

    onCloseFormItemModal = () => {
        this.setState({ creationItemOpen: false });
    }

    /* CALL BACKEND */


    /* */

    handleCreateModal = (typeAction: number) => {
        switch(typeAction){
            case 1:
                break;
        }
        this.onCloseCreationModal();
    }

    onCloseCreationModalEmail = () => {
        this.setState({ creationModalOpenEmail: false });
    }

    handleClickListado=(income:number)=>{
        this.setState({creationItemOpen:true, bRegister: tmRegister(this.props.match.params.centrotrabajoId)});
        /*switch(income){
            case 1:
                this.props.history.push('/generar/preventivo')
                break;
            case 2:
                this.props.history.push('/ingressos/correctivo')
                break;
        }*/
    }

    handleSelectYearChange = (event:any) => {
    }

    handleItemClick=(item:any)=>{
        this.props.history.push(`${window.location.hash.replace('#','')}/${item.id}`)
    }

    handleChangeFilter=(id:string, evt:any)=>{
        let newFilters = cloneDeep(this.state.filters);
        newFilters[id] = evt.target.value;
        this.setState({filters: newFilters});
    }

    handleCreateItem=(item:any)=>{
        this.setState({creatingItem:true})
        this.saveData(item);
    }

    saveData=async(item:any)=>{
        let response:any = await OrderService.saveData(item, 10);
        if(response == 2){
            this.setState({
                modalText: '¡ATENCIÓN! Ya existe el usuario.',
                modalType: 1,
                creationModalOpen: true,
                creatingItem:false,
            });
        } else {
            this.loadUsers();
            this.setState({creatingItem:false, creationItemOpen:false});
        }
    }

    handleClickOptionRow=(register:IUser, option:number)=>{
        switch(option){
            case 1: // Implica editar el registro
                this.setState({creationItemOpen:true, bRegister: register})
                break;
            default:
                break;
        }
    }

    render() {

        const {loadingDocuments, options, filters, documents, searchValue, creatingItem, bRegister} = this.state;
        const {me} = this.props;
        const empresa:ICentroTrabajo|undefined = GetEmpresa(this.props.match.params.centrotrabajoId, me);

        let title = (
            <div className='App-title-webPage'>
                {`Panel control usuarios`}
                {'  '}
                {/*<Loader loading={this.state.loading} />*/}
            </div>
        );


        return (
            <div className='App-basic'>
                <Layout 
                    history={this.props.history} 
                    currentPage={1}
                    allowDrawer={true}
                    match={this.props.match}
                    version={this.props.version}
                    me={me}
                    showEmpresa
                    showLateralMenu
                />
                <div className='App-body-webBar'>
                    {me?
                        [50,100].indexOf(GetRole(me, this.props.match.params.centrotrabajoId)) == -1?
                            <div>
                                <div className="App-header">
                                    {title}
                                </div>
                                <label style={{ marginLeft:10 }}>
                                    {`No tiene permisos para visualizar este espacio.`}
                                </label>
                            </div>
                        :
                            <div>
                                <div className="App-header">
                                    {title}
                                    <div className="App-header-options">
                                        <div style={{ display:'flex', gap:'16px' }}>
                                            <div className={`App-header-options-item App-header-options-item-create ${!loadingDocuments?null:`App-header-options-item-disable`}`} onClick={()=>(!loadingDocuments?this.handleClickListado(1):null)}>
                                                {`+ Crear usuario`}
                                            </div>
                                        </div>
                                        <SearchField placeholder="Buscar..." value={searchValue} allowSearch={true} onValueChange={this.handleSearchChange} handleClearSearchValue={this.handleClearSearchValue}/>
                                    </div>
                                </div>
                                <div>
                                    <PaginatedTable
                                        data={this.handleFilterDocuments(documents)}
                                        handleSearch={this.handleSearchChange}
                                        searchValue={searchValue}
                                        onClearSearchValue={this.handleClearSearchValue}
                                        handleItemClick={this.handleItemClick}
                                        handleClickOptionRow={this.handleClickOptionRow}
                                    />
                                </div>
                                {this.state.creationItemOpen?
                                    <FormListado
                                        options={options}
                                        handleCloseItemForm={this.onCloseFormItemModal}
                                        handleCreateItem={this.handleCreateItem}
                                        loading={creatingItem}
                                        register={bRegister}
                                    />
                                :
                                    null
                                }
                                {this.state.creationModalOpen?
                                    <CreateModal
                                        onCloseCreationModal={this.onCloseCreationModal}
                                        isOpen={this.state.creationModalOpen}
                                        inputText={this.state.modalText}
                                        typeModal={this.state.modalType}
                                        action={this.handleCreateModal}
                                        typeAction={this.state.modalAction}
                                        loadingModal={this.state.loadingModal}
                                        titleModal={this.state.titleModal}
                                    />
                                :
                                    null
                                }
                            </div>
                    :
                        this.state.loading?
                            <div style={{marginLeft:20}}>
                                <Loader loading={true}/>
                            </div>
                        :
                            `Fallo en la carga del perfil, volver a intentar`
                    }
                    
                    <LoaderBeauty visible={loadingDocuments?true:false}/>
                </div> 
            </div>
        );
    }
}

export default Income;