import React from 'react';
import clsx from 'clsx';
import { createStyles, lighten, makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import { IMe, IPersonal } from '../../types';
import SearchField from '../SearchField';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { GetRole } from '../../helpers';
import OverflowCell from './OverflowCell';
import CreateModal from '../CreateDialog';


const ITEM_HEIGHT = 48;

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof IPersonal;
  label: string;
  numeric: boolean;
}

interface OptionRow {
  disablePadding: boolean;
  id: number;
  label: string;
  numeric: boolean;
  isfileuploader: boolean;
  roles:Array<Number>;
}

const headCells: HeadCell[] = [
  { id: 'empresa', numeric: true, disablePadding: false, label: 'Empresa' },
  { id: 'id', numeric: true, disablePadding: false, label: 'ID' },
  { id: 'name', numeric: false, disablePadding: false, label: 'Nombre' },
  { id: 'surname', numeric: true, disablePadding: false, label: 'Apellido' },
];

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof IPersonal) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property: keyof IPersonal) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) => (
          <TableCell
            key={headCell.id}            
            align={'center'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={/*orderBy === headCell.id*/true}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              IconComponent={ArrowDropDownIcon}
            >
              <strong>{headCell.label}</strong>
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight:
      theme.palette.type === 'light'
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85),
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
          },
    title: {
      flex: '1 1 100%',
    },
  }),
);

interface EnhancedTableToolbarProps {
  numSelected: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      // marginTop:20,
      '& thead.MuiTableHead-root':{
        borderBottom: '2px solid #00D3FF'
      },
      '& thead .MuiSvgIcon-root':{
        color:'#00D3FF !important',
        fontSize:'30px'
      },
      '& tbody> .MuiTableRow-root': {
        '&:nth-child(odd)': {
          backgroundColor: '#EEEEEE',
        },
        '&:nth-child(even)': {
          backgroundColor: 'white',
        },
        '&:hover': {
          backgroundColor: '#ddd',
        }
      },
      '& tbody .MuiTableCell-body': {
        maxWidth: '190px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        width: 'fit-content',
        whiteSpace: 'nowrap',
        "&:first-child": {
          position: 'sticky',
          left: 0,
          zIndex: 1,
          backgroundColor:'inherit'
        },
      },
      '& thead .MuiTableCell-head': {
        "&:first-child": {
          backgroundColor: 'white',
          position: 'sticky',
          left: 0,
          zIndex: 1,
        },
      }
    },
    paper: {
      width: 'auto',
      marginBottom: theme.spacing(2),
      border:'3px solid rgb(0, 43, 97, 0.60)',
      borderRadius:'5px'
    },
    table: {
      minWidth: 750,
      '& .MuiTableCell-head':{ lineHeight: 1.25 } 
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
  }),
);

interface IPaginatedProps {
  data:Array<IPersonal>;
  onBusinessClick: Function;
}

export default function EnhancedTable(props: IPaginatedProps) {
  const classes = useStyles();
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof IPersonal>('id');
  const [selected, setSelected] = React.useState<string[]>([]);
  const [page, setPage] = React.useState(0);
  const [dialog, setDialog] = React.useState(false);  
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rowOptionSelected, setRowOptionSelected] = React.useState("-1");
  const [rowOptionSelectedAnchor, setRowOptionSelectedAnchor] = React.useState(null);

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof IPersonal) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = props.data.map((n:IPersonal) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };


  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, props.data.length - page * rowsPerPage);
  
  const OptionisOpened=(id:string)=>{
    if(rowOptionSelected == id)
        return true;

    return false;
  }

  const handleClickCloseRowOptions=()=>{
    setRowOptionSelected("-1");
    setRowOptionSelectedAnchor(null);
  }

  const handleClickOpenRowOptions=(evt:any, id:string)=>{
    setRowOptionSelectedAnchor(evt.currentTarget);
    setRowOptionSelected(id);
  }

  const onCloseCreationModal = () => {    
    document.getElementById("attachFileLine")?.click();
    setDialog(false);
  }
  
  const handleCreateModal = () => {    
    onCloseCreationModal();
  }  

  return (
    <div className={classes.root}>
      <Paper square elevation={0}>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={'medium'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={props.data.length}
            />
            <TableBody>
              {stableSort(props.data, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row:IPersonal, index:number) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      //onClick={(event) => handleClick(event, row.name)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      <TableCell style={{cursor:'pointer'}} onClick={()=>props.onBusinessClick(row)} align="center">
                        <OverflowCell text={row.empresa}/>
                      </TableCell>
                      <TableCell align="center">{row.id}</TableCell>
                      <TableCell align="center">
                        <OverflowCell text={row.name}/>
                      </TableCell>
                      <TableCell align="center">
                        <OverflowCell text={row.surname}/>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          component="div"
          count={props.data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage='Filas por página:'
          labelDisplayedRows={({ from, to, count })=>{            
            return `${from} - ${to} de ${count}`;
          }}
        />
        <CreateModal
          onCloseCreationModal={onCloseCreationModal}
          isOpen={dialog}
          inputText={"Por favor, adjuntar el modelo del Grupo (disponible en el Share Point) de apto con limitaciones firmado por todas las partes implicadas."}
          typeModal={1}
          action={handleCreateModal}
          typeAction={1}
          loadingModal={false}
          titleModal={"Apto con limitaciones"}
        />
      </Paper>
    </div>
  );
}
