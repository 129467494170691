import React, { Component } from 'react';
import { createStyles, withStyles, Theme } from '@material-ui/core/styles';
import { Text, View, StyleSheet, Image, PDFViewer, PDFDownloadLink, pdf } from '@react-pdf/renderer';
import { Document, Page } from '../Material/pdfRenderComponents';
import Button from '@material-ui/core/Button';
import PersonAddIcon from '@material-ui/icons/PersonAddOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import DatePicker from '../DatePicker';
import { IMe, IIIRegister, IVRegister, IIForm, IQuestion, ICentroTrabajo, IIQuestion, IIIQuestion, IPersonal } from '../../types';
import { SelectorField, CheckBoxField, TextFieldInput } from '../FormsWidgets';
import {GetRole, GetEmpresa} from '../../helpers';
import TakeIcon from '@material-ui/icons/CheckCircleOutline';
import {cloneDeep, filter, find, findIndex} from 'lodash';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddActionIcon from '@material-ui/icons/AddBox';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import PageViewIcon from '@material-ui/icons/Pageview';
import { GetConcatedDate, GetDatetimeFormat } from '../../vc_general';
import OrderService from '../../ApiClients/OrderService';
import LoaderBeauty from '../Material/activityProgress';
import CreateModal from '../CreateDialog';
import List from '../Material/MultiSelect';
import Loader from '../Loader';
import { ListChildComponentProps } from 'react-window';
import {epis} from '../../ApiClients/DummyData';

const styles = (theme: Theme) => createStyles({
    container: {
        padding: '10px',
        textAlign: 'center',
        overflowY:'auto'
    },
    image: {
        width: '100%',
        height: 'auto'
    },
    promotion: {
        //backgroundColor: '#f00',
        padding: '3px 15px',
        //color: '#fff',
        verticalAlign: 'middle',
    },
    root: {
    width: '100%',
    },
    paper: {
        width: '100%',
        marginTop: '5px',
        //overflowX: 'auto',
        borderWidth: 2, 
        borderColor: '#00809b',
        borderStyle: 'solid',
    },
    table:{
        //tableLayout:'fixed'
        width: '100%',
    },
    row:{
        //wordWrap:'break-word'
        width:'100%',
        //backgroundColor:'#ddd'
    },
    heading: {
    fontSize: theme.typography.pxToRem(15),
    //fontWeight: theme.typography.fontWeightRegular,
    },
    item: {
        maxWidth:'200px',
        border: '2px solid #ccc',
        borderRadius: '15px',
        margin: '5px 10px',
        width: 'calc(100% - 20px)!important',
        boxShadow: '5px 5px 5px 0px rgba(0,0,0,0.50)',
        cursor: 'pointer',
        paddingTop: '10px',
        paddingBottom: '10px',
        position: 'relative',
    },
});

const useStyles = ({
    page: {
        //display: 'row',
        //backgroundColor: '#E4E4E4',
        margin: 10,
        padding: 10,
        flexGrow: 1,
    },
    body: {
        paddingTop: 15,
        paddingBottom: 40,
        paddingLeft: 15,
    },
    logo: {
        width: 60,
        height: 60,
    },
    logoFito: {
        width: 'auto',
        height: 'auto',
    },
    bussinessData: {
        width: 'auto',
        height: 'auto',
    },
    header:{
        marginTop:7,
        fontSize:10
    },
    secondHeader:{
        marginTop:7,
        fontSize:11,
        marginBottom:10
    },
    headerTable:{
        fontSize:10,
        marginVertical:'auto',
        padding:3,
        borderTop:'1pt solid rgba(0,0,0,0.31)',
        borderBottom:'1pt solid rgba(0,0,0,0.31)',
        borderLeft:'1pt solid rgba(0,0,0,0.31)',
    },
    lineTable:{
        fontSize:9,
        marginVertical:'auto',
        padding:3,
    },
    content: {
        marginRight:15,
        '@media min-width: 400': {
            flexDirection: 'row',
        },
    },
    content02: {
        marginRight:0,
        '@media min-width: 400': {
            flexDirection: 'row',
        },
    }
});
/*
const secciones_inspected = (list:Array<any>)=>{

    let secciones = list.map((seccion:any, index:number)=>{

        return (
            <View key={index} style={[useStyles.content, {paddingBottom:5}]}>
                <View style={{width:'13%'}}>
                    <Text style={[useStyles.lineTable, {textAlign:'center', justifyContent:'center', borderRight:'1pt solid rgba(0,0,0,0.31)'}]}>
                        {index + 1}
                    </Text>
                </View>
                <View style={{width:'87%'}}>
                    <Text style={[useStyles.lineTable, {textAlign:'center', justifyContent:'center', borderRight:'1pt solid rgba(0,0,0,0.31)'}]}>
                        {seccion.name}
                    </Text>
                </View>
            </View>
        );
    })
    return secciones;
}

const questions = (list:Array<IQuestion>)=>{

    let questions = list.map((question:IQuestion, index:number)=>{

        return (
            <View key={index} style={[useStyles.content, {paddingBottom:5}]}>
                <View style={{width:'55%'}}>
                    <Text style={[useStyles.lineTable, {textAlign:'left', justifyContent:'center', borderRight:'1pt solid rgba(0,0,0,0.31)'}]}>
                        {question.question}
                    </Text>
                </View>
                <View style={{width:'10%'}}>
                    <Text style={[useStyles.lineTable, {textAlign:'center', justifyContent:'center', borderRight:'1pt solid rgba(0,0,0,0.31)'}]}>
                        {question.answer}
                    </Text>
                </View>
                <View style={{width:'35%'}}>
                    <Text style={[useStyles.lineTable, {textAlign:'center', justifyContent:'center', borderRight:'1pt solid rgba(0,0,0,0.31)'}]}>
                        {question.comment}
                    </Text>
                </View>
            </View>
        );
    })
    return questions;
}
*/

const pdf_questions = (list:Array<IQuestion>)=>{

    let questions = list.map((question:IQuestion, index:number)=>{

        return (
            <View key={index} style={[useStyles.content02, {paddingTop:10, marginLeft:'auto', paddingRight:'5%'}]}>
                <View style={{width:'22%'}}>
                    <Text style={[useStyles.lineTable, {textAlign:'center', justifyContent:'center', borderRight:'1pt solid rgba(0,0,0,0.31)'}]}>
                        {question.question}
                    </Text>
                </View>
                <View style={{width:'15%'}}>
                    <Text style={[useStyles.lineTable, {textAlign:'center', justifyContent:'center', borderRight:'1pt solid rgba(0,0,0,0.31)'}]}>
                        {question.answer}
                    </Text>
                </View>
                <View style={{width:'45%'}}>
                    <Text style={[useStyles.lineTable, {textAlign:'center', justifyContent:'center', borderRight:'1pt solid rgba(0,0,0,0.31)'}]}>
                        {question.comment}
                    </Text>
                </View>
            </View>
        );
    })
    return questions;
}

const pdf_users = (inspections:Array<IIQuestion>) => {
    const data = inspections.map((inspection:IIQuestion, i01:number)=>{
        const d_user = inspection.usuario.map((user:any, id03:number)=>{
            const n_epis = inspection.epis.map((epi:IIIQuestion, i02:number)=>{

                return(
                    <View style={{paddingBottom:10}}>
    
                        <View style={{width:'85%', marginTop:10, marginLeft:'auto', paddingRight:'5%'}}>
                            <Text style={[useStyles.headerTable, {textAlign:'left', justifyContent:'center', backgroundColor:'#ddd', borderRight:'1pt solid rgba(0,0,0,0.31)'}]}>{`${epi.epi}`}</Text>
                        </View>
    
                        <View style={[useStyles.content02, {paddingBottom:20, paddingTop:10, marginLeft:'auto', paddingRight:'5%'}]}>
                            <View style={{width:'22%'}}>
                                <Text style={[useStyles.headerTable, {textAlign:'center', justifyContent:'center'}]}>{`Punto inspección`}</Text>
                            </View>
                            <View style={{width:'15%'}}>
                                <Text style={[useStyles.headerTable, {textAlign:'center', justifyContent:'center', backgroundColor:'#ddd'}]}>{`Respuesta`}</Text>
                            </View>
                            <View style={{width:'45%'}}>
                                <Text style={[useStyles.headerTable, {textAlign:'center', justifyContent:'center', borderRight:'1pt solid rgba(0,0,0,0.31)'}]}>{`Comentario`}</Text>
                            </View>
                        </View>
    
                        {pdf_questions(epi.questions)}
                    </View>
                );
            });
    
            return(
                <View key={i01} style={{paddingBottom:5}}>
                    <View style={{width:'95%'}}>
                        <Text style={[useStyles.headerTable, {textAlign:'left', justifyContent:'center', backgroundColor:'#ddd', borderRight:'1pt solid rgba(0,0,0,0.31)'}]}>{`${user.name}`}</Text>
                    </View>
    
                    <View style={{width:'90%', marginTop:10, marginLeft:'auto', paddingRight:'5%'}}>
                        <Text style={[useStyles.headerTable, {textAlign:'left', justifyContent:'center', backgroundColor:'#ddd', borderRight:'1pt solid rgba(0,0,0,0.31)'}]}>{`Puesto trabajo`}</Text>
                    </View>
                    <View style={{width:'85%', marginTop:10, marginLeft:'auto', paddingRight:'5%'}}>
                        <Text style={[useStyles.headerTable, {textAlign:'left', justifyContent:'center', borderRight:'1pt solid rgba(0,0,0,0.31)'}]}>{`${inspection.puestoTrabajo.name}`}</Text>
                    </View>
    
                    <View style={{width:'90%', marginTop:10, marginLeft:'auto', paddingRight:'5%'}}>
                        <Text style={[useStyles.headerTable, {textAlign:'left', justifyContent:'center', backgroundColor:'#ddd', borderRight:'1pt solid rgba(0,0,0,0.31)'}]}>{`Secciones`}</Text>
                    </View>
                    <View style={{width:'85%', marginTop:10, marginLeft:'auto', paddingRight:'5%'}}>
                        <Text style={[useStyles.headerTable, {textAlign:'left', justifyContent:'center', borderRight:'1pt solid rgba(0,0,0,0.31)'}]}>{`${inspection.secciones.map(function(o){return o.name}).join("\r\n")}`}</Text>
                    </View>
    
                    <View style={{width:'90%', marginTop:10, marginLeft:'auto', paddingRight:'5%'}}>
                        <Text style={[useStyles.headerTable, {textAlign:'left', justifyContent:'center', backgroundColor:'#ddd', borderRight:'1pt solid rgba(0,0,0,0.31)'}]}>{`EPIs`}</Text>
                    </View>
                        
                    {n_epis}
                </View>
            );
        })

        return d_user;
        
    });

    
    /*return(
        <View style={[useStyles.content, {paddingBottom:10}]}>
        <View style={{width:'55%'}}>
            <Text style={[useStyles.headerTable, {textAlign:'center', justifyContent:'center'}]}>{`Punto inspección`}</Text>
        </View>
        <View style={{width:'10%'}}>
            <Text style={[useStyles.headerTable, {textAlign:'center', justifyContent:'center', backgroundColor:'#ddd'}]}>{`Respuesta`}</Text>
        </View>
        <View style={{width:'35%'}}>
            <Text style={[useStyles.headerTable, {textAlign:'center', justifyContent:'center', borderRight:'1pt solid rgba(0,0,0,0.31)'}]}>{`Comentario`}</Text>
        </View>
    </View>

    <View>
        {questions(register.questions)}
    </View>
    )*/

    return data;

}

const PdfForm  = (props: ListChildComponentProps) =>{
    const register:IIForm = props.data[0];
    const me:IMe = props.data[1];
    const item:IVRegister = props.data[2];

    const empresa:ICentroTrabajo|undefined = GetEmpresa(item.centrotrabajoId, me);

    return(
        <Document>
            <Page size="A4" style={useStyles.body} >
                <View fixed>
                    <View style={[useStyles.content, {paddingBottom:20, justifyContent:'space-between'}]}>
                        <Text style={{fontSize:8, color:'#8c8c8c', fontWeight:'bold'}} fixed>
                            {`Fecha revisión: ${register.dt_reviewed}`}
                        </Text>
                        <Text style={{fontSize:8}} render={({ pageNumber, totalPages }) => (
                            `Pág. ${pageNumber} / ${totalPages}`
                        )} fixed />
                    </View>
                    <View style={[useStyles.content, {paddingBottom:20}]}>
                        <View style={{width:'25%', marginRight:30, paddingRight:15, borderRight:'1pt solid rgba(0,0,0,0.31)'}}>
                            {empresa?
                                <Image src={empresa.logo} style={useStyles.bussinessData}/>
                            :
                                null
                            }
                            <Text style={{fontWeight:25, fontSize:10, textAlign:'center', marginTop:10}}>{`${empresa?.description}`}</Text>
                        </View>
                        <View style={{width:'70%', height:120}}>
                            <Text style={{fontWeight:50, fontSize:20}}>{`${register.title}`}</Text>
                            <Text style={useStyles.header}>{`Fecha realización: ${GetDatetimeFormat(register.dt_created)}`}</Text>
                            <Text style={useStyles.header}>{`Realizado por: ${register.usuario}`}</Text>
                        </View>
                    </View>
                </View>

                <View>
                    {pdf_users(register.inspections)}
                </View>
            </Page>    
        </Document>
    ); 
}


const questions = [
    {id:"q_1", question: "Estado", answer: "", comment: "", a_template: [{id:"ADECUADO",name:"ADECUADO"},{id:"NO ADECUADO",name:"NO ADECUADO"},{id:"NO DISPONIBLE",name:"NO DISPONIBLE"}]},
    {id:"q_2", question: "Utilización", answer: "", comment: "", a_template: [{id:"CORRECTA",name:"CORRECTA"},{id:"INCORRECTA",name:"INCORRECTA"},{id:"NO UTILIZADO",name:"NO UTILIZADO"}]},
];

const i_newRegister:IIQuestion = {usuario: [], secciones: [], epis: [], puestoTrabajo: null};

interface IFormProps {
    me:IMe;
    classes: any;
    secciones:any;
    puestosTrabajo: any;
    handleCloseItemForm: Function;
    item:IVRegister;
}

interface IFormState {
    creationModalOpen:boolean;
    modalAction:number;
    modalText:any;
    modalType:number;
    titleModal: string;
    htmlBody:boolean;
    loading:boolean;
    loadingModal:boolean;
    register:IIForm;
    personal:Array<IPersonal>;
    loadingPersonal: boolean;
}

class Form extends Component<IFormProps, IFormState> {
    _going2upload:any = {attachments:[]};
    constructor(props: IFormProps) {
        super(props);
        this.state = {
            creationModalOpen:false,
            modalType: -1,
            modalAction: -1,
            modalText:'',
            htmlBody:false,
            loading: false,
            loadingModal: false,
            titleModal: `MENSAJE`,
            register: {
                filename: `Informe_EPIs_01_personal_${GetConcatedDate(new Date())}.pdf`,
                dt_reviewed: `01/01/21`,
                dt_created: new Date(),
                dt_edited: new Date(),
                title: `Revisión EPIs - Personal`,
                usuario: props.me.userIdName,
                inspections: [i_newRegister],
                secciones: []
            },
            personal: [],
            loadingPersonal: true
        };
    }

    componentDidMount(){
        this.loadPersonal();
    }

    loadPersonal = async () => {
        let response = await OrderService.LoadPanelPersonal(this.props.item.centrotrabajoId);
        let personal:Array<IPersonal> = response
        if(response){
            response = personal.map((person:IPersonal, idx:number)=>{
                return({
                    id: `${person.id}`,
                    name: `${person.name} ${person.surname}`
                })
            })
        }
        else
            response = [];

        this.setState({loadingPersonal: false, personal: response}); // Controlar el error en un futuro
    }
    
    onCloseCreationModal = () => {
        this.setState({ creationModalOpen: false, modalText: undefined, htmlBody:false });
    }

    /* CALL BACKEND */

    sendAttachFiles = async () => {
        let response = await OrderService.createImgBlob(this._going2upload, 6);
        this._going2upload = {attachments:[]};
        let p = `Fallo en el proceso, vuelva a intentarlo.`;
        let modaltype = 1;
        let modalaction = -1;

        if(response == 1) {
            p = `Formulario adjuntado correctamente`;
            modaltype = 4;
            modalaction= 1;
        }
        
        this.setState({
            creationModalOpen:true,
            modalText:p,
            modalType: modaltype,
            modalAction: modalaction,
            loadingModal: false
        });
    }

    /* */

    handleCreateModal = (typeAction: number) => {
        switch(typeAction){
            case 1: // Implica que se ha adjuntado corractamente el formulario
                this.props.handleCloseItemForm(true);
                this.onCloseCreationModal();
                break;
            case 2: // Implica iniciar el proceso de adjuntar el formulario
                this.setState({loadingModal: true},()=>{
                    this.handleCreateForm();
                    //this.onCloseCreationModal();
                });
                break;
            default:
                this.onCloseCreationModal();
        }
    }

    handleChangeDate=(id:any, event:any)=>{
        let nRegister = Object.assign({}, this.state.register);
        nRegister[id] = event;
        this.setState({ register: nRegister });
    }

    handleCloseForm=(item:IIIRegister, lines:Array<IVRegister>)=>{
        this.setState({
            modalAction: 2,
            modalText: `¡ATENCIÓN! ¿Quiere finalizar el formulario y adjuntarlo a la acción ${item.id} de la orden de trabajo ${item.code}?`,
            modalType: 2,
            creationModalOpen: true
        });
    }

    handleChangeUser=(e:any, list:Array<string>, i01:number)=>{
        let nRegister = cloneDeep(this.state.register);
        //nRegister.inspections[i01][id] = String(event.target.value).toUpperCase();
        nRegister.inspections[i01].usuario = list
        this.setState({ register: nRegister });
    }

    handleChangeUserQuestions=(id:any, event:any, i01:number, i02:number, i03:number, type?:string)=>{
        let nRegister = cloneDeep(this.state.register);
        nRegister.inspections[i01].epis[i02].questions[i03][id] = event.target.value;
        this.setState({ register: nRegister });
    }

    handleChangeSelectSecciones=(e:any, list:Array<string>, i01:number)=>{
        let nRegister = cloneDeep(this.state.register);
        nRegister.inspections[i01].secciones = list;
        this.setState({ register: nRegister });
    }
    
    handleChangeSelectPuestoTrabajo=(e:any, list:Array<string>, i01:number)=>{
        let nRegister = cloneDeep(this.state.register);
        nRegister.inspections[i01].puestoTrabajo = list;
        this.setState({ register: nRegister });
    }

    handleChangeSelectEPIs=(e:any, list:Array<string>, i01:number)=>{
        let nRegister = cloneDeep(this.state.register);
        let nepis:Array<IIIQuestion> = [];

        list.map((item:any)=>{
            const index = findIndex(nRegister.inspections[i01].epis, function(o){return o.id == item.id});

            if(index > -1)
                nepis.push(nRegister.inspections[i01].epis[index]);
            else
                nepis.push({id:item.id, epi:item.name, questions: questions})
        })

        nRegister.inspections[i01].epis = nepis;

        this.setState({ register: nRegister });
    }

    handleCreateForm=()=>{

        const {item, me} = this.props;
        const {register} = this.state;

        let dale = 
                <PdfForm key={1} index={1} data={[register, me, item]} style={{}} />

        /*this.setState({
            modalAction: -1,
            modalText: <PDFViewer style={{width:'40vw', height:'90vh'}}>{dale}</PDFViewer>,
            modalType: 1,
            creationModalOpen: true,
        });*/

        const blob = pdf(dale).toBlob();

        blob.then(ret=>{

            let reader = new FileReader();

            reader.onloadend = async ()=>{
                this._going2upload.attachments.push({name: register.filename, stream: reader.result, centrotrabajoId: item.centrotrabajoId, tipo: item.tipo, listadoId: item.listadoTableId, id: item.id});
                //this.setState({loading:true }, ()=>this.sendAttachFiles());
                this.sendAttachFiles();
            }
            reader.readAsDataURL(ret);

        }).catch(err=>{
            console.log(err)
        })
    }

    handleAskCreateForm=()=>{
        const {item} = this.props;
        this.setState({
            modalAction: 2,
            modalText: `¡ATENCIÓN! ¿Quiere finalizar el formulario y adjuntarlo a la acción ${item.id} de la orden de trabajo ${item.listadoTableId}?`,
            modalType: 2,
            creationModalOpen: true,
        });
    }

    verifyForm=(register:IIForm)=>{

        // Verifica que almenos tiene algún usuario
        if(register.inspections.length == 0)
            return false;

        // Verifica que está el nombre de todos los usuarios
        let u_c = true;
        register.inspections.map(i=>{
            if(i.usuario == null)
            u_c = false
        })

        if(!u_c)
            return false;

        // Verifica todos los usuarios tienen un puesto de trabajo
        let p_c = true;
        register.inspections.map(i=>{
            if(i.puestoTrabajo == null)
            p_c = false
        })

        if(!p_c)
            return false;

        // Verifica todos los usuarios tienen almenos una sección
        let s_c = true;
        register.inspections.map(i=>{
            if(i.secciones.length == 0)
            s_c = false
        })

        if(!s_c)
            return false;

        // Verifica todos los usuarios tienen almenos un EPI
        let e_c = true;
        register.inspections.map(i=>{
            if(i.epis.length == 0)
            e_c = false
        })

        if(!e_c)
            return false;

        // Verifica que todas las preguntas de los EPIs menos los comentarios han sido contestadas
        let c = true;
        register.inspections.map(i=>{
            i.epis.map(d=>{
                d.questions.map(q=>{
                    if(q.answer.length == 0)
                        c = false;
                })
            })
        })

        if(!c)
            return false;

        return true;
    }

    handleDeleteUser = (i01:number) => {
        let nregister = cloneDeep(this.state.register);
        nregister.inspections.splice(i01, 1);
        this.setState({register: nregister});
    }

    handleAddUser = () => {
        let nregister = cloneDeep(this.state.register);
        nregister.inspections.push(i_newRegister);
        this.setState({register: nregister});
    }

    renderUser = (user:IIQuestion, i01:number, secciones:any, puestosTrabajo:any) => {

        let epis_tables = user.epis.map((epi:IIIQuestion, i02: number)=>{
            return(
                <div>
                    <Paper className={this.props.classes.paper}>
                        <Table className={this.props.classes.table} aria-label="simple table">
                            <TableBody key={i02} style={{width:'100%',backgroundColor:'#00809b'}}>
                                <TableRow>
                                    <TableCell style={{ color:'#fff' }}>
                                        <strong>{`${epi.epi}`}</strong>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                            {epi.questions.map((item:IQuestion, i03:number)=>{
                                return(
                                    <TableBody key={i03} style={{width:'100%'}}>
                                        {/*<TableRow className={this.props.classes.row} style={{backgroundColor:'#00809b'}} key={i}>
                                            <TableCell style={{width:'100%'}} align="center"><strong>{i03 + 1}</strong></TableCell>
                                </TableRow> */}
                                        <TableRow className={this.props.classes.row}> 
                                            <TableCell  align="left">{`${item.question}`}</TableCell>
                                        </TableRow> 
                                        <TableRow className={this.props.classes.row}> 
                                            <TableCell align="center">
                                                <div style={{maxWidth:150}}>
                                                    <SelectorField
                                                        name={`Q_${i03 + 1}`}
                                                        fieldsData={item.a_template}
                                                        id={'answer'}
                                                        deviceData={item}
                                                        handleFieldChange={(id:string, evt:any)=>this.handleChangeUserQuestions(id,evt,i01,i02,i03)}
                                                        required={true}
                                                        style={3}
                                                    />
                                                </div>

                                            </TableCell>
                                        </TableRow> 
                                        <TableRow className={this.props.classes.row}> 
                                            <TableCell align="center">
                                                <TextFieldInput
                                                    name={`C_${i03 + 1}`}
                                                    id={'comment'}
                                                    disabled={false}
                                                    type={"text"}
                                                    required={false}
                                                    handleFieldChange={(id:string, evt:any, type:string)=>this.handleChangeUserQuestions(id,evt,i01,i02,i03,type)}
                                                    style={3}
                                                    helper={""}
                                                    fieldsData={item}
                                                    maxText={2000}
                                                    noError={true}
                                                    size={"medium"}
                                                    shrink={true}
                                                />
                                            </TableCell>
                                        </TableRow> 
                                    </TableBody>
                                );
                            })}
                        </Table>
                    </Paper>
                </div>
            );
        })

        return(
            <Accordion defaultExpanded={false} style={{border:`2px solid rgb(0, 43, 97, 0.5)`, marginBottom:20, borderRadius:'10px'}}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-content"
                >
                {/*<Typography style={{fontWeight:'bold'}}>
                    <input placeholder='Nombre trabajador/a...' style={{fontSize:'15px', fontWeight:'bold'}} width={"300px"} type={"text"} onChange={(e:any)=>this.handleChangeUser('usuario', e, i01)} onClick={(e:any)=>{e.stopPropagation();}} value={`${user.usuario}`}/>
        </Typography>*/}

                <div style={{width:'100%', maxWidth:500}} onClick={(e:any)=>{e.stopPropagation();}}>
                    <List id={"usuario"} data={user.usuario} multiple={true} name={'Trabajador/a'} placeholder={'Trabajador/a'} options={this.state.personal} handleChangeSelect={(e:any, list:any)=>this.handleChangeUser(e,list, i01)}/>
                </div>
                </AccordionSummary>
                <AccordionDetails>
                    <div style={{display:'inline', width:'100%'}}>
                        <div style={{display:'flex', marginLeft:'auto', width:'100%'}}>
                            <IconButton onClick={()=>this.handleDeleteUser(i01)} aria-label="delete user">
                                <DeleteIcon />
                            </IconButton>
                        </div>
                        <div  style={{width:'100%', marginBottom:20}}>
                            <List id={"puestoTrabajo"} data={user.puestoTrabajo} multiple={false} name={'Puesto trabajo'} placeholder={'Puesto trabajo'} options={puestosTrabajo} handleChangeSelect={(e:any, list:any)=>this.handleChangeSelectPuestoTrabajo(e,list, i01)}/>
                        </div>
                        <div style={{width:'100%', marginBottom:20}}>
                            <List id={"secciones"} data={user.secciones} multiple={true} name={'Secciones'} placeholder={'Sección'} options={secciones} handleChangeSelect={(e:any, list:any)=>this.handleChangeSelectSecciones(e,list, i01)}/>
                        </div>
                        <div style={{width:'100%', marginBottom:20}}>
                            <List id={"epis"} data={user.epis.map(function(o){return {id:o.id, name:o.epi}})} multiple={true} name={'EPIs'} placeholder={'EPI'} options={epis} handleChangeSelect={(e:any, list:any)=>this.handleChangeSelectEPIs(e,list, i01)}/>
                        </div>
                        {epis_tables}   
                    </div>
                </AccordionDetails>
            </Accordion>
        );
    }

    render() {
        const {register, loading} = this.state;
        const {me, secciones, puestosTrabajo} = this.props;

        let usersinspected = register.inspections.map((inspection:IIQuestion, index:number)=>{
            return(this.renderUser(inspection, index, secciones, puestosTrabajo));
        });

        return (
            <Dialog style={{padding:'25px'}} fullScreen={false} open={true} aria-labelledby="form-dialog-title" className='dialog-data form-dialog-data'>
                <DialogTitle id="form-dialog-title">
                    <div className="App-header">
                        {`${register.title}`}
                    </div>
                </DialogTitle>
                <DialogContent>
                    <div>
                        <IconButton onClick={()=>this.handleAddUser()} aria-label="delete user">
                            <PersonAddIcon />
                        </IconButton>
                    </div>
                    {usersinspected}
                    <div>
                        {this.state.creationModalOpen?
                            <CreateModal
                                onCloseCreationModal={this.onCloseCreationModal}
                                isOpen={this.state.creationModalOpen}
                                inputText={this.state.modalText}
                                typeModal={this.state.modalType}
                                action={this.handleCreateModal}
                                typeAction={this.state.modalAction}
                                loadingModal={this.state.loadingModal}
                                titleModal={this.state.titleModal}
                            />
                        :
                            null
                        }
                    </div>
                </DialogContent>
                <DialogActions style={{ borderTop: '#ff972b solid 3px'}}>
                    {!loading?
                        <div style={{marginRight:'auto', display:'flex', marginLeft: 'auto', width: '90%', justifyContent:'space-between'}}>
                            <Button onClick={()=>this.props.handleCloseItemForm(false)} className="cancel-dialog">
                                {'Cancelar'}
                            </Button>
                            <Button disabled={!this.verifyForm(register)} onClick={()=>this.handleAskCreateForm()} className={`confirm-dialog ${!this.verifyForm(register)?`confirm-dialog-disable`:``}`}>
                                {`Generar informe`}
                            </Button>
                        </div>
                    :
                        <Loader loading={true}/>
                    }
                </DialogActions>
            </Dialog>
        );
    }
}

export default withStyles(styles)(Form);