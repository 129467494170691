import React, { Component, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from "@fullcalendar/interaction"
import esLocale from '@fullcalendar/core/locales/es';
import { IMe } from '../types';
import Loader from './Loader';
import Layout from './Layout';
import { GetRole, GetEmpresa } from '../helpers';
import OrderService from '../ApiClients/OrderService';
import CreateModal from './CreateDialog';

import './Calendar.css';

import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import DoneIcon from '@material-ui/icons/Done';
import SyncIcon from '@material-ui/icons/Sync';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import DoNotDisturbIcon from '@material-ui/icons/Block';
import { id } from 'date-fns/locale';

interface ICalendarProps {
    history: any;
    match: any;
    me?: IMe;
    version: string;
}

interface ICalendarState {
    loading: boolean;
    showEventDetail: boolean;
    eventDetail:any;
    events: any[];
    correctivos: any[];
    preventivos: any[];
    filterButtonState: number;
    range: { startDate: Date, endDate: Date };
}

class Calendar extends Component<ICalendarProps, ICalendarState> {    
    constructor(props: ICalendarProps) {
        super(props);
        this.state = {
            loading: true,
            showEventDetail: false,
            eventDetail: null,
            events: [],
            correctivos: [],
            preventivos: [],
            filterButtonState: 0,
            range: { startDate: new Date(), endDate: new Date() }
        };
    }

    componentDidMount() {
        this.data();
    }

    handleEventClick = (arg: any) => {
        let content = <div>
                        <b>Código: </b>{arg.event.title}<br/>
                        <b>Fecha: </b>{arg.event.start.toLocaleDateString()}<br/>
                        <b>Tipo: </b>{arg.event.extendedProps.eventType === 1 ? 'Correctivo' : 'Preventivo'}<br/>
                        <b>Prioridad: </b><span className='calendarItemPriority' style={{ backgroundColor: arg.event.extendedProps.priorityColor }}></span>{arg.event.extendedProps.priority}<br/>
                        <b>Estado: </b>
                        <span style={{ color:arg.event.extendedProps.stateColor, marginRight:10 }}>{
                            (() => {
                                switch (arg.event.extendedProps.state) {
                                    case 50:
                                        return <DoneIcon />
                                    case 20:
                                        return <SyncIcon />
                                    case 10:
                                        return <PriorityHighIcon />
                                    case 97:
                                        return <EventBusyIcon />
                                    case 98:
                                        return <DoNotDisturbIcon />
                                    default:
                                        return null
                                }
                            })()
                        }</span>
                        <span>{arg.event.extendedProps.stateDsc}</span>
                    </div>;
                
        this.setState({ showEventDetail: true, eventDetail:content });
    }
    hadleCloseEventDetailModal = () => {
        this.setState({ showEventDetail: false, eventDetail:null });
    }
    data = async () => {
        let correctivos = await OrderService.LoadListado(this.props.match.params.centrotrabajoId, 1);
        let preventivos = await OrderService.LoadListado(this.props.match.params.centrotrabajoId, 2);
        correctivos = correctivos.filter((item: any) => item.estadoDsc === 'PENDIENTE' && item.fechaPrevistaEnd !== null);
        preventivos = preventivos.filter((item: any) => item.estadoDsc === 'PENDIENTE' && item.fechaPrevistaEnd !== null);
        let events = correctivos.map((item: any) => {
            return {
                id: item.id,
                title: item.code,
                date: item.fechaPrevistaEnd,
                allDay: true,
                extendedProps: {
                    eventType: 1,
                    priorityColor: item.prioridadColor,
                    priority: item.prioridadDsc,
                    stateDsc: item.estadoDsc,
                    stateColor: item.estadoColor,
                    state: item.estado,
                },
                classNames: 'correctivos',
            }
        });
        events.push(...preventivos.map((item: any) => {
            return {
                id: item.id,
                title: item.code,
                date: item.fechaPrevistaEnd,
                allDay: true,
                extendedProps: {
                    eventType: 2,
                    priorityColor: item.prioridadColor,
                    priority: item.prioridadDsc,
                    stateDsc: item.estadoDsc,
                    stateColor: item.estadoColor,
                    state: item.estado,
                },
                classNames: 'preventivos',
            }

        }));

        this.setState({
            events: [...events], correctivos:correctivos, preventivos:preventivos, loading: false
        });
    }

    renderEventContent(eventInfo: any) {
        return (
            <>
                <b>{eventInfo.event.title}&nbsp;&nbsp;-</b><span><span className='calendarItemPriority' style={{ backgroundColor: eventInfo.event.extendedProps.priorityColor }}></span>{eventInfo.event.extendedProps.priority}</span>
                <br />
                <div className='calendarItemState'>
                <span style={{ color:eventInfo.event.extendedProps.stateColor, marginRight:10 }}>{
                    (() => {
                        switch (eventInfo.event.extendedProps.state) {
                            case 50:
                                return <DoneIcon />
                            case 20:
                                return <SyncIcon />
                            case 10:
                                return <PriorityHighIcon />
                            case 97:
                                return <EventBusyIcon />
                            case 98:
                                return <DoNotDisturbIcon />
                            default:
                                return null
                        }
                    })()
                }</span>
                <span>{eventInfo.event.extendedProps.stateDsc}</span>
                </div>
            </>
        )
    }

    renderCalendar() {
        // this.data();
        return (
            <FullCalendar
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                headerToolbar={{
                    left: 'prev,next today',
                    center: 'title',
                    right: 'dayGridMonth,timeGridWeek'
                }}
                
                initialView="dayGridMonth"
                selectable={true}
                selectMirror={true}
                dayMaxEvents={true}                
                eventClick={this.handleEventClick}
                eventContent={this.renderEventContent}
                events={this.state.events}
                locale={esLocale}
                height='calc(100dvh - 250px)'
                contentHeight='calc(100dvh - 270px)'
            />
        );
    }

    render() {
        const { me } = this.props;
        return (
            <div className='App-basic'>
                <Layout
                    me={this.props.me}
                    history={this.props.history}
                    currentPage={0}
                    allowDrawer={false}
                    match={this.props.match}
                    version={this.props.version}
                    showEmpresa
                    showLateralMenu
                    loading={this.state.loading}
                />
                <div className='App-body-webBar'>
                    <div className='App-header'>
                        <div className="App-title-webPage">Agenda</div>
                    </div>
                    {me ?
                        [20, 50, 100].indexOf(GetRole(me, this.props.match.params.centrotrabajoId)) === -1 ?
                            <label style={{ marginLeft: 10 }}>
                                {`No tiene permisos para visualizar este espacio.`}
                            </label>
                            :
                            <div>
                                {this.renderCalendar()}
                                <div className='legend'><span className='preventivos'>Preventivos</span><span className='correctivos'>Correctivos</span></div>
                                {this.state.showEventDetail ?
                                    <CreateModal isOpen={this.state.showEventDetail} titleModal='Detalle del evento' onCloseCreationModal={this.hadleCloseEventDetailModal} typeModal={1} inputText={this.state.eventDetail}/>
                                    :null
                                }
                            </div>
                            
                        :
                        this.state.loading ?
                            <div style={{ marginLeft: 20 }}>
                                <Loader loading={true} />
                            </div>
                            :
                            <label style={{ marginLeft: 10 }}>
                                {`Fallo en la carga del perfil, volver a intentar.`}
                            </label>
                    }

                </div>

            </div>
        );
    }
}
export default Calendar;