import React from "react";
import { Text, View, Image, Font, StyleSheet } from "@react-pdf/renderer";
import { PdfStyles } from "./pdfStyles";
import { InformeT } from "../types";
import { Section, Action } from "../../Section/types";
import { ICentroTrabajo } from "../../../types";
import { GetDatetimeFormat, GetFormattedDateTime } from "../../../vc_general";
import AppNealia_prevent_icon from '../../../Assets/nealia_prevent_icon.png';
import AppNealia_prevent from '../../../Assets/nealia_prevent.png';
import { act } from "react-dom/test-utils";
import { Document, Page } from "../../../Components/Material/pdfRenderComponents";

// const convertHtmlToPDFComponents = (htmlString:any) => {
//   const parser = new DOMParser();
//   const htmlDocument = parser.parseFromString(htmlString, 'text/html');
//   const body = htmlDocument.body;

//   const GetStyle =(style:string|null, addedStyle:any = {})=>{

//     if(style == null)
//       return {};

//     let styles:Array<any> = [addedStyle];

//     const split_style = style.split(';');

//     split_style.map((item:string, idx00:number)=>{
//       const tmp_item = item.trim();

//       if(tmp_item.startsWith('text-decoration:') && tmp_item.includes('underline'))
//         styles.push(PdfStyles.underline);
//       if(tmp_item.startsWith('color:'))
//         styles.push({color:tmp_item.split(':')[1].trim()})
//       if(tmp_item.startsWith('background-color:'))
//         styles.push({backgroundColor:tmp_item.split(':')[1].trim()})
//       if(tmp_item.startsWith('padding-left:'))
//         styles.push({paddingLeft:tmp_item.split(':')[1].trim()})
//     })

//     return styles;
//   }

//   const traverseNodes = (node:ChildNode, styles:Array<any>|Object = {}) => {
//       switch (node.nodeName.toLowerCase()) {
//           case 'p':
//               const p_nodeElement = node as HTMLElement;
//               const p_nodeElement_style = p_nodeElement.getAttribute("style");

//               return (
//                   <Text style={GetStyle(p_nodeElement_style, PdfStyles.paragraph)} key={Math.random()}>
//                       {Array.from(node.childNodes).map((childNode, index) => traverseNodes(childNode))}
//                   </Text>
//               );
//           case 'strong':
//               return (
//                   <Text key={Math.random()}>
//                       {Array.from(node.childNodes).map((childNode, index) => traverseNodes(childNode, PdfStyles.strong))}
//                   </Text>
//               );
//           case 'em':
//             return (
//                 <Text style={PdfStyles.em} key={Math.random()}>
//                     {Array.from(node.childNodes).map((childNode, index) => traverseNodes(childNode))}
//                 </Text>
//             );
//           case 'span':
    
//             const span_nodeElement = node as HTMLElement;
//             const span_nodeElement_style = span_nodeElement.getAttribute("style");

//             return (
//               <Text key={Math.random()}>
//                 {Array.from(node.childNodes).map((childNode, index) => traverseNodes(childNode, GetStyle(span_nodeElement_style)))}
//               </Text>
//             );
//           case 'ul':
//           case 'ol':
//             return (
//                 <View style={PdfStyles.list} key={Math.random()}>
//                     {Array.from(node.childNodes).map((childNode, index) => traverseNodes(childNode))}
//                 </View>
//             );
//           case 'li':
//             const li_htmlElement = node.parentElement as HTMLElement;
//             const li_parent_style = li_htmlElement.getAttribute("style");

//             const GetBulletItem =(style:string|null)=>{
//               if(style == null)
//                 return PdfStyles.bullet;

//               if(style.includes("list-style-type: square"))
//                 return (
//                   PdfStyles.square
//                 )
//               else if(style.includes("list-style-type: circle"))
//                 return (
//                   PdfStyles.circle
//                 )
//               else if(style.includes("list-style-type: circle"))
//                 return (
//                   PdfStyles.circle
//                 )
//               else
//                 return PdfStyles.bullet
//             }

//             return(
//               <View style={PdfStyles.bulletItem}>
//                 <View style={GetBulletItem(li_parent_style)}/>

//                 <Text style={PdfStyles.listItem} key={Math.random()}>
//                     {Array.from(node.childNodes).map((childNode, index) => traverseNodes(childNode))}
//                 </Text>
//               </View>
//             )

//           // case 'li':
//           //     console.log(node.parentElement)
//           //     console.log(node.parentNode)
//           //     const htmlElement = node.parentElement as HTMLElement;
//           //     const nodeElement = node as HTMLElement;

//           //     const style = htmlElement.getAttribute("style");

//           //     if(style == null)
//           //       return (
//           //         <Text style={PdfStyles.listItem} key={Math.random()}>
//           //           {nodeElement.innerHTML}
//           //         </Text>
//           //       )
//           //     else if(style.includes("list-style-type: square"))
//           //       return (
//           //         <Text style={PdfStyles.square} key={Math.random()}>
//           //           {nodeElement.innerHTML}
//           //         </Text>
//           //       )
//           //     else if(style.includes("list-style-type: circle"))
//           //       return (
//           //         <Text style={PdfStyles.circle} key={Math.random()}>
//           //           {nodeElement.innerHTML}
//           //         </Text>
//           //       )
//           //     return (
//           //       <>
//           //         <Text style={PdfStyles.bulletPoint}>•</Text>
//           //         <Text style={PdfStyles.listItem} key={Math.random()}>
//           //             {Array.from(node.childNodes).map((childNode, index) => traverseNodes(childNode))}
//           //         </Text>
//           //       </>
//           //     );
//           default:
//               return node.nodeType === 3 ? <Text style={styles}>{node.textContent}</Text> : null; // Texto
//       }
//   };

//   return Array.from(body.childNodes).map((node, index) => traverseNodes(node));
// };

// const convertHtmlToPDFComponents = (htmlString: string): React.ReactNode[] => {
//   console.log(htmlString)
//   const parser = new DOMParser();
//   const htmlDocument = parser.parseFromString(htmlString, 'text/html');
//   const body = htmlDocument.body;

//   const GetStyle = (style: string | null, addedStyle: any = {}): any => {
//     if (style == null) return {};

//     let styles: any[] = [addedStyle];

//     const split_style = style.split(';');

//     split_style.map((item: string) => {
//       const tmp_item = item.trim();

//       if (tmp_item.startsWith('text-decoration:') && tmp_item.includes('underline'))
//         styles.push(PdfStyles.underline);
//       if (tmp_item.startsWith('color:'))
//         styles.push({ color: tmp_item.split(':')[1].trim() });
//       if (tmp_item.startsWith('background-color:'))
//         styles.push({ backgroundColor: tmp_item.split(':')[1].trim() });
//       if (tmp_item.startsWith('padding-left:'))
//         styles.push({ paddingLeft: tmp_item.split(':')[1].trim() });
//     });

//     return styles;
//   };

//   const getListItemLabel = (index: number, listType: string | null): string => {
//     switch (listType) {
//       case 'lower-alpha':
//         return String.fromCharCode(97 + ((index - 1) % 26)) + '. '; // a, b, c, ...
//       case 'upper-alpha':
//         return String.fromCharCode(65 + ((index - 1) % 26)) + '. '; // A, B, C, ...
//       case 'lower-greek':
//         return getGreekLetter(index).toLowerCase() + '. '; // α, β, γ, ...
//       case 'upper-roman':
//         return toRoman(index).toUpperCase() + '. '; // I, II, III, ...
//       case 'lower-roman':
//         return toRoman(index).toLowerCase() + '. '; // i, ii, iii, ...
//       default:
//         return `${index}. `; // Default is numeric
//     }
//   };

//   const getGreekLetter = (index: number): string => {
//     const greekAlphabet = [
//       'α', 'β', 'γ', 'δ', 'ε', 'ζ', 'η', 'θ', 'ι', 'κ', 'λ', 'μ', 'ν', 'ξ', 'ο', 'π', 'ρ', 'σ', 'τ', 'υ', 'φ', 'χ', 'ψ', 'ω'
//     ];
//     return greekAlphabet[(index - 1) % 24];
//   };

//   const toRoman = (num: number): string => {
//     const romanNumerals: { [key: number]: string } = {
//       1: 'i', 4: 'iv', 5: 'v', 9: 'ix', 10: 'x', 40: 'xl', 50: 'l', 90: 'xc', 100: 'c', 400: 'cd', 500: 'd', 900: 'cm', 1000: 'm'
//     };
//     let roman = '';
//     const keys = Object.keys(romanNumerals).map(Number).reverse();

//     for (const key of keys) {
//       while (num >= key) {
//         roman += romanNumerals[key];
//         num -= key;
//       }
//     }

//     return roman;
//   };

//   const traverseNodes = (node: ChildNode, styles: any = {}, listIndex: number = 1, isList: boolean = false, listType: string | null = null): React.ReactNode | null => {
//     // Si estamos en una lista (ordenada o no), ignoramos los nodos de texto que contienen solo espacios en blanco o retornos de carro
//     if (isList && node.nodeType === 3 && !node.textContent?.trim()) {
//       return null;
//     }

//     switch (node.nodeName.toLowerCase()) {
//       case 'p': {
//         const p_nodeElement = node as HTMLElement;
//         const p_nodeElement_style = p_nodeElement.getAttribute('style');

//         return (
//           <Text style={GetStyle(p_nodeElement_style, PdfStyles.paragraph)} key={Math.random()}>
//             {Array.from(node.childNodes).map((childNode) => traverseNodes(childNode))}
//           </Text>
//         );
//       }
//       case 'strong':
//         return (
//           <Text key={Math.random()}>
//             {Array.from(node.childNodes).map((childNode) => traverseNodes(childNode, PdfStyles.strong))}
//           </Text>
//         );
//       case 'em':
//         return (
//           <Text style={PdfStyles.em} key={Math.random()}>
//             {Array.from(node.childNodes).map((childNode) => traverseNodes(childNode))}
//           </Text>
//         );
//       case 'span': {
//         const span_nodeElement = node as HTMLElement;
//         const span_nodeElement_style = span_nodeElement.getAttribute('style');

//         return (
//           <Text key={Math.random()}>
//             {Array.from(node.childNodes).map((childNode) => traverseNodes(childNode, GetStyle(span_nodeElement_style)))}
//           </Text>
//         );
//       }
//       case 'ul':
//       case 'ol': {
//         const listStyleType = (node as HTMLElement).style.listStyleType || null;
//         let currentIndex = 1; // Iniciar el índice en 1 para cada lista ordenada
//         return (
//           <View style={PdfStyles.list} key={Math.random()}>
//             {Array.from(node.childNodes)
//               .map((childNode) => {
//                 const result = traverseNodes(childNode, {}, currentIndex, true, listStyleType);
//                 if (node.nodeName.toLowerCase() === 'ol' && result) currentIndex += 1; // Incrementar el índice solo si el nodo no es nulo y es una lista ordenada
//                 return result;
//               })
//               .filter((child) => child !== null)}
//           </View>
//         );
//       }
//       case 'li': {
//         const li_htmlElement = node.parentElement as HTMLElement;
//         const li_parent_style = li_htmlElement.getAttribute('style');

//         if (li_htmlElement.nodeName.toLowerCase() === 'ol') {
//           return (
//             <View style={PdfStyles.bulletItem} key={Math.random()}>
//               <Text style={PdfStyles.listItemNumbered}>{getListItemLabel(listIndex, listType)}</Text>
//               <Text style={PdfStyles.listItem}>
//                 {Array.from(node.childNodes)
//                   .map((childNode) => traverseNodes(childNode, {}, listIndex, true, listType))
//                   .filter((child) => child !== null)}
//               </Text>
//             </View>
//           );
//         } else {
//           const GetBulletItem = (style: string | null): any => {
//             if (style == null) return PdfStyles.bullet;

//             if (style.includes('list-style-type: square')) return PdfStyles.square;
//             else if (style.includes('list-style-type: circle')) return PdfStyles.circle;
//             else return PdfStyles.bullet;
//           };

//           return (
//             <View style={PdfStyles.bulletItem} key={Math.random()}>
//               <View style={GetBulletItem(li_parent_style)} />
//               <Text style={PdfStyles.listItem}>
//                 {Array.from(node.childNodes)
//                   .map((childNode) => traverseNodes(childNode, {}, listIndex, true, listType))
//                   .filter((child) => child !== null)}
//               </Text>
//             </View>
//           );
//         }
//       }
//       default:
//         return node.nodeType === 3 ? <Text style={styles}>{node.textContent}</Text> : null; // Texto
//     }
//   };

//   return Array.from(body.childNodes)
//     .map((node) => traverseNodes(node))
//     .filter((node) => node !== null);
// };

const convertHtmlToPDFComponents = (htmlString: string): React.ReactNode[] => {
  const parser = new DOMParser();
  const htmlDocument = parser.parseFromString(htmlString, 'text/html');
  const body = htmlDocument.body;

  const GetStyle = (style: string | null, addedStyle: any = {}): any => {
    if (style == null) return {};

    let styles: any[] = [addedStyle];

    const split_style = style.split(';');

    split_style.map((item: string) => {
      const tmp_item = item.trim();
      if (tmp_item.startsWith('text-decoration:') && tmp_item.includes('underline'))
        styles.push(PdfStyles.underline);
      if (tmp_item.startsWith('color:'))
        styles.push({ color: tmp_item.split(':')[1].trim() });
      if (tmp_item.startsWith('background-color:'))
        styles.push({ backgroundColor: tmp_item.split(':')[1].trim() });
      if (tmp_item.startsWith('background:'))
        styles.push({ backgroundColor: tmp_item.split(':')[1].trim() });
      if (tmp_item.startsWith('padding-left:'))
        styles.push({ paddingLeft: tmp_item.split(':')[1].trim() });
    });

    return styles;
  };

  const getListItemLabel = (index: number, listType: string | null): string => {
    switch (listType) {
      case 'lower-alpha':
        return String.fromCharCode(97 + ((index - 1) % 26)) + '. '; // a, b, c, ...
      case 'upper-alpha':
        return String.fromCharCode(65 + ((index - 1) % 26)) + '. '; // A, B, C, ...
      case 'lower-greek':
        return getGreekLetter(index).toLowerCase() + '. '; // α, β, γ, ...
      case 'upper-roman':
        return toRoman(index).toUpperCase() + '. '; // I, II, III, ...
      case 'lower-roman':
        return toRoman(index).toLowerCase() + '. '; // i, ii, iii, ...
      default:
        return `${index}. `; // Default is numeric
    }
  };

  const getGreekLetter = (index: number): string => {
    const greekAlphabet = [
      'α', 'β', 'γ', 'δ', 'ε', 'ζ', 'η', 'θ', 'ι', 'κ', 'λ', 'μ', 'ν', 'ξ', 'ο', 'π', 'ρ', 'σ', 'τ', 'υ', 'φ', 'χ', 'ψ', 'ω'
    ];
    return greekAlphabet[(index - 1) % 24];
  };

  const toRoman = (num: number): string => {
    const romanNumerals: { [key: number]: string } = {
      1: 'i', 4: 'iv', 5: 'v', 9: 'ix', 10: 'x', 40: 'xl', 50: 'l', 90: 'xc', 100: 'c', 400: 'cd', 500: 'd', 900: 'cm', 1000: 'm'
    };
    let roman = '';
    const keys = Object.keys(romanNumerals).map(Number).reverse();

    for (const key of keys) {
      while (num >= key) {
        roman += romanNumerals[key];
        num -= key;
      }
    }

    return roman;
  };

  const traverseNodes = (
    node: ChildNode,
    styles: any = {},
    listIndex: number = 1,
    isList: boolean = false,
    listType: string | null = null
  ): React.ReactNode | null => {
    // Ignorar nodos de texto vacíos en listas
    if (isList && node.nodeType === 3 && !node.textContent?.trim()) {
      return null;
    }

    switch (node.nodeName.toLowerCase()) {
      case 'p': {
        const p_nodeElement = node as HTMLElement;
        const p_nodeElement_style = p_nodeElement.getAttribute('style');
  
        return (
          <Text style={GetStyle(p_nodeElement_style, PdfStyles.paragraph)} key={Math.random()}>
            {Array.from(node.childNodes).map((childNode) => traverseNodes(childNode, GetStyle(p_nodeElement_style)))}
          </Text>
        );
      }
      case 'strong':
        return (
          <Text style={[styles, PdfStyles.strong]} key={Math.random()}>
            {Array.from(node.childNodes).map((childNode) => traverseNodes(childNode, PdfStyles.strong))}
          </Text>
        );
      case 'em':
        return (
          <Text style={[styles, PdfStyles.em]} key={Math.random()}>
            {Array.from(node.childNodes).map((childNode) => traverseNodes(childNode, PdfStyles.em))}
          </Text>
        );
      case 'span': {
        const span_nodeElement = node as HTMLElement;
        const span_nodeElement_style = span_nodeElement.getAttribute('style');
        return (
          <Text style={[styles]}/*style={[styles, GetStyle(span_nodeElement_style)]}*/ key={Math.random()}>
            {Array.from(node.childNodes).map((childNode) => traverseNodes(childNode, GetStyle(span_nodeElement_style)))}
          </Text>
        );
      }
      case 'ul':
      case 'ol': {
        const listStyleType = (node as HTMLElement).style.listStyleType || null;
        let currentIndex = 1; // Iniciar el índice en 1 para cada lista ordenada
        return (
          <View style={PdfStyles.list} key={Math.random()}>
            {Array.from(node.childNodes)
              .map((childNode) => {
                const result = traverseNodes(childNode, {}, currentIndex, true, listStyleType);
                if (node.nodeName.toLowerCase() === 'ol' && result) currentIndex += 1; // Incrementar el índice solo si el nodo no es nulo y es una lista ordenada
                return result;
              })
              .filter((child) => child !== null)}
          </View>
        );
      }
  
      case 'li': {
        const li_htmlElement = node.parentElement as HTMLElement;
        const li_parent_style = li_htmlElement.getAttribute('style');
  
        // Verifica si el elemento 'li' tiene una lista anidada
        const subList = Array.from(node.childNodes).find(
          (childNode) => childNode.nodeName.toLowerCase() === 'ul' || childNode.nodeName.toLowerCase() === 'ol'
        );
  
        if (li_htmlElement.nodeName.toLowerCase() === 'ol') {
          return (
            <View style={PdfStyles.bulletItem} key={Math.random()}>
              <Text style={PdfStyles.listItemNumbered}>{getListItemLabel(listIndex, listType)}</Text>
              <View style={PdfStyles.listItem}>
                {Array.from(node.childNodes)
                  // Excluir la sublista de los nodos procesados para evitar duplicación
                  .filter((childNode) => childNode !== subList)
                  .map((childNode) => traverseNodes(childNode, {}, listIndex, true, listType))
                  .filter((child) => child !== null)}
                {/* Renderizar sublista si existe */}
                {subList && (
                  <View style={PdfStyles.sublist}>
                    {traverseNodes(subList)}
                  </View>
                )}
              </View>
            </View>
          );
        } else {
          const GetBulletItem = (style: string | null): any => {
            if (style == null) return PdfStyles.bullet;
  
            if (style.includes('list-style-type: square')) return PdfStyles.square;
            else if (style.includes('list-style-type: circle')) return PdfStyles.circle;
            else return PdfStyles.bullet;
          };
  
          return (
            <View style={PdfStyles.bulletItem} key={Math.random()}>
              <View style={GetBulletItem(li_parent_style)} />
              <View style={PdfStyles.listItem}>
                {Array.from(node.childNodes)
                  // Excluir la sublista de los nodos procesados para evitar duplicación
                  .filter((childNode) => childNode !== subList)
                  .map((childNode) => traverseNodes(childNode, {}, listIndex, true, listType))
                  .filter((child) => child !== null)}
                {/* Renderizar sublista si existe */}
                {subList && (
                  <View style={PdfStyles.sublist}>
                    {traverseNodes(subList)}
                  </View>
                )}
              </View>
            </View>
          );
        }
      }
      default:
        return node.nodeType === 3 ? <Text style={[styles, PdfStyles.listItemText]}>{node.textContent}</Text> : null; // Texto
    }
  };
  return Array.from(body.childNodes)
  .map((node) => traverseNodes(node))
  .filter((node) => node !== null);
};

Font.register({
  family: 'Open Sans',
  fonts: [
    { src: '/OpenSans-Regular.ttf' },
    { src: '/OpenSans-Bold.ttf', fontWeight: 'bold' },
    { src: '/OpenSans-Italic.ttf', fontStyle: 'italic', fontWeight: 'normal' },
    { src: '/OpenSans-BoldItalic.ttf', fontStyle: 'italic', fontWeight: 'bold' },
  ],
});

//ISO to date conversion for PDF template
const formatDate = (dateStr: string | number | Date) => {
  const date = new Date(dateStr);
  return date.toLocaleDateString("es-ES", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
};

//Style change if informe is Visit or Audit
const getMarkStyle = (isMarkPresent: any) => {
  return isMarkPresent ? PdfStyles.mark : { ...PdfStyles.mark, backgroundColor: '#FFFFFF', border: 0 };
};

const getMark1Style = (isMarkPresent: any) => {
  return isMarkPresent ? PdfStyles.mark1 : { ...PdfStyles.mark1, backgroundColor: '#FFFFFF', border: 0 };
};

const getMarkEmpty = (isMarkPresent: any) => {
  return isMarkPresent ? PdfStyles.markempty : { ...PdfStyles.markempty, backgroundColor: '#FFFFFF', border: 0 };
};



export const MyDocument = ({ informe, empresa }: { informe: InformeT, empresa?: ICentroTrabajo }) => {
  const orderedSections = (informe.sections || []).sort((a, b) => a.groupId - b.groupId);
  if (!empresa) return null;

  const renderTotalInformeSection = () => {
    if (!informe.mark) return null;

    return (
      <View style={PdfStyles.titlesec}>
        <View style={PdfStyles.titletext}>
          <Text>TOTAL INFORME</Text>
        </View>
        <View style={getMark1Style(isMarkPresent)}>
          <Text style={getMarkTextStyle(parseFloat(informe.mark!.toFixed(2)))}>
            {parseFloat(informe.mark!.toFixed(2))}
          </Text>
        </View>        
      </View>
      
    );
  };

  const isMarkPresent = informe.mark !== undefined && informe.mark !== null && informe.tipo == 2;

  const getMarkTextStyle = (mark: string | number | undefined) => {
    let color = '#000000';
    if (mark === undefined) {
      return { ...PdfStyles.markText, color };
    }


    const parsedMark = typeof mark === 'string' ? parseFloat(mark) : mark;


    if (parsedMark < 5) {
      color = '#b01c1c';
    } else if (parsedMark >= 5 && parsedMark < 7) {
      color = '#f77f00';
    } else if (parsedMark >= 7 && parsedMark < 9) {
      color = '#fcbf49';
    } else if (parsedMark >= 9) {
      color = '#70a444';
    }

    return { ...PdfStyles.markText, color };
  };


  const renderBuenasPracticasSection = () => {
    if (!informe.globalComment01) return null;

    const pdfComponents = convertHtmlToPDFComponents(informe.globalComment01);

    return (
      <View style={PdfStyles.apartado} wrap={false}>
        <View style={PdfStyles.titlesec}>
          <View style={PdfStyles.titletext}><Text><Text style={PdfStyles.numeration}> // </Text>{`Buenas Prácticas:`}</Text></View>
        </View>
        <View style={PdfStyles.comentarios}>
          <View style={PdfStyles.ctext}>{pdfComponents}</View>
        </View>
      </View>

    );
  };

  const renderValoracionGlobalSection = () => {
    if (!informe.globalComment02) return null;

    const pdfComponents = convertHtmlToPDFComponents(informe.globalComment02);

    return (
      <View style={PdfStyles.global} wrap={false}>
        <View style={PdfStyles.titlesec}>
          <View style={PdfStyles.titletext}><Text><Text style={PdfStyles.numeration}> // </Text>{`Valoración Global:`}</Text></View>
        </View>
        <View style={PdfStyles.comentarios}>
          <View style={PdfStyles.ctext}>{pdfComponents}</View>
        </View>
      </View>
    );
  };
  const groups = orderedSections.reduce<Record<string, Section[]>>((acc, section) => {
    const groupIdKey = String(section.groupId);
    if (!acc[groupIdKey]) {
      acc[groupIdKey] = [];
    }
    acc[groupIdKey].push(section);
    return acc;
  }, {});

  const createTableHeader = () => {

    return (
      <View style={PdfStyles.tableRowStyle} fixed>

        <View style={PdfStyles.tableColHeaderStyle}>
          <Text style={PdfStyles.tableCellHeaderStyle}>TIPO</Text>
        </View>

        <View style={PdfStyles.tableColHeaderStyle}>
          <Text style={PdfStyles.tableCellHeaderStyle}>ID</Text>
        </View>

        <View style={PdfStyles.tableColHeaderStyle}>
          <Text style={PdfStyles.tableCellHeaderStyle}>BLOQUE</Text>
        </View>

        <View style={PdfStyles.tableColHeaderStyle}>
          <Text style={PdfStyles.tableCellHeaderStyle}>APARTADO</Text>
        </View>

        <View style={PdfStyles.tableColHeaderStyle}>
          <Text style={PdfStyles.tableCellHeaderStyle}>FECHA CREACIÓN</Text>
        </View>
      </View>
    );
  };

  const createTableRows = (rows:Action[]) => {    
    return (      
      rows.map((row) => (        
        <View style={PdfStyles.tableRowStyle}>
          <View style={PdfStyles.tableColStyle}>
            <Text style={PdfStyles.tableCellStyle}>{row.typeDsc}</Text>
          </View>
  
          <View style={PdfStyles.tableColStyle}>
            <Text style={PdfStyles.boldTableCellStyle}>{row.referenceDsc}</Text>
          </View>
  
          <View style={PdfStyles.tableColStyle}>
            <Text style={PdfStyles.tableCellStyle}>{row.sectionDsc}</Text>
          </View>
  
          <View style={PdfStyles.tableColStyle}>
            <Text style={PdfStyles.tableCellStyle}>{row.subsectionDsc}</Text>
          </View>
  
          <View style={PdfStyles.tableColStyle}>
            <Text style={PdfStyles.tableCellStyle}>{formatDate(row.dtCreated)}</Text>
          </View>
        </View> 
      ))
    );
  };

  const renderMedidasRealizadas = (medidas:Action[]) => {
    
    if (medidas.length == 0) {
      return null;
    }else{
      return (
        <View style={PdfStyles.apartado}>
          <View style={PdfStyles.titlesec}>
            <View style={PdfStyles.titletext}><Text><Text style={PdfStyles.numeration}> // </Text>{`Medidas realizadas:`}</Text></View>
          </View>
          {createTableHeader()}                
          {createTableRows(medidas)}           
        </View>
      )
    }    
  }

  const medidasRealizadas = () =>{
    let correctivos: Action[] = [];
    let preventivos: Action[] = [];
    let secctions = (informe.sections || []).sort((a, b) => a.id- b.id);
    secctions?.forEach(section => {
      section.subsections?.forEach(subsection => {        
        subsection.actions?.forEach(action => {
          if(action.tipo === 1)
            preventivos.push(action);
          else
            correctivos.push(action);
        })
      })
    });
    return [...correctivos, ...preventivos];
  }

  return (
    <Document>
      <Page size="A4" style={PdfStyles.page}>
        {/* PDF Header */}
        {/* <View style={PdfStyles.mainlogo}>
            <Image style={PdfStyles.logo1} src="/nealia3d.png" />
          </View> */}
        <View style={PdfStyles.rowlogo}>
          <View style={PdfStyles.mainlogo}>
            <Image style={PdfStyles.logo1} src={AppNealia_prevent_icon} />
          </View>
          <View style={{ position: 'absolute', left: '45%', top: 5, textAlign: 'center' }}>
            <Text>{informe.code}</Text>
          </View>
          <View style={PdfStyles.scdlogo}>
            <Image style={PdfStyles.logo2} src={empresa.logo} />
          </View>
        </View>
        <View style={PdfStyles.rheader}>
          {/* <View style={PdfStyles.rowlogo}>
            <View style={PdfStyles.mainlogo}>
              <Image style={PdfStyles.logo1} src={AppNealia_prevent_icon} />
            </View>
            <View style={{ position: 'absolute', left: '45%', top: 5, textAlign: 'center' }}>
              <Text>{informe.code}</Text>
            </View>
            <View style={PdfStyles.scdlogo}>
              <Image style={PdfStyles.logo2} src={empresa.logo} />
            </View>
          </View> */}
          <View style={PdfStyles.title}>
            <Text>{informe.name}</Text>
          </View>
          <View style={PdfStyles.info}>
            <View style={PdfStyles.data}>
              <View style={PdfStyles.campo}>
                <Text>FECHA</Text>
              </View>
              <View style={PdfStyles.dato}>
                <Text>{formatDate(informe.dtStartVisit)} - {formatDate(informe.dtEndVisit)}</Text>
              </View>
            </View>
            <View style={PdfStyles.data}>
              <View style={PdfStyles.campo}>
                <Text>CENTRO</Text>
              </View>
              <View style={PdfStyles.dato}>
                <Text>{empresa.description}</Text>
              </View>
            </View>
            {informe.tipo == 2 ?
              <React.Fragment>
                <View style={PdfStyles.data}>
                  <View style={PdfStyles.campo}>
                    <Text>EQUIPO AUDITOR</Text>
                  </View>
                  <View style={PdfStyles.dato}>
                    <Text>{informe.team}</Text>
                  </View>
                </View>
                <View style={PdfStyles.data}>
                  <View style={PdfStyles.campo}>
                    <Text>PARTICIPANTES</Text>
                  </View>
                  <View style={PdfStyles.dato}>
                    <Text>{informe.participant}</Text>
                  </View>
                </View>
              </React.Fragment>
              :
              null
            }

            <View style={PdfStyles.data}>
              <View style={PdfStyles.campo}>
                <Text>AUTOR</Text>
              </View>
              <View style={PdfStyles.dato}>
                <Text>{informe.author}</Text>
              </View>
            </View>

          </View>
        </View>
        {informe.description && informe.description.length > 0 ?
          <View style={PdfStyles.desc}>
            <Text>{informe.description}</Text>
          </View>
          :
          null
        }
        <View style={PdfStyles.sections}>
          {/* Regroup sections by groupID for mark */}
          {Object.entries(groups).reverse().map(([groupId, sections]) => (
            <React.Fragment key={groupId}>
              {groupId === "2" && <View style={PdfStyles.titlesec} wrap={false} minPresenceAhead={100}>
                <View style={PdfStyles.group}>
                  <Text>GESTIÓN DOCUMENTAL</Text>
                </View>
                <View style={getMark1Style(isMarkPresent)}>
                  {informe.tipo === 2 ?
                    <Text style={PdfStyles.markText2}> {parseFloat(informe.markDocumentManagement!.toFixed(2))} </Text>
                    :
                    null
                  }
                </View>
              </View>
              }

              {groupId === "1" && <View style={PdfStyles.titlesec} wrap={false} minPresenceAhead={100}>
                <View style={PdfStyles.group}>
                  <Text>CONTROL OPERACIONAL</Text>
                </View>
                <View style={getMark1Style(isMarkPresent)}>
                  {informe.tipo == 2 ?
                    <Text style={PdfStyles.markText2}> {parseFloat(informe.markOperationalControl!.toFixed(2))} </Text>
                    :
                    null
                  }
                </View>
              </View>}

              {sections.map((section, sectionIndex) => (
                <React.Fragment key={sectionIndex} >
                  {/* SECCIONES */}
                  <View style={PdfStyles.titlesec} wrap={false} minPresenceAhead={60}>
                    <View style={PdfStyles.titletext}>
                      <Text><Text style={PdfStyles.numeration}>{section.numeration} /</Text> {section.name}</Text>
                    </View>
                    <View style={getMark1Style(isMarkPresent)}></View>
                  </View>

                  {/* SUBSECCIONES */}
                  {section.subsections.map((subsection, subIndex) => (
                    <View key={subIndex} style={PdfStyles.apartado} wrap={false} minPresenceAhead={60}>
                      <View style={PdfStyles.apartadotitle} wrap={false} minPresenceAhead={60}>
                        <View style={PdfStyles.aspacer}></View>
                        <Text style={PdfStyles.atext}><Text style={PdfStyles.numeration}>{subsection.numeration} / </Text>{subsection.name}</Text>
                          <View style={getMarkStyle(isMarkPresent)}>
                            {isMarkPresent && subsection.includeAvg ?
                              <Text style={getMarkTextStyle(parseFloat(Number(subsection.mark).toFixed(2)))}>
                                {(parseFloat(Number(subsection.mark).toFixed(2)))}
                              </Text>
                            :
                              null
                            }
                          </View>
                      </View>

                      {/* COMENTARIOS DE LAS SUBSECCIONES */}
                      {subsection.comments?.map((comment, commentIndex) => {
                        const pdfComponents = convertHtmlToPDFComponents(comment.comment);
                        return (
                          <View key={commentIndex} style={PdfStyles.comentarios} wrap={false} minPresenceAhead={60}>
                            <View style={PdfStyles.cspacer}>
                              {/* <View style={PdfStyles.square}></View> */}
                            </View>
                            {/* <Text style={PdfStyles.ctext}>{comment.comment}</Text> */}
                            <View style={PdfStyles.ctext}>
                              {pdfComponents}
                              <View style={PdfStyles.cseparator}/>
                            </View>
                            <View style={getMarkEmpty(isMarkPresent)}></View>
                          </View>
                        )
                        })}
                    </View>
                  ))}
                  
                </React.Fragment>
              ))}
            </React.Fragment>
          ))}
          {renderTotalInformeSection()}
          {renderBuenasPracticasSection()}
          {renderValoracionGlobalSection()}          
        </View>
        {/* PDF Footer with page counter*/}
        {/* Footer con texto */}
        {/* <Text style={PdfStyles.footer} render={({ pageNumber, totalPages }) => `Página ${pageNumber} de ${totalPages} | Código: ${informe.code} - Autor: ${informe.author} Fecha de creación: ${formatDate(informe.dtCreated)} `} fixed /> */}
        <Text style={PdfStyles.footer} render={({ pageNumber, totalPages }) => `Página ${pageNumber} de ${totalPages} | ${informe.code} - ${GetDatetimeFormat(informe.dtCreated)} `} fixed />

        {/* Imagen en el footer */}
        <Image style={PdfStyles.footerImage2} src={AppNealia_prevent_icon} fixed />
        <Image style={PdfStyles.footerImage} src={AppNealia_prevent} fixed />
        {[10,20].includes(informe.status) ? <Text style={PdfStyles.waterMark} fixed>BORRADOR</Text> : null}        
      </Page>
      {medidasRealizadas().length > 0 ?
        <Page size="A4" style={PdfStyles.page}>
          <View style={PdfStyles.sections}>
            {renderMedidasRealizadas(medidasRealizadas())}            
          </View>
          
            {/* PDF Footer with page counter*/}
          {/* Footer con texto */}
          {/* <Text style={PdfStyles.footer} render={({ pageNumber, totalPages }) => `Página ${pageNumber} de ${totalPages} | Código: ${informe.code} - Autor: ${informe.author} Fecha de creación: ${formatDate(informe.dtCreated)} `} fixed /> */}
          <Text style={PdfStyles.footer} render={({ pageNumber, totalPages }) => `Página ${pageNumber} de ${totalPages} | ${informe.code} - ${GetDatetimeFormat(informe.dtCreated)} `} fixed />

          {/* Imagen en el footer */}
          <Image style={PdfStyles.footerImage2} src={AppNealia_prevent_icon} fixed />
          <Image style={PdfStyles.footerImage} src={AppNealia_prevent} fixed />
          {[10,20].includes(informe.status) ? <Text style={PdfStyles.waterMark} fixed>BORRADOR</Text> : null}        
        </Page>
      :
        null
      }
      
    </Document>
  );
};