import { EcoTwoTone } from '@material-ui/icons';
import React from 'react';
import {GetEmpresa, GetRole} from '../helpers';
import { IMe, ICentroTrabajo } from '../types';
import SvgIcon from '@material-ui/core/SvgIcon';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import './LateralMenu.css';
export interface ILateralMenuProps {
    match:any;
    history:any;
    opened: boolean;
    me?:IMe,
    handleToogle:Function;
}
 
export interface ILateralMenuState {
    empresa:string;
    url:string;
}
 
class LateralMenu extends React.PureComponent<ILateralMenuProps, ILateralMenuState> {
    constructor(props: ILateralMenuProps) {
        super(props);
        this.state = {
            empresa:'company',
            url: props.match.url
        };
    }

    componentDidUpdate(prevProps:ILateralMenuProps, prevState:ILateralMenuState){
        // alert(prevProps.match.url)
        if(this.props.match.url == this.state.url)
            return;

        this.setState({url: this.props.match.url}, ()=>this.props.handleToogle());

    }

    handleClick=(route:string)=>{
        this.props.history.push(`/${this.props.match.params.centrotrabajoId}/${route}`)
    }

    handleNoOption=(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>)=>{
        e.preventDefault();
    }

    render() {
        const {me, opened} = this.props;

        return (
            <div>
                <div className='lm-overlay-nav' style={{ display: (opened?'block':'none') }}  onClick={()=>opened?this.props.handleToogle():null}/>
                <nav className={opened?"navigation":"navigation navigation-hide"}>
                    <ul className="mainmenu">
                        
                        <li><a onClick={e => this.handleClick("dashboard")}>Tablero</a></li>
                        {me && [100].includes(GetRole(me, Number(this.props.match.params.centrotrabajoId))) ? 
                            <li><a onClick={e => this.handleClick("agenda")}>Agenda</a></li>
                        :
                            null
                        }
                        
                        <li>
                            <div className='hasSubMenu'>
                                <a onClick={e => this.handleNoOption(e)}>Preventivos</a>
                                <SvgIcon component={ArrowDropDownIcon} viewBox="0 0 24 24" style={{color:"#F90000"}} />
                            </div>
                            <ul className="submenu">
                                
                                {me && [5,20,50,100].includes(GetRole(me, Number(this.props.match.params.centrotrabajoId))) ? 
                                    <React.Fragment>
                                        <li><a onClick={()=>this.handleClick("panelcontrol/1")}>Panel control</a></li>
                                    </React.Fragment>
                                : 
                                    null
                                }
                                <li><a onClick={()=>this.handleClick(`listado/${1}`)}>Listado</a></li>
                            </ul>
                        </li>
                        <li><a onClick={()=>this.handleClick(`listado/${2}`)}>Correctivos</a></li>

                        {me && [5,10,11,20,50,100].includes(GetRole(me, Number(this.props.match.params.centrotrabajoId))) ? 
                            <React.Fragment>
                                <li>
                                    <div  className='hasSubMenu'>
                                        <a onClick={e => this.handleNoOption(e)}>Personal</a>
                                        <SvgIcon component={ArrowDropDownIcon} viewBox="0 0 24 24" style={{color:"white"}} />
                                    </div>
                                    <ul className="submenu">
                                        <li><a onClick={()=>this.handleClick(`personal`)}>Listado</a></li>
                                        <li><a onClick={()=>this.handleClick(`personal/documents`)}>Documentación</a></li>
                                    </ul>
                                </li>
                            </React.Fragment>
                        : 
                            null
                        }

                        {/* TODO:OS permissions */}
                        {/* https://app.asana.com/0/1205937976678584/1206024460381949/f */}
                        {me && [20,50,100].includes(GetRole(me, Number(this.props.match.params.centrotrabajoId))) ? 
                            <React.Fragment>
                                <li>
                                    <div  className='hasSubMenu'>
                                        <a onClick={e => this.handleNoOption(e)}>Informes</a>
                                        <SvgIcon component={ArrowDropDownIcon} viewBox="0 0 24 24" style={{color:"white"}} />
                                    </div>
                                    <ul className="submenu">
                                        <li><a onClick={()=>this.handleClick(`reports/visits`)}>Visitas</a></li>
                                        <li><a onClick={()=>this.handleClick(`reports/audits`)}>Auditorías</a></li>
                                    </ul>
                                </li>
                            </React.Fragment>
                        : 
                            null
                        }
                        {me && [20,50,100].includes(GetRole(me, Number(this.props.match.params.centrotrabajoId))) ? 
                            <React.Fragment>
                                <li>
                                    <div className='hasSubMenu'>
                                        <a onClick={e => this.handleNoOption(e)}>Configuración</a>
                                        <SvgIcon component={ArrowDropDownIcon} viewBox="0 0 24 24" style={{color:"white"}} />
                                    </div>
                                    <ul className="submenu">
                                        {me && [50,100].includes(GetRole(me, Number(this.props.match.params.centrotrabajoId))) ? 
                                            <li><a onClick={e => this.handleClick(`setting/users`)}>{`Usuarios`}</a></li>
                                        :
                                            null
                                        }
                                        {me && [50,100].includes(GetRole(me, Number(this.props.match.params.centrotrabajoId))) ? 
                                            <li><a onClick={e => this.handleClick(`setting/sections`)}>{`Secciones`}</a></li>
                                        :
                                            null
                                        }
                                        {me && [20,50,100].includes(GetRole(me, Number(this.props.match.params.centrotrabajoId))) ? 
                                            <li><a onClick={e => this.handleClick(`setting/jobplaces`)}>{`Puestos trabajo`}</a></li>
                                        :
                                            null
                                        }
                                    </ul>
                                </li>
                            </React.Fragment>
                        : 
                            null
                        }
                    </ul>
                </nav>
            </div>
        );
    }
}
 
export default LateralMenu;