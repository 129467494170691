import React, { Component } from 'react';
import { createStyles, withStyles, Theme } from '@material-ui/core/styles';
import Layout from './Layout';
import Empresa from './Empresa';
import Button from '@material-ui/core/Button';
import Add from '@material-ui/icons/Add';
import PaginatedTable from './Material/PaginatedControlPanelPersonalDoc';
import {filter, includes, cloneDeep, groupBy, findIndex, orderBy} from 'lodash';
import OrderService from '../ApiClients/OrderService';
import { SelectorField, CheckBoxField, TextFieldInput } from './FormsWidgets';
import Toolbar from '@material-ui/core/Toolbar';
import {GetRole, GetEmpresa, OpenExternalFile} from '../helpers';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FilterListIcon from '@material-ui/icons/FilterList';
import IconButton from '@material-ui/core/IconButton';
import ClearAllIcon from '@material-ui/icons/LayersClearOutlined';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Loader from './Loader';
import DatePicker from './DatePicker';
import { IMe, IIPersonal, ICentroTrabajo, IFilter, IOptionRow } from '../types';
import LoaderBeauty from './Material/activityProgress';
import CreateModal from './CreateDialog';
import LateralMenu from './LateralMenu';
import SearchField from './SearchField';
import { GetDatetimeFormat } from '../vc_general';

const listadoStyles = ({
    button: {
        margin: 1,
        marginRight:15
    },
      leftIcon: {
        marginRight: 10,
    },
      rightIcon: {
        marginLeft: 1,
    },
      iconSmall: {
        fontSize: 20,
    },
    formControl: {
        margin: '1px',
        minWidth: 120,
        marginRight: 'auto',
    },
});

const fields = [
    {
        id: 'doc_type',
        name: 'Tipo documento',
        required: false,
        style:1,
    },
    {
        id: 'status',
        name: 'Estado',
        required: false,
        style:1,
    },
    {
        id: 'otp_required',
        name: 'OTP',
        required: false,
        style:1,
    },
];

interface IListadoProps {
    history:any;
    match:any;
    me?: IMe;
    version: string;
    filters: IFilter;
    handleChangeFilter: Function;
    handleCheckFilter: Function;
}

interface IListadoState {
    rowSelected: number;
    registerSelected?:IIPersonal;
    creationModalOpen:boolean;
    creationItemOpen: boolean;
    creationModalOpenEmail:boolean;
    modalAction:number;
    modalText:any;
    modalType:number;
    htmlBody:boolean;
    sendingEmail:boolean;
    emailResult:number;
    currentYearSelected: number|string;
    loading:boolean;
    loadingDocuments:boolean;
    documents:Array<IIPersonal>;
    filters:any;
    options?:any;
    searchValue:string;
    creatingItem:boolean;
    loadingModal: boolean;
    titleModal: string;
    filter_id: string;
    modalClassName:string;
}

class Listado extends Component<IListadoProps, IListadoState> {
    constructor(props: IListadoProps) {
        super(props);
        this.state = {
            rowSelected: -1,
            //dataIncome: dataIncome,
            documents:[],
            creationModalOpen:false,
            creationItemOpen:false,
            creationModalOpenEmail:false,
            modalType: -1,
            modalAction: -1,
            modalText:'',
            sendingEmail:false,
            emailResult:-1,
            htmlBody:false,
            currentYearSelected: new Date().getFullYear(),
            loading: true,
            loadingDocuments: true,
            filters:{},
            searchValue: '',
            creatingItem: false,
            loadingModal: false,
            titleModal: `MENSAJE`,
            filter_id: "f_personal_doc",
            modalClassName: 'dialog-little'
        };
    }

    componentDidMount(){
        this.reviewPage();
        this.loadControlPanel();
    }

    componentDidUpdate(){
        this.reviewPage();
    }

    reviewPage = () => {
        this.props.handleCheckFilter(this.props.match.params.centrotrabajoId);
    }

    loadControlPanel = async () => {
        const centrotrabajoId = Number(this.props.match.params.centrotrabajoId);
        let response = await OrderService.LoadPanelPersonalDoc(centrotrabajoId);
        this.setState({loadingDocuments: false, documents: response?response:[]}); // Controlar el error en un futuro
    }

    cancelRegister = async (data:IIPersonal, ispasive: boolean) => {
        let response = await OrderService.oTP3gSignActions(data);

        if(ispasive)
            return;

        if(response == 1){
            this.setState({
                modalAction: -1,
                modalText: `Registro cancelado correctamente.`,
                modalType: 1,
                creationModalOpen: true,
                modalClassName:'dialog-little',
                registerSelected: undefined,
                loadingModal: false,
            }, ()=>this.loadControlPanel());
        } else {
            this.setState({
                modalAction: -1,
                modalText: `¡ATENCIÓN! No se ha podido cancelar el registro, vuelva a intentarlo.`,
                modalType: 1,
                creationModalOpen: true,
                modalClassName:'dialog-little',
                loadingModal: false
            });
        }
    }

    deleteRegister = async (data:IIPersonal) => {
        let response = await OrderService.saveData(data, 9);

        //** If use OTP method do that action */
        if(data.otp_required)
            await this.cancelRegister(data, true);
        
        if(response == 1){
            let t_documents = cloneDeep(this.state.documents);
            const idx = findIndex(t_documents, function(o){return o.id == data.id});
            
            if(idx > - 1)
                t_documents.splice(idx, 1);

            this.setState({
                modalAction: -1,
                modalText: `Registro eliminado correctamente.`,
                modalType: 1,
                creationModalOpen: true,
                modalClassName:'dialog-little',
                registerSelected: undefined,
                loadingModal: false,
                documents: t_documents
            });
        } else {
            this.setState({
                modalAction: -1,
                modalText: `¡ATENCIÓN! No se ha podido eliminar el registro, vuelva a intentarlo.`,
                modalType: 1,
                creationModalOpen: true,
                modalClassName:'dialog-little',
                loadingModal: false
            });
        }
    }

    handleFilterDocuments=(documents:any, f:any)=>{
        let newDocuments = cloneDeep(documents);

        //** FILTRA POR LOS FILTROS APLICADOS */
        const keys = Object.keys(f);
        const values = Object.values(f);

        if(keys.length > 0){
            for(let a=0; a<keys.length;a++){
                newDocuments = filter(newDocuments, function(o){return o[keys[a]] == values[a]})
            }
        }

        //** FILTRA POR EL VALOR DE BÚSQUEDA */
        const searchValue = this.state.searchValue.toLowerCase();

        if(newDocuments.length > 0 && searchValue && searchValue.length > 0){
            newDocuments = filter(newDocuments, function(o:IIPersonal){return includes(o.p_id.toLowerCase(), searchValue) || includes(o.p_name.toLowerCase() + ' ' + o.p_surname.toLowerCase(), searchValue) || includes(o.d_name.toLowerCase(), searchValue) });
        }

        return newDocuments;
    }

    handleSearchChange=(evt:any)=>{
        this.setState({searchValue:evt.target.value})
    }

    handleClearSearchValue=()=>{
        this.setState({searchValue:''})
    }

    handleCleanFilters = (evt:any)=>{
        evt.stopPropagation();
        this.props.handleChangeFilter(this.state.filter_id, {}, false);
    }

    onCloseCreationModal = () => {
        this.setState({ creationModalOpen: false, modalText: undefined, htmlBody:false, });
    }

    onCloseFormItemModal = () => {
        this.setState({ creationItemOpen: false });
    }

    /* CALL BACKEND */


    /* */

    handleCreateModal = (typeAction: number) => {
        const {registerSelected} = this.state;

        switch(typeAction){
            case 1: // Implica eliminar un registro
                if(!registerSelected)
                    return;
                this.setState({
                    loadingModal: true,

                }, ()=> this.deleteRegister(registerSelected))
                break;
            case 2: // Implica cancelar documento
                if(!registerSelected)
                    return;
                this.setState({
                    loadingModal: true,

                }, ()=> this.cancelRegister(registerSelected, false))
                break;
        }
        //this.onCloseCreationModal();
    }

    onCloseCreationModalEmail = () => {
        this.setState({ creationModalOpenEmail: false });
    }

    handleItemClick=(item:any)=>{
        this.props.history.push(`${window.location.hash.replace('#','')}/${item.id}`)
    }

    handleChangeFilter=(id:string, evt:any)=>{
        let newFilters = cloneDeep(this.props.filters.f_personal_doc);
        newFilters[id] = evt.target.value;
        this.props.handleChangeFilter(this.state.filter_id, newFilters, false);
    }

    RenderFilters = (documents:Array<IIPersonal>, arrayfilters:any) => {
        const grouped_docType = groupBy(documents, function(register) {
            return register.doc_type;
        });

        const merged_dname = orderBy(Object.keys(grouped_docType),['asc']).map((key:string, idx:number)=>{
            return {
                id: key,
                name: `${key} - ${grouped_docType[key][0].doc_type_dsc}`
            }
        });

        const grouped_status = groupBy(documents, function(register) {
            return register.status;
        });

        const merged_status = orderBy(Object.keys(grouped_status),['asc']).map((key:string, idx:number)=>{
            return {
                id: key,
                name: `${key} - ${grouped_status[key][0].statusDsc}`
            }
        });

        const data:any = {
            doc_type: merged_dname,
            status: merged_status,
            otp_required: [{active:true, id:true, name:'Si'},{active:true, id:false, name:'No'}]
        }
        
        let filters = fields.map((filter:any, index:number)=>{
            return (
                <SelectorField
                    key={index}
                    name={filter.name}
                    id={filter.id}
                    deviceData={arrayfilters}
                    fieldsData={data[filter.id]?data[filter.id]:[]}
                    handleFieldChange={this.handleChangeFilter}
                    required={filter.required}
                    style={filter.style}
                    className="filter-item-200px"
                />
            );
        })

        return filters;
    }

    handleClickOptionRow=(register:IIPersonal, option:number)=>{
        switch(option){
            case 1: // Implica ver el registro
                OpenExternalFile(register.d_storage)
                break;
            case 2: // Implica eliminar
                this.setState({
                    modalAction: 1,
                    modalText: `¡ATENCIÓN! ¿Quiere eliminar el tipo de registro "${register.doc_type_dsc}" del trabajador "${register.p_id}" con fecha "${GetDatetimeFormat(register.d_dt_created)}"?`,
                    modalType: 2,
                    creationModalOpen: true,
                    modalClassName:'dialog-little',
                    registerSelected: register
                });
                break;
            case 3: // Implica cancelar firma documento
                this.setState({
                    modalAction: 2,
                    modalText: `¡ATENCIÓN! ¿Quiere cancelar la firma del tipo de registro "${register.doc_type_dsc}" del trabajador "${register.p_id}" con fecha "${GetDatetimeFormat(register.d_dt_created)}"?`,
                    modalType: 2,
                    creationModalOpen: true,
                    modalClassName:'dialog-little',
                    registerSelected: register
                });
                break;
            default:
                break;
        }
    }

    render() {

        const {loadingDocuments, options, filter_id, documents, searchValue, creatingItem} = this.state;
        const {me, filters} = this.props;
        const empresa:ICentroTrabajo|undefined = GetEmpresa(this.props.match.params.centrotrabajoId, me);

        const optionsRow: IOptionRow[] = [
            { id: 1, numeric: true, disablePadding: false, label: 'Ver', enabled: true },
            { id: 3, numeric: true, disablePadding: false, label: 'Cancelar', enabled: true },
            { id: 2, numeric: true, disablePadding: false, label: 'Eliminar', enabled: (me && [50,100].includes(GetRole(me, this.props.match.params.centrotrabajoId))?true:false) },
        ];

        let title = (
            <div className='App-title-webPage'>
                {`Documentación`}
                {'  '}
                {/*<Loader loading={this.state.loading} />*/}
            </div>
        );

        const apply_filters: any = filters[filter_id];

        return (
            <div className='App-basic'>
                <Layout 
                    history={this.props.history} 
                    currentPage={1}
                    allowDrawer={true}
                    match={this.props.match}
                    version={this.props.version}
                    me={me}
                    showEmpresa
                    showLateralMenu
                />
                <div className='App-body-webBar'>
                    {me?
                        [5,10,11,20,50,100].indexOf(GetRole(me, this.props.match.params.centrotrabajoId)) == -1?
                            <div>
                                <div className="App-header">
                                    {title}
                                </div>
                                <label style={{ marginLeft:10 }}>
                                    {`No tiene permisos para visualizar este espacio.`}
                                </label>
                            </div>
                        :
                            <div>
                                <div className="App-header">
                                    {title}
                                    <div className="App-header-options">
                                        <SearchField placeholder="Buscar..." value={searchValue} allowSearch={true} onValueChange={this.handleSearchChange} handleClearSearchValue={this.handleClearSearchValue}/>
                                    </div>
                                </div>
                                <div>
                                {loadingDocuments?
                                    null
                                :
                                    <div className='filter-list'>                                                        
                                        <Toolbar variant="dense" className='filter-list-toolbar'>
                                            {this.RenderFilters(documents, apply_filters)}
                                        </Toolbar>
                                        <div className='clear-filter'>
                                            {Object.keys(apply_filters).length == 0?
                                                <IconButton aria-label="filter list">
                                                    <FilterListIcon />
                                                </IconButton>
                                            :
                                                <IconButton onClick={(evt:any)=>this.handleCleanFilters(evt)} aria-label="filter list">
                                                    <ClearAllIcon />
                                                </IconButton>
                                            }
                                        </div>                                        
                                    </div>                                      
                                }   
                                </div>
                                <div>
                                    <PaginatedTable
                                        data={this.handleFilterDocuments(documents, apply_filters)}
                                        handleSearch={this.handleSearchChange}
                                        searchValue={searchValue}
                                        onClearSearchValue={this.handleClearSearchValue}
                                        handleItemClick={this.handleItemClick}
                                        handleClickOptionRow={this.handleClickOptionRow}
                                        optionsRow={optionsRow}
                                    />
                                </div>
                                {this.state.creationModalOpen?
                                    <CreateModal
                                        onCloseCreationModal={this.onCloseCreationModal}
                                        isOpen={this.state.creationModalOpen}
                                        inputText={this.state.modalText}
                                        typeModal={this.state.modalType}
                                        action={this.handleCreateModal}
                                        typeAction={this.state.modalAction}
                                        loadingModal={this.state.loadingModal}
                                        titleModal={this.state.titleModal}
                                        className={this.state.modalClassName}
                                    />
                                :
                                    null
                                }
                            </div>
                    :
                        this.state.loading?
                            <div style={{marginLeft:20}}>
                                <Loader loading={true}/>
                            </div>
                        :
                            `Fallo en la carga del perfil, volver a intentar`
                    }
                    
                    <LoaderBeauty visible={loadingDocuments?true:false}/>
                </div> 
            </div>
        );
    }
}

export default Listado;