import React, { Component } from 'react';
import Layout from './Layout';
import Empresa from './Empresa';
import { createStyles, withStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Add from '@material-ui/icons/Add';
import OrderService from '../ApiClients/OrderService';
import Loader from './Loader';
import DatePicker from './DatePicker';
import { IMe, IIIRegister, IVRegister, IAttach, ICentroTrabajo } from '../types';
import { SelectorField, CheckBoxField, TextFieldInput } from './FormsWidgets';
import {GetRole, GetEmpresa, OpenExternalFile} from '../helpers';
import TakeIcon from '@material-ui/icons/CheckCircleOutline';
import {cloneDeep, filter, find, findIndex} from 'lodash';
import CreateModal from './CreateDialog';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddActionIcon from '@material-ui/icons/AddBox';
import IconButton from '@material-ui/core/IconButton';
import LoaderBeauty from './Material/activityProgress';
import PaginatedTableLines from './Material/PaginatedTableListadoLines';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import PageViewIcon from '@material-ui/icons/Pageview';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import { GetFormattedDate, GetFormattedDateTime, GetDatetimeFormat } from '../vc_general';
import Camera from './Material/camera';
import Form_EPIs_01 from './Forms/EPIs_01_personal';
import Form_EPIs_02 from './Forms/EPIs_02_seccion';
import Form_BTQ_01 from './Forms/BTQ_01';
import Form_OL_01 from './Forms/OL_01';
import Form_EPCI_01 from './Forms/EPCI_01_seccion';
import Form_APQ_01 from './Forms/APQ_01';
import Form_APQ_02 from './Forms/APQ_02';
import Form_DL_01 from './Forms/DL_01';
import Form_EST_01 from './Forms/EST_01';
import Form_ESC_01 from './Forms/ESC_01';
import Form_II_ICP_01 from './Forms/II_ICP_01';
import FormCorrectivo from './FormCorrectivo';
import { Checkbox, FormControlLabel } from '@mui/material';


const listadoStyles = ({
    button: {
        margin: 1,
        marginRight:15
    },
      leftIcon: {
        marginRight: 10,
    },
      rightIcon: {
        marginLeft: 1,
    },
      iconSmall: {
        fontSize: 20,
    },
    formControl: {
        margin: '1px',
        minWidth: 120,
        marginRight: 'auto',
    },
});

const fieldsFormListado = [
    [
        /*
        {
            id: 'id',
            name: 'ID',
            required: true,
            style:1,
            typeField:2,
            group: []
        },
        */
        {
            id: 'origen',
            name: 'Orígen',
            required: true,
            style:1,
            typeField:1,
            group: [20, 50, 100],
            tipo: [1, 2]
        },
        {
            id: 'tipoMedida',
            name: 'Tipo medida',
            required: false,
            style:1,
            typeField:1,
            group: [20, 50, 100],
            tipo: [1, 2]
        },
        {
            id: 'seccion',
            name: 'Sección',
            required: true,
            style:1,
            typeField:1,
            group: [20, 50, 100],
            tipo: [1, 2]
        },
        {
            id: 'puestoTrabajo',
            name: 'Puesto de trabajo',
            required: true,
            style:1,
            typeField:1,
            group: [20, 50, 100],
            tipo: [1, 2]
        },
    ],
    [
        {
            id: 'responsable',
            name: 'Responsable',
            required: false,
            style:1,
            helper:'',
            typeField:1,
            group: [20, 50, 100],
            tipo: [1, 2]
        },
        {
            id: 'prioridad',
            name: 'Prioridad',
            required: true,
            style:1,
            typeField:1,
            group: [20, 50, 100],
            tipo: [1, 2]
        },        
        {
            id: 'periodicidad',
            name: 'Periodicidad',
            required: false,
            style:1,
            helper:'',
            typeField:1,
            group: [],
            tipo: [1, 2]
        },
        {
            id: 'nonCompliant',
            name: 'No Conformidad',
            required: false,
            style: 1, 
            typeField:5,
            group: [20, 50, 100],
            tipo: [2]
        },
    ],
    [
        {
            id: 'dt_created',
            name: 'Fecha creación',
            required: true,
            style:1,
            typeField:4,
            group: [50, 100],
            tipo: [1, 2]
        },
        {
            id: 'fechaPrevista',
            name: 'Fecha prevista inicio',
            required: true,
            style:1,
            typeField:3,
            group: [20, 50, 100],
            tipo: [1, 2]
        },
        {
            id: 'fechaPrevistaEnd',
            name: 'Fecha prevista fin',
            required: true,
            style:1,
            typeField:3,
            group: [20, 50, 100],
            tipo: [2]
        },
    ],
    [    
        {
            id: 'referencia',
            name: 'Riesgo',
            required: true,
            style:3,
            typeField:1,
            group: [20, 50, 100],
            tipo: [1, 2]
        },
        {
            id: 'detalleRiesgo',
            name: 'Detalle riesgo',
            required: false,
            style:3,
            helper:'',
            maxText:4000,
            typeField:2,
            multiline:true,
            group: [20, 50, 100],
            tipo: [1, 2]
        }
    ],
    [
        {
            id: 'medidas',
            name: 'Medidas',
            required: true,
            style:3,
            helper:'',
            maxText:30000,
            typeField:2,
            multiline:true,
            group: [20, 50, 100],
            tipo: [1, 2]
        },        
    ],
    [
        {
            id: 'comentario',
            name: 'Comentarios',
            required: false,
            style:3,
            helper:'',
            maxText:1000,
            typeField:2,
            multiline:true,
            group: [10, 20, 50, 100],
            tipo: [1, 2]
        },
    ],
    [
        
        {
            id: 'coste',
            name: 'Coste',
            required: false,
            style:2,
            helper:'',
            maxText:100,
            typeField:2,
            multiline:false,
            group: [10, 20, 50, 100],
            tipo: [1, 2]
        },
        
        /*{
            id: 'estado',
            name: 'Estado',
            required: true,
            style:1,
            typeField:1,
            group: [10, 20, 50, 100],
            filter: [10,20]
        },*/
        {
            id: 'fechaCierre',
            name: 'Fecha realización',
            required: true,
            style:1,
            typeField:3,
            group: [10, 20, 50, 100],
            tipo: [1, 2]
        }
    ],
    [
        
        {
            id: 'last_userId',
            name: 'Último usuario',
            required: false,
            style:3,
            helper:'',
            maxText:250,
            typeField:2,
            multiline:false,
            group: [],
            tipo: [1, 2]
        },
    ]
];

const fieldsFormListadoItemLine = [
    [
        {
            id: 'id',
            name: 'ID',
            required: true,
            style:1,
            typeField:2,
            group: []
        },
    ],
    [
        {
            id: 'comentario',
            name: 'Comentarios',
            required: false,
            style:3,
            helper:'',
            maxText:1000,
            typeField:2,
            multiline:true,
            group: [10, 20, 50, 100]
        }
    ],
    [
        {
            id: 'fechaCierre',
            name: 'Fecha realización',
            required: true,
            style:1,
            typeField:3,
            group: [10, 20, 50, 100]
        }
    ]
];

const enumerationForms = [
    {id:6,dsc:"APQ_01 - recipientes móviles"},
    {id:7,dsc:"APQ_02 - recipientes fijos"},
    {id:3,dsc:"Botiquines - Sección"},
    {id:8,dsc:"Duchas y lavaojos - Sección"},
    {id:5,dsc:"EPCI - Sección"},
    {id:1,dsc:"EPIs - Personal"},
    {id:2,dsc:"EPIs - Sección"},
    {id:10,dsc:"Escaleras portátiles - Sección"},
    {id:9,dsc:"Estanterías - Sección"},
    {id:11,dsc:"Inspecciones Internas ICP_Check list"},
    {id:4,dsc:"Orden y Limpieza - Sección"},
]

/*function controlCamera(type:any){
    switch(type){
       case 1:
        camera.startCamera();
        break;
      case 2:
        var img = camera.takeSnapshot();
        var suh : any = document.getElementById("holita");
        if(suh == null)
          return;
        suh.src = img;
        console.log(img)
        break;
      case 3:
        camera.closeCamera();
        break;
    }
}*/

interface OptionRow {
    disablePadding: boolean;
    id: number;
    label: string;
    numeric: boolean;
    isfileuploader: boolean;
    roleAllowed: boolean;
    readonly: boolean;
    ignorestate: boolean;
    parentState: number;
}

interface IFormButtonProps {
    id:number;
    dsc:string;
    goToForm:Function;
}

interface IFormButtonState {
}

class FormButtonInternal extends Component<IFormButtonProps, IFormButtonState> {
    constructor(props: IFormButtonProps) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <div key={this.props.id} className='form-button' onClick={() => this.props.goToForm(this.props.id)}>
                <div className='form-button-text'>
                    {this.props.dsc}
                </div>
            </div>
        );
    }
}
const FormButton = withStyles(listadoStyles)(FormButtonInternal);

interface IFormListadoItemLineProps {
    handleCreateItem:Function;
    handleCloseItemForm: Function;
    loading:boolean;
    register: IVRegister;
    me:IMe;
    centrotrabajoId:number;
    parentRegister?: IIIRegister;
}

interface IFormListadoItemLineState {
    itemListado:any;
}

class FormListadoItemLine extends Component<IFormListadoItemLineProps, IFormListadoItemLineState> {
    constructor(props: IFormListadoItemLineProps) {
        super(props);
        this.state = {
            itemListado: props.register,
        };
    }

    handleChangeUser=(id:any, event:any)=>{
        let newItemListado = Object.assign({}, this.state.itemListado);
        newItemListado[id] = event.target.value;
        this.setState({ itemListado: newItemListado });
    }    
    
    shouldDisableToday = (date:any) => {
        return false;
    }

    handleChangeDate=(id:any, event:any)=>{
        let newItemListado = Object.assign({}, this.state.itemListado);
        newItemListado[id] = event;
        this.setState({ itemListado: newItemListado });
    }

    handleResetItemForm = () => {
        this.setState({itemListado:undefined});
    }

    render() {
        const {itemListado} = this.state;
        const {me, centrotrabajoId, parentRegister} = this.props;

        const myRole = GetRole(me, centrotrabajoId);

        let fieldsTMP = fieldsFormListadoItemLine.map((n0:any, index:number)=>{
            let fieldsN0 = n0.map((n:any, index:number)=>{
                switch(n.typeField){
                    case 2:
                        return(
                            <TextFieldInput
                                key={index}
                                name={n.name}
                                id={n.id}
                                fieldsData={itemListado}
                                handleFieldChange={this.handleChangeUser}
                                disabled={(parentRegister && [10,20].indexOf(parentRegister.estado) > -1 && itemListado.estado == 10)?(n.group.indexOf(myRole) == -1?true:false):true}
                                size="medium"
                                shrink={true}
                                //disabled={n.id=="id"?!this.props.newProduct:this.state.editProduct}
                                //disabled={n.id=="id"?!this.props.newProduct:this.state.editProduct}
                                type={n.type}
                                required={n.required}
                                style={n.style}
                                helper={n.helper}
                                maxText={n.maxText}
                                //noError={n.required?this.verifyForm(0,n.id):true}
                                noError={true}
                                multiline={n.multiline}
                            />
                        )
                    case 3:
                        return(
                            <div>{
                                DatePicker(
                                    n.id,
                                    n.name,
                                    "dd/MM/yyyy",
                                    this.handleChangeDate,
                                    itemListado[n.id],
                                    (parentRegister && [10,20].indexOf(parentRegister.estado) > -1 && itemListado.estado == 10)?(n.group.indexOf(myRole) == -1?true:false):true,
                                    this.shouldDisableToday,
                                    new Date('1970-01-01')
                                )
                            }</div>
                            
                        );
                    default:
                        return('')
                }
            })
            return (
                <form key={index} noValidate autoComplete="off">
                    {fieldsN0}
                </form>
            )
        })

        return (
            <Dialog fullScreen={false} open={true} aria-labelledby="form-dialog-title" className='dialog-little'>
                <DialogTitle id="form-dialog-title">{'FORMULARIO ACCIÓN'}</DialogTitle>
                <DialogContent>
                    {fieldsTMP}
                </DialogContent>
                <DialogActions>
                    {!this.props.loading?
                        <div style={{ display:'flex', gap:20 }}>
                            <Button onClick={()=>this.props.handleCloseItemForm()} className='cancel-dialog'>
                                {'Cancelar'}
                            </Button>
                            <Button disabled={(parentRegister && [10,20].indexOf(parentRegister.estado) > -1 && itemListado.estado == 10)?false:true} onClick={()=>this.props.handleCreateItem(this.state.itemListado)} className={`confirm-dialog ${(parentRegister && [10,20].indexOf(parentRegister.estado) > -1 && itemListado.estado == 10)?'':'confirm-dialog-disable'}`}>
                                {`Guardar`}
                            </Button>
                        </div>
                    :
                        <Loader loading={true}/>
                    }
                </DialogActions>
            </Dialog>
        );
    }
}
const FormListadoLine = withStyles(listadoStyles)(FormListadoItemLine);

interface IListadoProps {
    history:any;
    match:any;
    me?:IMe;
    version: string;
}

interface IListadoState {
    rowSelected: number;
    creationModalOpen:boolean;
    creationModalOpenEmail:boolean;
    modalAction:number;
    modalText:any;
    modalType:number;
    titleModal: string;
    fullscreenmodal?:boolean;
    maxwdidthmodal?:string;
    htmlBody:boolean;
    loading:boolean;
    loadingItem:boolean;
    loadingOptions:boolean;
    loadingItemLines: boolean;
    editing: boolean;
    saving: boolean;
    options?:any;
    register?:IIIRegister;
    registerLines?:Array<IVRegister>;
    sitemline?:IVRegister;
    creationItemLineOpen:boolean;
    bRegisterLine?:IVRegister;
    going2attach?:any;
    going2deleteattach?:IAttach;
    loadingModal: boolean;
    creationCameraOpen:boolean;
    creationItemOpen: boolean;
    creatingItem:boolean;
    numform:number;

    modalClassName:string;
}

class ListadoDetail extends Component<IListadoProps, IListadoState> {
    _going2upload:any = {attachments:[]};
    constructor(props: IListadoProps) {
        super(props);
        this.state = {
            rowSelected: -1,
            creationModalOpen:false,
            creationModalOpenEmail:false,
            modalType: -1,
            modalAction: -1,
            modalText:'',
            htmlBody:false,
            loading: true,
            loadingItem: true,
            loadingOptions: true,
            editing: false,
            saving: false,
            titleModal: `MENSAJE`,
            loadingItemLines: true,
            creationItemLineOpen: false,
            loadingModal: false,
            creationCameraOpen:false,
            creatingItem:false,
            creationItemOpen:false,
            numform:-1,
            modalClassName:'dialog-little',
        };
    }

    componentDidMount(){
        this.reviewPage();

        //[20,50,100].indexOf(GetRole(me, this.props.match.params.centrotrabajoId)) == -1
        this.loadData();
    }

    componentDidUpdate(prevProps:IListadoProps){
        this.reviewPage();
        if(prevProps.me !== this.props.me)
            this.loadData();
    }

    loadData = () => {
        const {me} = this.props;

        if(!me)
            return;
        if ([5,10,20,50,100].indexOf(GetRole(me, this.props.match.params.centrotrabajoId)) > -1) {
            this.loadDocument();
            this.loadDocumentLines();
            this.loadOptions();
        } else {
            this.setState({
                loading:false,
                loadingOptions: false,
                loadingItem: false,
                loadingItemLines: false
            })
        }
    }
    loadOptions = async () => {
        let response = await OrderService.LoadOptions(this.props.match.params.centrotrabajoId);
        this.setState({loadingOptions: false, options: response});
    }

    loadDocument = async () => {
        const centrotrabajoId = Number(this.props.match.params.centrotrabajoId);
        const tipo = Number(this.props.match.params.tipo);
        const id = Number(this.props.match.params.id);

        let response = await OrderService.LoadListadoItem(centrotrabajoId, tipo, id);
        this.setState({loadingItem: false, register: response});
    }

    loadDocumentLines = async () => {
        const centrotrabajoId = Number(this.props.match.params.centrotrabajoId);
        const tipo = Number(this.props.match.params.tipo);
        const listadoId = Number(this.props.match.params.id);

        let response = await OrderService.LoadListadoItemLines(centrotrabajoId, tipo, listadoId);
        this.setState({loadingItemLines: false, registerLines: response});
    }

    saveData=async(item:IIIRegister, action:number)=>{
        let response:any = await OrderService.saveData(item, action);

        switch(action){
            case 2:
                this.setState({
                    creatingItem:false,
                    creationItemOpen:false,
                    modalText: `Correctivo generado correctamente.`,
                    modalType: 1,
                    creationModalOpen: true,
                    modalClassName:'dialog-little',
                });
                return;
            default:
                this.setState({editing: false, saving: false, loadingItem: true}, ()=>this.loadDocument());
        }
    }

    saveDataLines=async(item:IVRegister, action:number)=>{
        this.setState({loadingItemLines: true});
        let response:any = await OrderService.saveData(item, action);
        this.loadDocumentLines();
    }

    saveDataLinesAttach=async(item:IAttach, action:number)=>{
        let response:any = await OrderService.saveData(item, action);
        this.setState({loadingModal:false, loadingItemLines: true, modalText:`Archivo eliminado correctamente`, modalType: 1, modalAction: -1}, ()=>this.loadDocumentLines());
    }

    sendAttachFiles = async () => {
        let response = await OrderService.createImgBlob(this._going2upload, 6);
        this._going2upload = {attachments:[]};
        let p = `Fallo en el proceso, vuelva a intentarlo.`;
        if(response == 1) {
            p = `Archivo/s adjuntado/s correctamente`;
        }
        
        this.setState({
            loadingModal:false,
            loadingItemLines: true,
            modalText:p,
            modalType: 1,
            modalAction: -1,
            creationCameraOpen: false
        },
            ()=>this.loadDocumentLines()
        );
    }

    /*closeOrden=async(item:IIIRegister)=>{
        this.setState({loadingItemLines: true});
        let response:any = await OrderService.saveData(item, 3);
        this.setState({loadingItemLines: false});
        this.loadDocument();
    }*/

    reviewPage=()=>{
        if([1,2].indexOf(Number(this.props.match.params.tipo)) == -1)
            this.props.history.push(`/`);
    }

    onCloseCreationModal = () => {
        this.setState({ creationModalOpen: false, modalText: undefined, htmlBody:false, fullscreenmodal: undefined, maxwdidthmodal: undefined, titleModal: 'MENSAJE' });
    }

    onCloseCameraModal = () => {
        this.setState({ creationCameraOpen: false });
    }

    onCloseFormItemLineModal = () => {
        this.setState({ creationItemLineOpen: false });
    }

    onCloseFormItemModal = () => {
        this.setState({ creationItemOpen: false });
    }

    onCloseForm = (generated:boolean) => {
        if(generated)
            this.setState({numform:-1},() => {
                this.loadDocumentLines();
                this.onCloseCreationModal();
            });
        else
            this.setState({numform:-1});
    }
    /* CALL BACKEND */


    /* */

    handleCreateModal = (typeAction: number) => {
        switch(typeAction){
            case 1: // Implica cerrar la orden de trabajo
                const nregister = cloneDeep(this.state.register);
                if(nregister){
                    nregister.estado = 50;
                    this.saveData(nregister, 3);
                }
                this.onCloseCreationModal();
                break;
            case 2: // Implica generar una línea en la orden de trabajo
                const centrotrabajoId = Number(this.props.match.params.centrotrabajoId);
                const tipo = Number(this.props.match.params.tipo);
                const listadoId = Number(this.props.match.params.id);
                this.saveDataLines({centrotrabajoId:centrotrabajoId, tipo: tipo, listadoTableId: listadoId, id:-1, comentario:'', estado:10}, 4);
                this.onCloseCreationModal();
                break;
            case 3: // Implica adjuntar ficheros
                this.setState({loadingModal: true}, ()=>this.drawOnCanvas());
                break;
            case 4: // Implica eliminar un adjunto
                const {going2deleteattach} = this.state;
                if(going2deleteattach)
                    this.setState({loadingModal: true}, ()=>this.saveDataLinesAttach(going2deleteattach, 7));
                break;
            case 5: // Implica adjuntar una foto
                this.setState({loadingModal: true}, ()=>this.sendAttachFiles());
                break;
            case 6: // Cancelar la OT
                const nregister02 = cloneDeep(this.state.register);
                if(nregister02){
                    nregister02.estado = 98;
                    this.saveData(nregister02, 3);
                }
                this.onCloseCreationModal();
                break;
                case 7: // Eliminar la OT
            const nregister03 = cloneDeep(this.state.register);
                if(nregister03){
                    nregister03.estado = 99;
                    this.saveData(nregister03, 3);
                }
                this.onCloseCreationModal();
                break;
        }
    }

    shouldDisableToday = (date:any) => {
        return false;
    }

    handleChangeDate=(id:any, event:any)=>{
        let nRegister = Object.assign({}, this.state.register);
        nRegister[id] = event;
        this.setState({ register: nRegister, editing: true });
    }

    handleCloseOrden=(item:IIIRegister, lines:Array<IVRegister>)=>{
        const opennedline = find(lines, function(o){return o.estado == 10});
        if(item.fechaCierre == null){
            this.setState({
                modalAction: -1,
                modalText: `¡ATENCIÓN! No puede cerrar una orden de trabajo sin indicar la fecha de realización.`,
                modalType: 1,
                creationModalOpen: true,
                modalClassName:'dialog-little',
            });
        } else if(lines.length == 0){
            this.setState({
                modalAction: -1,
                modalText: `¡ATENCIÓN! No puede cerrar una orden de trabajo sin realizar ninguna acción.`,
                modalType: 1,
                creationModalOpen: true,
                modalClassName:'dialog-little',
            });
        } else if(!opennedline) {
            this.setState({
                modalAction: 1,
                modalText: `¡ATENCIÓN! ¿Quiere cerrar la orden de trabajo ${item.code}?`,
                modalType: 2,
                creationModalOpen: true,
                modalClassName:'dialog-little',
            });
        } else {
            this.setState({
                modalAction: -1,
                modalText: `¡ATENCIÓN! No puede cerrar una orden de trabajo con acciones pendientes de finalizar.`,
                modalType: 1,
                creationModalOpen: true,
                modalClassName:'dialog-little',
            });
        }
    }

    handleCancelOrden=(item:IIIRegister)=>{
        this.setState({
            modalAction: 6,
            modalText: `¡ATENCIÓN! ¿Quiere cancelar la orden de trabajo ${item.code}?`,
            modalType: 2,
            creationModalOpen: true,
            modalClassName:'dialog-little',
        });
    }

    handleDeleteOrden=(item:IIIRegister)=>{
        this.setState({
            modalAction: 7,
            modalText: `¡ATENCIÓN! ¿Quiere eliminar la orden de trabajo ${item.code}?`,
            modalType: 2,
            creationModalOpen: true,
            modalClassName:'dialog-little',
        });
    }

    handleChangeUser=(id:any, event:any)=>{
        let nRegister = Object.assign({}, this.state.register);
        nRegister[id] = event.target.value;
        this.setState({ register: nRegister, editing: true });
    }

    handleChangeCheckbox= (id:string, event:any) =>{        
        let newItemListado = Object.assign({}, this.state.register);   
        newItemListado[id] = event.target.checked;
        if(newItemListado[id])
            newItemListado["prioridad"] = 20;
        this.setState({ register: newItemListado, editing: true });        
    }

    handleSaveData = (register: IIIRegister) => {
        this.setState({saving: true}, ()=>this.saveData(register, 3));
    }

    handleSaveItemLine=(item:IVRegister)=>{
        this.setState({loadingItemLines:true}, ()=>this.saveDataLines(item, 5));
        this.onCloseFormItemLineModal();
    }

    handleAddAction=(item:any)=>{
        this.setState({
            modalAction: 2,
            modalText: `¡ATENCIÓN! ¿Quiere generar una nueva acción a la orden de trabajo ${item.code}?`,
            modalType: 2,
            creationModalOpen: true,
            modalClassName:'dialog-little'
        })
    }

    handleClickOptionRow=(line:IVRegister, option:number)=>{
        switch(option){
            case 1: // Implica editar la línea del registro
                this.setState({creationItemLineOpen:true, bRegisterLine: line})
                break;
            case 3: // Implica ver los archivos adjuntos
            
            this.setState({modalText: this.RenderItemLineAttachment(line, this.props.me), modalType:1, maxwdidthmodal:'sm', modalAction: -1, creationModalOpen: true, modalClassName:'dialog-little', titleModal:`ADJUNTOS ACCIÓN ${line.id}`})
                break;
            case 4: // Implica cerrar la línea de la orden de trabajo
                if(line.fechaCierre == null){
                    this.setState({
                        modalAction: -1,
                        modalText: `¡ATENCIÓN! No puede cerrar la acción ${line.id} sin indicar una fecha de realización.`,
                        modalType: 1,
                        creationModalOpen: true,
                        modalClassName:'dialog-little',
                    })
                } else if(line.comentario.length == 0) {
                    this.setState({
                        modalAction: -1,
                        modalText: `¡ATENCIÓN! No puede cerrar la acción ${line.id} sin indicar un comentario.`,
                        modalType: 1,
                        creationModalOpen: true,
                        modalClassName:'dialog-little',
                    })
                } else {
                    this.setState({loadingItemLines: true});
                    let nline = cloneDeep(line);
                    nline.estado = 50;
                    this.saveDataLines(nline, 5);
                }
                break;
            case 5: // Implica hacer una foto
                this.setState({creationCameraOpen: true, sitemline:line})
                break;
            case 6: // Implica rellenar un formulario
                /*const {me} = this.props;
                const {options} = this.state;
                if(!me || !options)
                    return;*/

                

                /*this.setState({
                    modalAction: -1,
                    modalText: <Form01 me={me} secciones = {options['seccion']}/>,
                    modalType: 1,
                    creationModalOpen: true,
                    maxwdidthmodal: "xl",
                    fullscreenmodal: true,
                    titleModal: 'FORMULARIO'
                });*/

                let forms = enumerationForms.map((form)=>{
                    return(
                        <FormButton
                            id={form.id}
                            dsc={form.dsc}
                            goToForm={(id:number)=>this.setState({numform:id})}
                        />
                    );
                });

                this.setState({
                    modalAction: -1,
                    modalText: forms,
                    modalType: 1,
                    creationModalOpen: true,
                    modalClassName:'dialog-little',
                    titleModal: 'FORMULARIOS',
                    bRegisterLine: line
                });
                
                break;
            default:
                break;
        }
    }

    handleAttachFiles=(evt:any, line:IVRegister)=>{
        const afiles = evt.target.files;

        if(afiles.length > 3) {
            this.setState({
                modalAction: -1,
                modalText: `¡ATENCIÓN! No se pueden adjuntar más de 3 ficheros a la vez y ha seleccionado ${afiles.length}.`,
                modalType: 1,
                creationModalOpen: true,
                modalClassName:'dialog-little'
            })
            return;
        }

        let lines = [];
        let nfiles = [];
        for (let index = 0; index < afiles.length; index++) {
            const element = afiles[index];
            nfiles.push(element);
            lines.push(<tr><td style={{borderRight:'2px solid #337ab7'}}>{`${element.name}`}</td><td>{`${(element.size/1048576).toFixed(2)} MB`}</td></tr>)
        }
        const items = <div><p>{`¿Quiere adjuntar los siguientes ficheros?`}</p><table><thead><tr><th>{`Nombre`}</th><th>{`Tamaño`}</th></tr></thead><tbody key={1}>{lines}</tbody></table></div>;

        this.setState({
            modalAction: 3,
            modalText: items,
            modalType: 2,
            creationModalOpen: true,
            modalClassName:'dialog-little',
            going2attach: {files: nfiles, row:line}
        });
        /*const maxlength = Math.max.apply(Math, afiles.map(function(o:any) { return o.size; }))
        if(maxlength > 1200000) {
            this.setState({
                modalAction: -1,
                modalText: `¡ATENCIÓN! Hay almenos un fichero que ocupa más de 5 MB.`,
                modalType: 1,
                creationModalOpen: true
            })
            return;
        }*/
    }

    drawOnCanvas() {
        const item = cloneDeep(this.state.going2attach);
        const files:any = item.files;
        const row:IVRegister = item.row;

        let count = 0;

        for (let index = 0; index < files.length; index++) {
            const element = files[index];

            let reader = new FileReader();

            reader.onloadend = async ()=>{
                count++;
                this._going2upload.attachments.push({name: element.name, stream: reader.result, centrotrabajoId: row.centrotrabajoId, tipo: row.tipo, listadoId: row.listadoTableId, id: row.id});
                //let response = await OrderService.createImgBlob({attachments:[{name: element.name, stream: reader.result, centrotrabajoId: this.props.match.params.centrotrabajoId, tipo: this.props.match.params.tipo, listadoId: this.props.match.params.id}]}, 6);

                if(count == files.length){
                    this.sendAttachFiles();
                                    
                }
            }
            reader.readAsDataURL(element);
        }
    }

    RenderItem=(item:IIIRegister, me:IMe, options: any, lines?:Array<IVRegister>)=>{
        const indexEstado = options?findIndex(options[`estado`], function(o:any){return o.id == item.estado}):-1;
        const estadoHexColor = indexEstado == -1?item.estadoColor:options[`estado`][indexEstado].color;
        const estadoDsc = indexEstado == -1?item.estadoDsc:options[`estado`][indexEstado].name;

        const myRole = GetRole(me, this.props.match.params.centrotrabajoId);
        const fieldsTMP = 
            fieldsFormListado.map((n0:any, index:number)=>{
                let fieldsN0 = n0.map((n:any, index:number)=>{
                    if(n.tipo.indexOf(item.tipo) == -1)
                        return null;

                    switch(n.typeField){
                        case 1:
                            return(
                                <SelectorField
                                    key={index}
                                    name={n.name}
                                    id={n.id}
                                    //fieldsData={options[n.id]?filter(n.filter?filter(options[n.id], function(o:any){return n.filter.indexOf(o.id) !== -1}):options[n.id], function(o){return o.active}):[]}
                                    fieldsData={options[n.id]?filter(options[n.id], function(o){return o.active}):[]}
                                    //fieldsData={[]}
                                    handleFieldChange={this.handleChangeUser}
                                    disabled={[50, 97, 98, 99].indexOf(item.estado) == -1?(n.group.indexOf(myRole) == -1?true:(n.id == "prioridad" && item["nonCompliant"]?true:false)):true}
                                    required={n.required}
                                    style={n.style}
                                    deviceData={item}
                                    className="dialog-item-25 color-sky-blue"
                                />
                            )
                        case 2:
                            if(n.id == "last_userId" && item[n.id] == null)
                                return;
                            return(
                                <TextFieldInput
                                    key={index}
                                    name={n.name}
                                    id={n.id}
                                    fieldsData={item}
                                    handleFieldChange={this.handleChangeUser}
                                    disabled={[50, 97, 98, 99].indexOf(item.estado) == -1?(n.group.indexOf(myRole) == -1?true:false):true}
                                    size="medium"
                                    shrink={true}
                                    //disabled={n.id=="id"?!this.props.newProduct:this.state.editProduct}
                                    type={n.type}
                                    required={n.required}
                                    style={n.style}
                                    helper={n.helper}
                                    maxText={n.maxText}
                                    //noError={n.required?this.verifyForm(0,n.id):true}
                                    noError={true}
                                    multiline={n.multiline}
                                    rows={4}
                                    className="color-sky-blue"
                                />
                            )
                        case 3:
                            return(
                                <div key={index} style={{marginBottom:20, display:'inline-block', marginRight:20}}>
                                    {DatePicker(                                    
                                        n.id,
                                        n.name,
                                        "dd/MM/yyyy",
                                        this.handleChangeDate,
                                        item[n.id],
                                        [50, 97, 98, 99].indexOf(item.estado) == -1?(n.group.indexOf(myRole) == -1?true:false):true,
                                        this.shouldDisableToday,
                                        new Date('1970-01-01'), undefined, 'color-sky-blue datepicker-100')
                                    }
                                </div>
                            );
                        case 4:
                            return(                            
                                <div key={index} style={{marginBottom:20, display:'inline-block', marginRight:20}}>
                                    {DatePicker(                                    
                                        n.id,
                                        n.name,
                                        "dd/MM/yyyy",
                                        this.handleChangeDate,
                                        item[n.id],
                                        true,
                                        this.shouldDisableToday,
                                        new Date('1970-01-01'), undefined, 'color-sky-blue datepicker-100')
                                    }
                                </div>
                            );
                        case 5:
                            return(  
                                <FormControlLabel
                                    value="start"
                                    control={<Checkbox 
                                        key={index} 
                                        required={n.required} 
                                        id={n.id} 
                                        name={n.name}
                                        size="medium"
                                        checked={item[n.id]}
                                        onChange={(evt:any)=>this.handleChangeCheckbox(n.id, evt)}
                                        />}
                                    label={n.name}
    
                                    labelPlacement="start"
                                />                          
                            );
                        default:
                            return('')
                    }
                })
                return (
                    <form key={index} noValidate autoComplete="off" style={{overflowX:'hidden', paddingTop:10, display:'flex', flexWrap:'wrap', gap:20}}>
                        {fieldsN0}
                    </form>
                )
        });

        return(
            <div>
                <div style={{marginBottom:20}}>
                    <div style={{marginTop:'auto', marginBottom:20}}>
                        {/*<strong style={{padding:5, backgroundColor: estadoHexColor, color:'#FFF'}}>{estadoDsc}</strong>*/}
                        <strong style={{padding:10, backgroundColor: item.estadoColor, color:'#FFF', borderRadius:10}}>{item.estadoDsc}</strong>
                    </div>
                    <div style={{display:'flex', gap:20, justifyContent:'flex-end'}}>
                        {[10,20,50,100].indexOf(myRole) > -1 && [50, 97, 98, 99].indexOf(item.estado) == -1 && lines?
                            <div style={{display:'flex', flexWrap: 'wrap', gap:20, justifyContent:'flex-end'}}>
                                {[20,50,100].indexOf(myRole) > -1 && [1].indexOf(item.tipo) > -1?
                                        <Button size="small" onClick={()=>this.setState({creationItemOpen:true})} variant="contained" className='option-button' >
                                            <Add style={listadoStyles.leftIcon}/>
                                            {`GENERAR CORRECTIVO`}
                                        </Button>
                                    :
                                        null
                                }
                                <Button disabled={this.state.editing} size="small" onClick={()=>this.handleCloseOrden(item, lines)} variant="contained" className={`option-button ${this.state.editing?`option-button-disabled`:``}`} >
                                    {/* <Add style={listadoStyles.leftIcon}/> */}
                                    {`CERRAR ORDEN`}
                                </Button>
                                {[50,100].indexOf(myRole) > -1?
                                    <Button disabled={this.state.editing} size="small" onClick={()=>this.handleCancelOrden(item)} variant="contained" className={`option-button  option-button-orange ${this.state.editing?`option-button-disabled`:``}`}>
                                        {/* <Add style={listadoStyles.leftIcon}/> */}
                                        {`CANCELAR ORDEN`}
                                    </Button>
                                :
                                    null
                                }
                                {[50,100].indexOf(myRole) > -1?
                                    <Button disabled={this.state.editing} size="small" onClick={()=>this.handleDeleteOrden(item)} variant="contained" className={`option-button  option-button-red ${this.state.editing?`option-button-disabled`:``}`}>
                                        {/* <Add style={listadoStyles.leftIcon}/> */}
                                        {`ELIMINAR ORDEN`}
                                    </Button>
                                :
                                    null
                                }
                            </div>
                        :
                            null
                        }
                    </div>
                </div>
                {this.state.editing?
                    <div style={{marginTop:'20px', marginBottom:'20px', textAlign:'end'}}>
                        <Button disabled={this.state.saving?true:false} onClick={()=>this.handleSaveData(item)} variant="contained" className='btns' style={{backgroundColor:'#91cc22', width:'100%', borderRadius:10, color:'#fff'}}>
                            {!this.state.saving?
                                <TakeIcon style={listadoStyles.leftIcon}/>
                            :
                                <Loader loading={true}/>
                            }
                            {`${this.state.saving?`Guardando...`:`Guardar`}`}
                        </Button>
                    </div>
                :
                    null
                }

                <Accordion defaultExpanded={true} style={{border:`2px solid ${item.estadoColor}`, borderRadius:15}}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                    <Typography className='title-header'>{`Información orden de trabajo`}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="paper-listado">
                            {fieldsTMP}
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>
        );
    }

    RenderItemLines = (item:IIIRegister, me:IMe, items?:Array<IVRegister>) => {
        const myRole = GetRole(me, item.centrotrabajoId);

        const optionsRow: OptionRow[] = [
            { id: 1, numeric: true, disablePadding: false, label: 'Ver', isfileuploader: false, roleAllowed: true, readonly: true, ignorestate:false, parentState: item.estado },
            { id: 3, numeric: true, disablePadding: false, label: 'Ver adjuntos', isfileuploader: false, roleAllowed: true, readonly: true, ignorestate:false, parentState: item.estado },
            { id: 2, numeric: true, disablePadding: false, label: 'Adjuntar fichero', isfileuploader: true, roleAllowed: [10,20,50,100].indexOf(myRole)==-1?false:true, readonly: false, ignorestate:[100].indexOf(myRole)==-1?false:true, parentState: item.estado },
            { id: 5, numeric: true, disablePadding: false, label: 'Adjuntar foto', isfileuploader: false, roleAllowed: [10,20,50,100].indexOf(myRole)==-1?false:true, readonly: false, ignorestate:[100].indexOf(myRole)==-1?false:true, parentState: item.estado },
            { id: 6, numeric: true, disablePadding: false, label: 'Adjuntar formulario', isfileuploader: false, roleAllowed: [10,20,50,100].indexOf(myRole)==-1?false:true, readonly: false, ignorestate:[100].indexOf(myRole)==-1?false:true, parentState: item.estado },
            { id: 4, numeric: true, disablePadding: false, label: 'Finalizar acción', isfileuploader: false, roleAllowed: [10,20,50,100].indexOf(myRole)==-1?false:true, readonly: false, ignorestate:false, parentState: item.estado },
        ];

        return(
            <div>
                <div style={{ display:'flex', flexDirection:'row', justifyContent:'space-between', marginTop:20, alignItems:'center' }}>
                    <div className="App-sub-header title-header" style={{margin:0, backgroundColor:'transparent', color:'black'}}>
                            {`Acciones realizadas`}
                    </div>
                    
                    {[50,97,98,99].indexOf(item.estado)==-1 && [10,20,50,100].indexOf(myRole)>-1?
                        <div>
                            <Button size="small" onClick={()=>this.handleAddAction(item)} variant="contained" className='option-button'>
                                <Add style={listadoStyles.leftIcon}/>
                                {`ACCIÓN`}
                            </Button>
                        </div>
                        
                    :
                        null
                    }
                </div>
                
                {items?
                    <div style={{ paddingBottom:20 }}>
                        <PaginatedTableLines
                            data={items}
                            handleClickOptionRow={this.handleClickOptionRow}
                            handleAttachFiles={this.handleAttachFiles}
                            optionsrow={optionsRow}
                        />
                    </div>
                    //JSON.stringify(items)
                :
                    null
                }
            </div>
        );
    }

    RenderItemLineAttachment = (item:IVRegister, me?:IMe) => {
        if(!item.attachments || item.attachments.length == 0)
            return `Sin adjuntos.`

        if(!me)
            return `Sin permisos.`

        const myRole = GetRole(me, item.centrotrabajoId);
        
        return(
            <Paper style={{width:'100%', overflowX:'auto'}}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">{`Nombre`}</TableCell>
                            <TableCell align="center">{`Fecha`}</TableCell>
                            <TableCell align="center">{``}</TableCell>
                            <TableCell align="center">{``}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {item.attachments.sort((a, b) => (a.dt_created < b.dt_created ? -1 : 1)).map((attach:IAttach, index:number)=>{
                            return(
                                <TableRow key={index}>
                                    <TableCell style={{whiteSpace:'nowrap'}} align="left"><strong>{`${attach.name}`}</strong></TableCell>
                                    <TableCell align="center">{GetDatetimeFormat(attach.dt_created)}</TableCell>
                                    <TableCell align="center">
                                        <IconButton aria-label="close" onClick={()=>OpenExternalFile(attach.storage)}>
                                            <PageViewIcon />
                                        </IconButton>
                                    </TableCell>
                                    <TableCell align="center">
                                        <IconButton onClick={()=>this.handleDeleteAttachClick(attach)} aria-label="close" disabled={[10,20,50,100].indexOf(myRole) == -1?true:(item.estado == 10?false:true)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </Paper>
        );
    }

    handleDeleteAttachClick = (attach:IAttach) => {
        this.setState({
            modalAction: 4,
            modalText: `¡ATENCIÓN! ¿Quiere eliminar el adjunto ${attach.name}?`,
            modalType: 2,
            creationModalOpen: true,
            modalClassName:'dialog-little',
            going2deleteattach: attach
        });
    }

    handlePhotoTaken = (img:string) => {
        const {sitemline} = this.state;
        if(!sitemline)
            return;
        const photoname = `${new Date().getTime()}.png`;
        this._going2upload.attachments.push({name: photoname, stream: img, centrotrabajoId: sitemline.centrotrabajoId, tipo: sitemline.tipo, listadoId: sitemline.listadoTableId, id: sitemline.id});

        const items = 
        <div>
            <p>{`¿Quiere adjuntar la foto?`}</p>
            <table>
                <thead><tr><th>{`Nombre`}</th><th>{`Tamaño`}</th></tr></thead>
                <tbody key={1}>
                    <tr><td style={{borderRight:'2px solid #337ab7'}}>{`${photoname}`}</td><td>{`1 MB`}</td></tr>
                </tbody>
            </table>
        </div>;

        this.setState({
            modalAction: 5,
            modalText: items,
            modalType: 2,
            creationModalOpen: true,
            modalClassName:'dialog-little',
        });
    }

    handleCreateItem=(item:any)=>{
        this.setState({creatingItem:true})
        this.saveData(item, 2);
    }

    renderFormSelected=(id:number, me:IMe, options:any, item:IVRegister, centrotrabajoId: string)=>{
        switch (id) {
            case 1:
                return(
                    <Form_EPIs_01
                        me={me}
                        secciones = {options["seccion"]}
                        puestosTrabajo = {options["puestoTrabajo"]}
                        handleCloseItemForm={this.onCloseForm}
                        item={item}
                    />        
                );
            case 2:
                return(
                    <Form_EPIs_02
                        me={me}
                        secciones = {options["seccion"]}
                        handleCloseItemForm={this.onCloseForm}
                        item={item}
                    />        
                );
            case 3:
                return(
                    <Form_BTQ_01
                        me={me}
                        secciones = {options["seccion"]}
                        handleCloseItemForm={this.onCloseForm}
                        item={item}
                    />        
                );
            case 4:
                return(
                    <Form_OL_01
                        me={me}
                        secciones = {options["seccion"]}
                        handleCloseItemForm={this.onCloseForm}
                        item={item}
                    />        
                );
            case 5:
                return(
                    <Form_EPCI_01
                        me={me}
                        secciones = {options["seccion"]}
                        handleCloseItemForm={this.onCloseForm}
                        item={item}
                    />        
                );
            case 6:
                return(
                    <Form_APQ_01
                        me={me}
                        secciones = {options["seccion"]}
                        handleCloseItemForm={this.onCloseForm}
                        item={item}
                    />        
                );
            case 7:
                return(
                    <Form_APQ_02
                        me={me}
                        secciones = {options["seccion"]}
                        handleCloseItemForm={this.onCloseForm}
                        item={item}
                    />        
                );
            case 8:
                return(
                    <Form_DL_01
                        me={me}
                        secciones = {options["seccion"]}
                        handleCloseItemForm={this.onCloseForm}
                        item={item}
                    />        
                );
            case 9:
                return(
                    <Form_EST_01
                        me={me}
                        secciones = {options["seccion"]}
                        handleCloseItemForm={this.onCloseForm}
                        item={item}
                    />        
                );
            case 10:
                return(
                    <Form_ESC_01
                        me={me}
                        secciones = {options["seccion"]}
                        handleCloseItemForm={this.onCloseForm}
                        item={item}
                    />        
                );
            case 11:
                return(
                    <Form_II_ICP_01
                        me={me}
                        options = {options}
                        handleCloseItemForm={this.onCloseForm}
                        item={item}
                    />        
                );
            default:
                return(null);
        }
    }

    render() {
        const {register, loadingItem, loadingOptions, options, registerLines, loadingItemLines, bRegisterLine, creatingItem, numform} = this.state;
        const {me} = this.props;

        const tipo = this.props.match.params.tipo;
        const centrotrabajoId = this.props.match.params.centrotrabajoId;
        const empresa:ICentroTrabajo|undefined = GetEmpresa(centrotrabajoId, me);

        //[2,4].indexOf(option.id) == -1?false:(row.estado == 10?false:true)
        let title = (
            <div className='App-title-webPage'>
                {`${tipo == 1?`Preventivo`:`Correctivo`} / ${register?register.code:loadingItem?'Cargando...':'No existe.'}`}
                {'  '}
                {/*<Loader loading={this.state.loading} />*/}
            </div>
        );

        return (
            <div className='App-basic'>
                <Layout 
                    history={this.props.history} 
                    currentPage={1}
                    allowDrawer={true}
                    match={this.props.match}
                    version={this.props.version}
                    me={me}
                    showEmpresa
                    showLateralMenu
                />

                <div className='App-body-webBar'>
                    {(!loadingItem && !loadingOptions && me) || (register && me && options)?
                        [5,10,20,50,100].indexOf(GetRole(me, this.props.match.params.centrotrabajoId)) == -1?
                            <div>
                                <div className="App-header">
                                    {title}
                                </div>
                                <label style={{ marginLeft:10 }}>
                                    {`No tiene permisos para visualizar este espacio.`}
                                </label>
                            </div>
                        :
                            register && options?
                                <div>
                                    <div className="App-header">
                                        {title}
                                    </div>
                                    {this.RenderItem(register, me, options, registerLines)}
                                    {this.RenderItemLines(register, me, registerLines)}

                                    {this.state.creationItemLineOpen && bRegisterLine?
                                        <FormListadoItemLine
                                            handleCloseItemForm={this.onCloseFormItemLineModal}
                                            handleCreateItem={this.handleSaveItemLine}
                                            loading={loadingItemLines}
                                            register={bRegisterLine}
                                            parentRegister={register}
                                            me={me}
                                            centrotrabajoId={centrotrabajoId}
                                        />
                                    :
                                        null
                                    }
                                    {this.state.creationItemOpen?
                                        <FormCorrectivo
                                            centrotrabajoId={this.props.match.params.centrotrabajoId}
                                            options={options}
                                            handleCloseItemForm={this.onCloseFormItemModal}
                                            handleCreateItem={this.handleCreateItem}
                                            loading={creatingItem}
                                        />
                                    :
                                        null
                                    }
                                </div>
                            :
                                `El ítem seleccionado no existe.`
                    :
                        <Loader loading={true}/>    
                    }
                    <div>
                    {this.state.creationModalOpen?
                        <CreateModal
                            onCloseCreationModal={this.onCloseCreationModal}
                            isOpen={this.state.creationModalOpen}
                            inputText={this.state.modalText}
                            typeModal={this.state.modalType}
                            action={this.handleCreateModal}
                            typeAction={this.state.modalAction}
                            loadingModal={this.state.loadingModal}
                            titleModal={this.state.titleModal}
                            maxwidth={this.state.maxwdidthmodal}
                            fullscreen={this.state.fullscreenmodal}
                            className={this.state.modalClassName}
                        />
                    :
                        null
                    }
                    
                    {this.state.creationCameraOpen?
                        <Camera
                            onCloseCamera={this.onCloseCameraModal}
                            isOpen={this.state.creationCameraOpen}
                            handlePhotoTaken={this.handlePhotoTaken}
                        />
                    :
                        null
                    }

                    {me && options && bRegisterLine?
                        this.renderFormSelected(numform,me,options,bRegisterLine, centrotrabajoId)
                    :
                        null
                    }
                    <LoaderBeauty visible={loadingItemLines||loadingItem||loadingOptions?true:false}/>
                    </div>
                </div> 
            </div>
        );
    }
}

export default ListadoDetail;